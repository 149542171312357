<!------------------------------------------------------------------------------
// Kidiwi Digital property
// Component a header to define the working employee scedule for a ressource or anything:
// Props:
// - dayName: day to be displayed in the header
// - ressourceid: contains an id to identify the case of delete of a ressource.
// Example of usage:
      <kdw-dialog-resource-sub-worktime-week-sub-worktime-Day
        v-model="mondayWorkingHours"
        dayName="Monday"
        :ressourceid="resource.id"
      />
with an example of the variable to use:
    mondayWorkingHours = [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
                          { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }]
// ------------------------------------------------------------------------------>

<template>
  <v-container>
    <kdw-Statusbar-card
      v-model="snackbarUpdate"
      :snackbar-timeout="snackbarTimeout"
      :snackbar-message="snackbarMessage"
    />
    <!----- This is a dialog box to edit the slot  --->
    <v-dialog
      v-model="dialogEditSlot"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <base-info-card
            :title="$t('worktimeDay.dialogEditSlotTitle')"
            color="primary"
          />
        </v-card-title>
        <v-card-text :class="(slot.type === 'Working') ? 'black--text' : 'red--text'">
          <b> {{ (slot.type === 'Working') ? $t('worktimeDay.working') : $t('worktimeDay.break') }} </b>
        </v-card-text>
        <v-card-text>
          <v-row class="black--text font-weight-bold">
            {{ $t('worktimeDay.start') }}
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                key="startHH"
                v-model="slot.startHH"
                class="text-field-single-line-label-top text-field-single-line-prepend-append-margin-top"
                :items="staticHours"
                item-text="value"
                item-value="value"
                dense
                prepend-icon="mdi-clock-time-eight"
                :label="$t('serviceSP.DurationHH_hint')"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                key="startMM"
                v-model="slot.startMM"
                class="text-field-single-line-label-top text-field-single-line-prepend-append-margin-top"
                :items="staticMinutes"
                item-text="value"
                item-value="value"
                dense
                prepend-icon="mdi-clock-time-eight"
                :label="$t('serviceSP.DurationMM_hint')"
              />
            </v-col>
          </v-row>
          <v-row class="black--text font-weight-bold">
            {{ $t('worktimeDay.end') }}
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                key="startHH"
                v-model="slot.endHH"
                class="text-field-single-line-label-top text-field-single-line-prepend-append-margin-top"
                :items="staticHours"
                item-text="value"
                item-value="value"
                dense
                prepend-icon="mdi-clock-time-five"
                :label="$t('serviceSP.DurationHH_hint')"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                key="startMM"
                v-model="slot.endMM"
                class="text-field-single-line-label-top text-field-single-line-prepend-append-margin-top"
                :items="staticMinutes"
                item-text="value"
                item-value="value"
                dense
                prepend-icon="mdi-clock-time-five"
                :label="$t('serviceSP.DurationMM_hint')"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <kdw-btn
            text
            @click="closeDialogEditSlot()"
          >
            {{ $t('button.cancel') }}
          </kdw-btn>
          <kdw-btn
            @click="saveDialogEditSlot()"
          >
            {{ $t('button.validateQuestion') }}
          </kdw-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---- This is the header of the component: Day Name + buttons  --------------------------------------------->
    <v-row
      class="jean font-weight-bold ma-0 pa-0"
      no-gutters
    >
      <v-col>
        {{ dayName }}
      </v-col>
      <v-col
        cols="auto"
      >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              small
              rounded
              v-bind="attrs"
              v-on="on"
              @click="addWorkingSlot()"
            >
              +{{ $t('worktimeDay.work') }}
            </v-btn>
          </template>
          {{ $t('button.addWorkingSlot') }}
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              small
              rounded
              v-bind="attrs"
              v-on="on"
              @click="addBreakSlot()"
            >
              +{{ $t('worktimeDay.break') }}
            </v-btn>
          </template>
          {{ $t('button.addBreakSlot') }}
        </v-tooltip>
      </v-col>
    </v-row>
    <!------ We display the list of slots  ------>
    <v-row
      v-for="(timeSlot, i) in timeSlots"
      :key="i"
      justify="space-between"
      dense
      no-gutters
    >
      <span :class="(timeSlot.type === 'Working') ? 'concrete--text' : 'red--text'">{{ getTimeSlotString(timeSlot) }}</span>
      <span>
        <!--
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              color="black"
              dark
              small
              rounded
              v-bind="attrs"
              v-on="on"
              @click="openDialogEditSlot(timeSlot, i)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{ $t('button.modifyWorkingSlot') }}</span>
        </v-tooltip>
        -->
        <kdw-icon-action-tooltip
          min-width="auto"
          tipkey="button.modifyWorkingSlot"
          @click="openDialogEditSlot(timeSlot, i)"
        >
          mdi-pencil
        </kdw-icon-action-tooltip>
        <!--
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              color="black"
              dark
              small
              rounded
              v-bind="attrs"
              v-on="on"
              @click="deleteSlot(i)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $t('button.deleteWorkingSlot') }}</span>
        </v-tooltip>
        -->
        <kdw-icon-action-tooltip
          min-width="auto"
          tipkey="button.deleteWorkingSlot"
          @click="deleteSlot(i)"
        >
          mdi-delete
        </kdw-icon-action-tooltip>
      </span>
    </v-row>
  </v-container>
</template>

<script>
  import { buildMinutesTable, isTimeStartBiggerTimeEnd } from '@/helpers/GenericFunctionsTime'
  export default {
    name: 'WorktimeDay',

    props: {
      value: {
        type: Array,
        required: true,
      },
      dayName: {
        type: String,
        default: 'Monday',
      },
      ressourceid: {
        type: String,
        default: '',
      },
    },

    data: () => {
      return {
        snackbarUpdate: false,
        snackbarMessage: '',
        snackbarTimeout: 2000,
        // In case we are modifying a slot.
        slot: { type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00', itemNumber: 0 },
        // List of the time slot for the day we are trying to edit. Every slot in timsSlots is out of Slot nature with type='Working' or 'Break'
        timeSlots: [],
        // to ON/OFF the slot edition dialog using the slot data
        dialogEditSlot: false,
        staticHours: [{ value: '00' }, { value: '01' }, { value: '02' }, { value: '03' }, { value: '04' }, { value: '05' }, { value: '06' }, { value: '07' }, { value: '08' }, { value: '09' }, { value: '10' }, { value: '11' }, { value: '12' }, { value: '13' }, { value: '14' }, { value: '15' }, { value: '16' }, { value: '17' }, { value: '18' }, { value: '19' }, { value: '20' }, { value: '21' }, { value: '22' }, { value: '23' }],
        staticMinutes: [],
        ressourceIdLocal: '',
      }
    },

    watch: {
      value: function (newValue) {
        // We are checking to see if a delete of a ressource happened
        if (this.ressourceIdLocal !== this.ressourceid) {
          // console.log('delete dectected......................... in working day')
          // We regenerate the content of the day off
          this.timeSlots = [...this.value]
          this.ressourceIdLocal = this.ressourceid
        }
      },
    },

    created: function () {
      // console.log(this.value)
      this.timeSlots = [...this.value]
      buildMinutesTable(this.staticMinutes)
      // console.log('this.$attr = ', this.$attrs)
      // console.log('this.$listeners = ', this.$listeners)
    },

    methods: {
      emitResult () {
        this.$emit('input', this.timeSlots)
      },
      openDialogEditSlot (timeSlot, i) {
        this.slot.type = timeSlot.type
        this.slot.startHH = timeSlot.startHH
        this.slot.startMM = timeSlot.startMM
        this.slot.endHH = timeSlot.endHH
        this.slot.endMM = timeSlot.endMM
        this.slot.itemNumber = i
        this.dialogEditSlot = true
      },
      closeDialogEditSlot () {
        this.dialogEditSlot = false
      },
      saveDialogEditSlot () {
        // We need to verify that the ending time is after the starting time
        if (isTimeStartBiggerTimeEnd(this.slot.startHH, this.slot.startMM, this.slot.endHH, this.slot.endMM)) {
          this.alertSnackBar(this.$i18n.t('worktimeDay.errorStartTimeBiggerEndTime'))
          return
        }
        // We need to record the data
        this.timeSlots[this.slot.itemNumber].startHH = this.slot.startHH
        this.timeSlots[this.slot.itemNumber].startMM = this.slot.startMM
        this.timeSlots[this.slot.itemNumber].endHH = this.slot.endHH
        this.timeSlots[this.slot.itemNumber].endMM = this.slot.endMM
        this.dialogEditSlot = false
        // We pass back the data to v-model variable
        this.emitResult()
        // this.alertSnackBar(this.$i18n.t('AccountEU.alertEUSuccessUpdate'))
      },
      alertSnackBar (message, snackbarTimeout = 2000) {
        this.snackbarMessage = message
        this.snackbarUpdate = true
        this.snackbarTimeout = snackbarTimeout
      },
      addWorkingSlot () {
        // We verify if a working slot is not already existing.
        var i = 0
        while (i < this.timeSlots.length) {
          if (this.timeSlots[i].type === 'Working') {
            this.alertSnackBar(this.$i18n.t('worktimeDay.workingSlotAlreadyExist'))
            return
          }
          i++
        }
        // We did not find any working slot & we create one with default value that the sp can change later
        this.timeSlots.push({ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' })
        this.emitResult()
      },
      addBreakSlot () {
        // We just add another break that the SP can change later on
        this.timeSlots.push({ type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' })
        // this.alertSnackBar(this.$i18n.t('worktimeDay.breakSlotAdded'))
        this.emitResult()
      },
      deleteSlot (itemNumber) {
        this.timeSlots.splice(itemNumber, 1)
        this.emitResult()
        // this.alertSnackBar(this.$i18n.t('worktimeDay.slotdeleted'))
      },
      getTimeSlotString (timeSlot) {
        var output = ''
        if (timeSlot.type === 'Working') {
          output = this.$i18n.t('worktimeDay.work')
        } else {
          output = this.$i18n.t('worktimeDay.break')
        }
        output = output + ':   ' + timeSlot.startHH + ':' + timeSlot.startMM + '-' + timeSlot.endHH + ':' + timeSlot.endMM
        return output
      },
    },

  }
</script>
