import { render, staticRenderFns } from "./VueTelInput.vue?vue&type=template&id=5fe83a78&"
import script from "./VueTelInput.vue?vue&type=script&lang=js&"
export * from "./VueTelInput.vue?vue&type=script&lang=js&"
import style0 from "vue-tel-input/dist/vue-tel-input.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VInput } from 'vuetify/lib/components/VInput';
installComponents(component, {VInput})
