// ------------------------------------------------------------------------------
// Kidiwi Digital property
// Generic functions to manage S3 download features
// ------------------------------------------------------------------------------
import { Logger, Storage } from 'aws-amplify'
const logger = new Logger('GenericFunctions')

export function downloadBlob (blob, filename) {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'download'
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
  return a
}

export function loadFilePublic (fileNameToDownload, fileNameDownloadedifDifferent) {
  var fileNameDownloaded
  if ((fileNameDownloadedifDifferent === undefined) || (fileNameDownloadedifDifferent === null) || (fileNameDownloadedifDifferent === '')) {
    fileNameDownloaded = fileNameToDownload
  } else {
    fileNameDownloaded = fileNameDownloadedifDifferent
  }
  Storage.get(fileNameToDownload, {
    download: true,
    progressCallback (progress) {
      console.log(`Downloaded: ${progress.loaded}/${progress.total}`)
    },
  })
    .then(result => {
      downloadBlob(result.Body, fileNameDownloaded)
    })
    .catch(err => logger.error(err))
}
