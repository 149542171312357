<template>
  <div>
    <!--
    <div>
      {{ signUpType }}
    </div>
    -->
    <amplify-auth-container>
      <amplify-authenticator
        :initial-auth-state="initialState"
      >
        <amplify-sign-up
          slot="sign-up"
          :header-text="signUpConfig.headerText"
          :form-fields.prop="signUpConfig.formFields"
          :handle-sign-up.prop="handleSignUp"
        />
        <amplify-sign-in
          slot="sign-in"
          :header-text="signInConfig.headerText"
          :form-fields.prop="signInConfig.formFields"
        />
      </amplify-authenticator>
    </amplify-auth-container>
  </div>
</template>

<script>
  import { AuthState, onAuthUIStateChange, Translations } from '@aws-amplify/ui-components'
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { ENDUSER, SERVICEPROVIDER, RESOURCE, checkCurrentUserType } from '@/services/User'
  import { navigate } from '@/helpers/Navigation'
  import { Auth, Logger } from 'aws-amplify'
  import { isPersistQuestionsEmpty } from '@/controllers/Questions'
  const logger = new Logger('AuthComponent')

  @Component({ name: 'AuthComponent' })
  class AuthComponent extends Vue {
    // coming from router
    @Prop(String) signUpType
    @Prop(String) navigateNext

    handleSignIn (userType) {
      if (userType === ENDUSER) {
        // console.log(`navigateNext is ${this.navigateNext}.`)
        if (this.navigateNext === undefined || this.navigateNext === 'HOME') {
          navigate(this, 'EndUserMyServices')
        }
      } else if (userType === SERVICEPROVIDER) {
        navigate(this, 'ServiceProviderMarket')
      } else if (userType === RESOURCE) {
        navigate(this, 'ResourceCalendar')
      } else {
        logger.error('Error userType not found in DB.')
        // TODO temporary redirect to EndUser
        // edge case when user exists in cognito but not in DB
        if (isPersistQuestionsEmpty()) {
          console.log(' Error navigate(this, EndUserMyServices)')
          navigate(this, 'EndUserMyServices')
        }
      }
      // else here ?
    }

    handleSignUp (formData) {
      const param = {
          ...formData,
          attributes: {
              ...formData.attributes,
              'custom:signUpType': this.signUpType,
          },
      }
      return Auth.signUp(param)
    }

    created () {
      this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
        this.authState = authState
        this.user = authData
        // logger.debug('handleSignIn type is ', typeof (this.handleSignIn))
        logger.info(`authState changed to ${authState} and authData is "` + JSON.stringify(authData) + '".')
        if (this.authState === AuthState.SignedIn) {
          // logger.error('SignedIn!!')
          // this.$router.push({ name: 'EndUserMyAccount' })
          // https://stackoverflow.com/questions/65284853/vue-router-navigation-cancelled-from-to-password-with-a-new-navigation
          try {
            // actively check usertype
            checkCurrentUserType().then(this.handleSignIn).catch((err) => {
              logger.error('Error fetching user: ', this.user.attributes.sub, ' err is: ', err)
            })
          } catch (err) {
            logger.error('Something bad happened. ', err)
          }
        }
      })
    }

    data () {
      return {
        user: undefined,
        authState: undefined,
        unsubscribeAuth: undefined,
        initialState: this.signUpType === SERVICEPROVIDER ? AuthState.SignUp : AuthState.SignIn,
        signInConfig: {},
        signUpConfig: {
          headerText: this.signUpType === SERVICEPROVIDER ? this.$t('login.signupheaderSP') : this.$t('login.signupheaderEU'),
        },
        /*        {
          headerText: 'My Custom Sign In Header',
          // handleSubmit: this.handleSignInSubmit,
          formFields: [
            { type: 'username', label: 'Username Label', required: true },
            { type: 'password', label: 'Pass Label Text', placeholder: 'placetext', required: true },
          ],
        },
        */
        /*
SIGN_UP_EMAIL_PLACEHOLDER = 'Email',
SIGN_UP_HAVE_ACCOUNT_TEXT = 'Have an account?',
SIGN_UP_HEADER_TEXT = 'Create a new account',
SIGN_UP_PASSWORD_PLACEHOLDER = 'Password',
SIGN_UP_SUBMIT_BUTTON_TEXT = 'Create Account',
SIGN_UP_USERNAME_PLACEHOLDER = 'Username',
        */
        signupFormFields: [
          { type: 'username', label: Translations.USERNAME_LABEL, placeholder: Translations.SIGN_UP_USERNAME_PLACEHOLDER, required: true },
          { type: 'password', label: Translations.PASSWORD_LABEL, placeholder: Translations.SIGN_UP_PASSWORD_PLACEHOLDER, required: true },
          { type: 'email', label: Translations.EMAIL_LABEL, placeholder: Translations.SIGN_UP_EMAIL_PLACEHOLDER, required: true },
          { type: 'phone_number', label: Translations.PHONE_LABEL, placeholder: Translations.PHONE_PLACEHOLDER, required: true },
        ],
        // signupFormFields: [],
      }
    }

    beforeDestroy () {
      this.unsubscribeAuth()
    }
  }

  export default AuthComponent
  Vue.component('KdwAuthComponent', AuthComponent)
</script>

<style scoped>
  amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100vh;
    --amplify-font-family: 'Montserrat';
    --amplify-primary-color: #E4007A;
    --amplify-primary-tint: rgba(228,0,112,0.9);
    --amplify-primary-shade: rgba(228,0,122,0.7);
    --amplify-background-color: #F9F9F9;
  }
</style>
