import { getEndUser, getServiceProvider, getResource } from '@/graphql/queries'
import { Auth, API, Hub, Logger } from 'aws-amplify'

const logger = new Logger('User', 'DEBUG')

export const ENDUSER = 'EndUser'
export const SERVICEPROVIDER = 'ServiceProvider'
export const RESOURCE = 'Resource'
export const ANONYMOUS = 'Anonymous'

var userType = ANONYMOUS

var localUserCache
var lock = false

// fetch current userType
export function getUserType () {
    return userType
}

export function getUserSubSync () {
    // fetch current user sub
    // Note localUserCache may be super wrong when user is "not yet known" (aka. when checkCurrentUserType didn't return yet)
    return localUserCache
}

// deprecated function - use getUserSubSync instead
/*
export function getUserSub () {
    return getUserSubSync()
}
*/

export async function getUserSub () {
    /* eslint-disable no-unmodified-loop-condition */
    logger.info(`getUserSub ${localUserCache}`)
    if (!localUserCache) {
        await checkCurrentUserType()
        logger.info(`getUserSub return ${localUserCache}`)
        return localUserCache
    }
    logger.info(`getUserSub return ${localUserCache}`)
    return localUserCache
}

// TODO should use ES6 getter/setter ?
function setUserType (inUserType, user = null) {
    if (user) {
        localUserCache = user.attributes.sub
    }
    if (inUserType === ANONYMOUS) {
        localUserCache = null
    }
    if (inUserType === userType) {
        return userType
    }
    userType = inUserType
    logger.info('Current userType set to ', userType)
    for (const listener of listenerList) {
        try {
            listener(userType)
        } catch (err) {
            logger.error(err)
            // ignore error
        }
    }
    return userType
}

// protected by lock
export async function checkCurrentUserType (event = null) {
    const max = 200 // how many times we sleep max
    const sleepTime = 20 // ms
    for (var i = 0; i < max; i++) {
        // if we're already checking user type, we wait for it and return result instead
        // we're waiting up to 200x20=4000ms for lock to be false
        if (lock === true) {
            logger.info('checkCurrentUserType going to sleep')
            await new Promise(resolve => setTimeout(resolve, sleepTime))
            if (lock === false) {
                logger.info(`checkCurrentUserType return ${localUserCache} after sleeping ${i} times`)
                return userType
            } else if (i === max - 1) {
                // sadly this is the last iteration and still no result
                logger.error(`checkCurrentUserType failed to complete after ${i} iterations - giving up`)
            }
        } else {
            break
        }
    }

    if (event) {
        logger.info('Received event ', event)
        // https://docs.amplify.aws/lib/auth/auth-events/q/platform/js
        // possible events are configured signIn, signUp, signOut, signIn_failure, tokenRefresh, tokenRefresh_failure, configured
        if (['configured', 'signIn_failure', 'signUp'].includes(event.payload.event)) {
            logger.info('ignoring event.')
            return userType
        }
        if (['signOut'].includes(event.payload.event)) {
            return setUserType(ANONYMOUS)
        }
    }
    logger.info('Checking current userType (current is ' + userType + '), localUserCache is ' + localUserCache)
    try {
        lock = true
        const user = await Auth.currentAuthenticatedUser()
        logger.info('Got current user ', user)
        if (localUserCache === user.attributes.sub) {
            // we already know
            logger.info('Using localUserCache ' + localUserCache + ' and userType ' + userType)
            return userType
        } else {
            // localUserCache = user.attributes.sub
            const res = await checkUserType(user)
            logger.info('New user localUserCache ' + localUserCache + ' and userType ' + res)
            return res
        }
    } catch (err) {
        logger.error('Error checking current userType ', err)
        return setUserType(ANONYMOUS)
    } finally {
        lock = false
    }
    // TODO if event is signIn and result is ANONYMOUS => signOut and go home with error msg
}

// TODO should probably make this a list of named listerners
var listenerList = []
export function onUserTypeChange (callback) {
    listenerList.push(callback)
    // logger.info('callback type is ', typeof (callback))
    logger.info('listenerList is ', listenerList)
    return () => {
        logger.info('unregister callback ', callback)
        listenerList.pop(callback)
    }
}

async function checkUserType (user) {
    try {
        const apiDataEndUser = await API.graphql({ query: getEndUser, variables: { id: user.attributes.sub }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        logger.debug('apiDataEndUser is ', apiDataEndUser)
        const endUser = apiDataEndUser.data.getEndUser
        if (endUser !== null) {
            return setUserType(ENDUSER, user)
        }
        const apiDataSP = await API.graphql({ query: getServiceProvider, variables: { id: user.attributes.sub }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        logger.debug('apiDataSP is ', apiDataSP)
        const serviceProvider = apiDataSP.data.getServiceProvider
        if (serviceProvider !== null) {
            return setUserType(SERVICEPROVIDER, user)
        }
        const apiDataRes = await API.graphql({ query: getResource, variables: { id: user.attributes.sub }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        logger.debug('apiDataRes is ', apiDataRes)
        const resource = apiDataRes.data.getResource
        if (resource !== null) {
            return setUserType(RESOURCE, user)
        }
        return setUserType(ANONYMOUS)
    } catch (err) {
        logger.error('Error checking userType for user', user, ' err is ', err)
        return setUserType(ANONYMOUS)
    }
}

async function _manageUsers (userData) {
    const apiName = 'kdwevts'
    const path = '/users'
    const postData = {
      body: userData,
    }
    var output = {}
    try {
      logger.info('sending manageUser with userData: ' + JSON.stringify(userData))
      output = await API.post(apiName, path, postData)
      logger.info('send manageUser result:' + JSON.stringify(output))
      if (output.status === 'KO') {
        throw new Error(output.errorMessage)
      }
    } catch (err) {
      logger.error('send manageUser error:' + JSON.stringify(err))
      output.errorMessage = err.message
    }
    return output
  }

export async function manageCognitoUsers (firstName, lastName, email, phoneNumber, spId, isEnabled, action, loginId) {
    try {
        // send password by email to user
        return await _manageUsers({
            userData: {
                email,
                phoneNumber,
                firstName,
                lastName,
                loginId,
                spId,
            },
            isEnabled,
            action,
        })
    } catch (error) {
        console.log('error signing up:', error)
        throw error
    }
}

// listen to auth event
Hub.listen('auth', checkCurrentUserType)
// fetch current user type useful when user is already logged in
checkCurrentUserType()
