// ------------------------------------------------------------------------------
// Kidiwi Digital property
// Management of a specific resource
// ------------------------------------------------------------------------------

import { API, Logger, Storage } from 'aws-amplify'
import { deleteServiceResources as deleteServiceResourcesMutation, createResource as createResourceMutation, updateResource as updateResourceMutation, deleteResource as deleteResourceMutation } from '@/graphql/mutations'
import { listServiceResourcesSimplified, getResourceForCalendar, getResourceForLoad } from '@/graphql/kdwqueries'
import { getResource } from '@/graphql/queries'
import { getUserSub, manageCognitoUsers } from '@/services/User'
import { Appointments } from '@/services/Appointment'
import { convertDateTimeStringLocalUTC, convertDateTimeStringUTCLocal } from '@/helpers/Formatter'

const logger = new Logger('Resource')

export class Resource {
  id = ''
  firstname = ''
  lastname = ''
  phone = ''
  email = ''
  login = ''
  pictureName = '' // name of the picture, current
  pictureNameLoaded = '' // name of the ressource at load
  pictureObject = null
  pictureUrl = null
  pictureChange = false
  interval = 60
  webSiteAccess = false
  canBookOnline = false
  dayOffList = ['']
  events = [] // contain the event for the calendar infos
  /*
  dayOffList = [{ type: 'rangeByDate', startDate: '2021-09-01', endDate: '2021-09-10' },
                { type: 'rangeBytime', startDate: '2021-09-01', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }]
  */
  weekWorkingDays = []
  /*
  weekWorkingDays = [[{ type: 'Working', startHH: '10', startMM: '00', endHH: '17', endMM: '00' },
      { type: 'Break', startHH: '12', startMM: '00', endHH: '14', endMM: '00' }],
     [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
      { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
     [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
      { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
     [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
      { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
     [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
      { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
     [{ type: 'Working', startHH: '12', startMM: '00', endHH: '16', endMM: '00' },
     { type: 'Break', startHH: '09', startMM: '00', endHH: '10', endMM: '00' }],
    [{ type: 'Working', startHH: '12', startMM: '00', endHH: '16', endMM: '00' },
     { type: 'Break', startHH: '08', startMM: '00', endHH: '12', endMM: '00' }],
   ]
   */
   serviceProviderID = ''
  // i18n
  // TO UPDATE HOW IT IS STORED
  //    appointmentList = [{ type: 'rangeByDuration', startDate: '2021-09-01', startHH: '14', startMM: '00', duration: '60' },
  //                       { type: 'rangeByDuration', startDate: '2021-09-10', startHH: '15', startMM: '00', duration: '60' }]
  appointments = new Appointments()

  constructor (id) {
    // logger.info('Ressource created.')
    // this.i18n = i18n
    this.initialise()
    if ((id !== undefined) && (id !== null)) {
      this.id = id
    }
  }

  canBeCreated () {
    if (this.firstname === '') {
      return false
    }
    if (this.lastname === '') {
      return false
    }
    if (this.phone === '') {
      return false
    }
    if (this.email === '') {
      return false
    }
    if (this.login === '') {
      return false
    }
    if (this.interval < 1) {
      return false
    }
    return true
  }

  initialise () {
    this.id = ''
    this.firstname = ''
    this.lastname = ''
    this.phone = ''
    this.email = ''
    this.login = ''
    this.pictureName = ''
    this.pictureNameLoaded = ''
    this.pictureObject = null
    this.pictureUrl = null
    this.pictureChange = false
    this.interval = 60
    this.webSiteAccess = false
    this.canBookOnline = false
    this.serviceProviderID = ''
    this.dayOffList.splice(0, this.dayOffList.length)
    this.weekWorkingDays.splice(0, this.weekWorkingDays.length)
    this.appointments.initialise()
  }

  copy (resource) {
    this.id = resource.id
    this.firstname = resource.firstname
    this.lastname = resource.lastname
    this.phone = resource.phone
    this.email = resource.email
    this.login = resource.login
    this.interval = resource.interval
    this.picture = resource.picture // TO DO
    this.pictureName = resource.pictureName
    // trying to copy the object but I did not succeded so I did it differently.
    // console.log(resource.pictureObject)
    // this.pictureObject = Object.assign({}, ...resource.pictureObject)
    // this.pictureObject = { ...resource.pictureObject }
    // this.pictureObject = new File(resource.pictureObject, resource.pictureName)
    // this.pictureObject = JSON.parse(resource.pictureObject.toString())
    // this.pictureObject = resource.pictureObject
    // console.log(this.pictureObject)
    this.pictureUrl = resource.pictureUrl
    this.pictureChange = resource.pictureChange
    this.webSiteAccess = resource.webSiteAccess
    this.canBookOnline = resource.canBookOnline
    this.serviceProviderID = resource.serviceProviderID
    this.dayOffList.splice(0, this.dayOffList.length)
    this.weekWorkingDays.splice(0, this.weekWorkingDays.length)
    // we need to copy the day off
    this.copyDayOffList(resource.dayOffList)
    // we need to copy the weekWorkingDays
    this.copyWeekWorkingDays(resource.weekWorkingDays)
    this.appointments.copy(resource.appointments)
  }

  copyWeekWorkingDays (weekWorkingDays) {
    var monday = []
    var tuesday = []
    var wednesday = []
    var thursday = []
    var friday = []
    var saturday = []
    var sunday = []
    if (weekWorkingDays.length > 0) {
      if (weekWorkingDays[0].length > 0) {
        monday = [...weekWorkingDays[0]]
      }
      if (weekWorkingDays[1].length > 0) {
        tuesday = [...weekWorkingDays[1]]
      }
      if (weekWorkingDays[2].length > 0) {
        wednesday = [...weekWorkingDays[2]]
      }
      if (weekWorkingDays[3].length > 0) {
        thursday = [...weekWorkingDays[3]]
      }
      if (weekWorkingDays[4].length > 0) {
        friday = [...weekWorkingDays[4]]
      }
      if (weekWorkingDays[5].length > 0) {
        saturday = [...weekWorkingDays[5]]
      }
      if (weekWorkingDays[6].length > 0) {
        sunday = [...weekWorkingDays[6]]
      }
    }
    this.weekWorkingDays.push(monday)
    this.weekWorkingDays.push(tuesday)
    this.weekWorkingDays.push(wednesday)
    this.weekWorkingDays.push(thursday)
    this.weekWorkingDays.push(friday)
    this.weekWorkingDays.push(saturday)
    this.weekWorkingDays.push(sunday)
  }

  copyDayOffList (dayOffList) {
    // this.dayOffList = [...dayOffList]
    // console.log('copyDayOffList -----------------------')
    // console.log('dayOffList')
    // console.log(dayOffList)
    // console.log('dayOffList.length')
    // console.log(dayOffList.length)
    for (var i = 0; i < dayOffList.length; i++) {
      if (dayOffList[i].type !== '') {
        if (dayOffList[i].type === 'rangeByDate') {
          this.dayOffList.push({ type: 'rangeByDate', startDate: dayOffList[i].startDate, endDate: dayOffList[i].endDate })
        }
        if (dayOffList[i].type === 'rangeBytime') {
          this.dayOffList.push({ type: 'rangeBytime', startDate: dayOffList[i].startDate, startHH: dayOffList[i].startHH, startMM: dayOffList[i].startMM, endHH: dayOffList[i].endHH, endMM: dayOffList[i].endMM })
        }
      }
    }
    // console.log('this.dayOffList')
    // console.log(this.dayOffList)
  }

  async pictureUpdate (pictureObject) {
    // we check if a modification happened on the picture side
    if (this.pictureChange === true) {
      // We chacked if the name of the picture changed
      if (this.pictureName !== this.pictureNameLoaded) {
        // We store the new picture if the object exist.
        // console.log('pictureOject from picture Update')
        // console.log(pictureObject)
        // console.log(this.pictureName)
        if (pictureObject !== null) {
          await Storage.put(this.pictureName, pictureObject, { level: 'public' })
        }
        // we check if we need to delete the old picture
        // console.log(this.pictureName)
        // console.log(this.pictureNameLoaded)
        if (this.pictureNameLoaded !== '') {
          await Storage.remove(this.pictureNameLoaded, { level: 'public' })
        }
      }
    }
  }

  async updateResource (resource) {
    console.log('updateResource from Ressource')
    // we update the ressource content.
    this.copy(resource)
    // We update in the DB content
    try {
      var dayOffListStorage = []
      var weekWorkingDaysStorage = []
      this.serialiseDayOffList(dayOffListStorage)
      // console.log(dayOffListStorage)
      this.serialiseWeekWorkingDays(weekWorkingDaysStorage)
      // console.log(weekWorkingDaysStorage)
      // We can now update the ressource
      // TO DO
      var myResourceToUpdate = { id: this.id, firstname: this.firstname, lastname: this.lastname, phone: this.phone, email: this.email, login: this.login, webSiteAccess: this.webSiteAccess, canBookOnline: this.canBookOnline, dayOffList: dayOffListStorage, weekWorkingDays: weekWorkingDaysStorage, interval: this.interval }
      // var myResourceToUpdate = { id: this.id, webSiteAccess: this.webSiteAccess, canBookOnline: this.canBookOnline }
      // console.log(myResourceToUpdate)
      await API.graphql({ query: updateResourceMutation, variables: { input: myResourceToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      // console.log('OK updateResource from Ressource')
      // update cognito user
      await manageCognitoUsers(this.firstname, this.lastname, this.email, this.phone, this.serviceProviderID, this.webSiteAccess, 'UPDATE', this.login)
      // Update the picture loaded
      // console.log(this.pictureName)
      await this.pictureUpdate(resource.pictureObject)
      myResourceToUpdate = { id: this.id, picture: this.pictureName }
      await API.graphql({ query: updateResourceMutation, variables: { input: myResourceToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      return true
    } catch (err) {
      logger.error(err)
      // alert(this.i18n.t('ressources.alertFailCreateRes'))
      return false
    }
  }

  // this function is creating a ressource in the DB
  // the picture and the interval are not managed at ressource creation.
  async createResource () {
    // console.log('create Ressource')
    this.serviceProviderID = await getUserSub()
    var dayOffListStorage = []
    var weekWorkingDaysStorage = []
    // var appointmentsStorage = []
    this.serialiseDayOffList(dayOffListStorage)
    // console.log(dayOffListStorage)
    this.serialiseWeekWorkingDays(weekWorkingDaysStorage)
    // console.log(weekWorkingDaysStorage)
    // this.serialiseAppointmentList(appointmentsStorage)
    // creating resource in Cognito
    const cognitoUser = await manageCognitoUsers(this.firstname, this.lastname, this.email, this.phone, this.serviceProviderID, this.webSiteAccess, 'CREATE', this.login)
    console.log('create Ressource - cognitoUser ' + JSON.stringify(cognitoUser))
    if (cognitoUser.errorMessage && cognitoUser.errorMessage.includes('already exist')) {
      console.log('create Ressource - error: ' + cognitoUser.errorMessage)
      throw new Error('userAlreadyExist')
    }
    // We can now create the ressource
    // const myResource = { id: cognitoUser.sub, firstname: this.firstname, lastname: this.lastname, phone: this.phone, email: this.email, login: this.login, webSiteAccess: this.webSiteAccess, canBookOnline: this.canBookOnline, resourceServiceProviderId: this.serviceProviderID, dayOffList: dayOffListStorage, weekWorkingDays: weekWorkingDaysStorage, appointments: appointmentsStorage, picture: this.picture, interval: this.interval }
    // graphqlV1-->V2: const myResource = { id: cognitoUser.sub, firstname: this.firstname, lastname: this.lastname, phone: this.phone, email: this.email, login: this.login, webSiteAccess: this.webSiteAccess, canBookOnline: this.canBookOnline, resourceServiceProviderId: this.serviceProviderID, dayOffList: dayOffListStorage, weekWorkingDays: weekWorkingDaysStorage, picture: this.pictureName, interval: this.interval, parameters: '{ "language": { "default": "fr"}}' }
    const myResource = { id: cognitoUser.sub, firstname: this.firstname, lastname: this.lastname, phone: this.phone, email: this.email, login: this.login, webSiteAccess: this.webSiteAccess, canBookOnline: this.canBookOnline, serviceProviderResourcesId: this.serviceProviderID, dayOffList: dayOffListStorage, weekWorkingDays: weekWorkingDaysStorage, picture: this.pictureName, interval: this.interval, parameters: '{ "language": { "default": "fr"}}' }
    const myNewResource = await API.graphql({ query: createResourceMutation, variables: { input: myResource }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
    // console.log(myNewResource)
    // console.log(myNewResource.data)
    this.id = myNewResource.data.createResource.id
    return myNewResource
  }

  async loadResource (resourceID) {
    try {
      // console.log('----Start load resource: ')
      // console.log('resourceID:::::::::: ', resourceID)
      const apiData = await API.graphql({ query: getResourceForLoad, variables: { id: resourceID }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      // console.log('apiData')
      // console.log(apiData)
      const resource = apiData.data.getResource
      // console.log('resource')
      // console.log(resource)
      this.id = resource.id
      this.firstname = resource.firstname
      this.lastname = resource.lastname
      this.phone = resource.phone
      this.email = resource.email
      this.login = resource.login
      this.interval = resource.interval
      // console.log('resource.picture:', resource.picture)
      if (resource.picture !== '') {
        this.pictureName = resource.picture
        this.pictureNameLoaded = resource.picture
        this.pictureUrl = await Storage.get(this.pictureNameLoaded, { level: 'public' })
      }
      this.webSiteAccess = resource.webSiteAccess
      this.canBookOnline = resource.canBookOnline
      this.unserialiseDayOffList(resource.dayOffList)
      this.unserialiseWeekWorkingDays(resource.weekWorkingDays)
      await this.appointments.load(this.id)
      // console.log('End load resource ---- ')
    } catch (err) {
      logger.error(err)
      // alert(this.i18n.t('ressources.alertFailCreateRes'))
      // console.log('End load resource ---- ')
    }
  }

  async loadResourceForCalendar (resourceID, event) {
    try {
      // console.log('loadResourceForCalendar: resourceID:', resourceID)
      const apiData = await API.graphql({ query: getResourceForCalendar, variables: { id: resourceID }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      // console.log(apiData)
      const resource = apiData.data.getResource
      // console.log(resource)
      this.id = resource.id
      this.firstname = resource.firstname
      this.lastname = resource.lastname
      this.unserialiseDayOffListForCalendar(resource.dayOffList, event)
      if (this.appointments.list.length === 0) {
        await this.appointments.load(this.id)
      }
      this.appointments.buildCalendarEvent(event)
    } catch (err) {
      logger.error(err)
    }
  }

  // Data of the Appointments are stored using this principle:
  //    appointmentList = [{ type: 'rangeByDuration', startDate: '2021-09-01', startHH: '14', startMM: '00', duration: '60' },
  //                       { type: 'rangeByDuration', startDate: '2021-09-10', startHH: '15', startMM: '00', duration: '60' }]
  // We need to convert them into a table of string to store them in the DB.
  // this example will be converted into
  // storage = ['rangeByDuration,2021-09-01,14,00,60',
  //            'rangeByDuration,2021-09-10,15,00,60']
  // serialiseAppointmentList (storage) {
    /*
    // console.log('serialize the Appointments')
    if (this.appointments.length === 0) {
      storage.push('')
      return
    }
    var line
    for (var i = 0; i < this.appointments.length; i++) {
      if (this.appointments[i].type === 'rangeByDuration') {
        line = this.appointments[i].type + ',' + this.appointments[i].startDate + ',' + this.appointments[i].startHH + ',' + this.appointments[i].startMM + ',' + this.appointments[i].duration
      }
      storage.push(line)
    }
    */
  // }

    // reverse function compare to serialiseAppointmentList
    // unserialiseAppointmentList (storage) {
      /*
      // empty the day off
      this.appointments.splice(0, this.dayOffList.length)
      // console.log('unserialize the day off')
      if (storage.length === 0) {
        return
      }
      var line = ''
      for (var i = 0; i < storage.length; i++) {
        if (storage[i] !== '') {
          line = storage[i].split(',')
          // console.log(line)
          if (line[0] === 'rangeByDuration') {
            this.dayOffList.push({ type: 'rangeByDuration', startDate: line[1], startHH: line[2], startMM: line[3], duration: line[4] })
          }
        }
      }
      */
    // }

  // Data of the Day off are stored using this principle:
  //    dayOffList = [{ type: 'rangeByDate', startDate: '2021-09-01', endDate: '2021-09-10' },
  //                  { type: 'rangeBytime', startDate: '2021-09-01', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }]
  // We need to convert them into a table of string to store them in the DB.
  // this example will be converted into
  // storage = ['rangeByDate,2021-09-01,2021-09-10'
  //            'rangeBytime,2021-09-01,13,00,14,00']
  serialiseDayOffList (storage) {
    // console.log('serialize the day off')
    if (this.dayOffList.length === 0) {
      storage.push('')
      return
    }
    var line, convertDateToUTCStart, convertDateToUTCEnd
    for (var i = 0; i < this.dayOffList.length; i++) {
      if (this.dayOffList[i].type === 'rangeByDate') {
        convertDateToUTCStart = convertDateTimeStringLocalUTC(this.dayOffList[i].startDate, '00', '00')
        convertDateToUTCEnd = convertDateTimeStringLocalUTC(this.dayOffList[i].endDate, '23', '59')
        // line = this.dayOffList[i].type + ',' + this.dayOffList[i].startDate + ',' + this.dayOffList[i].endDate
        line = this.dayOffList[i].type + ',' + convertDateToUTCStart.date + ',' + convertDateToUTCStart.HH + ',' + convertDateToUTCStart.MM + ',' + convertDateToUTCEnd.date + ',' + convertDateToUTCEnd.HH + ',' + convertDateToUTCEnd.MM
      } else {
        convertDateToUTCStart = convertDateTimeStringLocalUTC(this.dayOffList[i].startDate, this.dayOffList[i].startHH, this.dayOffList[i].startMM)
        convertDateToUTCEnd = convertDateTimeStringLocalUTC(this.dayOffList[i].startDate, this.dayOffList[i].endHH, this.dayOffList[i].endMM)
        // line = this.dayOffList[i].type + ',' + this.dayOffList[i].startDate + ',' + this.dayOffList[i].startHH + ',' + this.dayOffList[i].startMM + ',' + this.dayOffList[i].endHH + ',' + this.dayOffList[i].endMM
        line = this.dayOffList[i].type + ',' + convertDateToUTCStart.date + ',' + convertDateToUTCStart.HH + ',' + convertDateToUTCStart.MM + ',' + convertDateToUTCEnd.HH + ',' + convertDateToUTCEnd.MM
      }
      storage.push(line)
    }
  }

  // reverse function compare to serialiseDayOffList
  unserialiseDayOffList (storage) {
    // console.log(convertDateTimeStringLocalUTC('2021-08-23', '19', '00'))
    // console.log(convertDateTimeStringUTCLocal('2021-07-23', '19', '00'))
    // empty the day off
    this.dayOffList.splice(0, this.dayOffList.length)
    // console.log('unserialize the day off')
    if (storage.length === 0) {
      return
    }
    var line = ''
    var convertDateToLocalStart, convertDateToLocalEnd
    for (var i = 0; i < storage.length; i++) {
      if (storage[i] !== '') {
        line = storage[i].split(',')
        // console.log(line)
        if (line[0] === 'rangeByDate') {
          convertDateToLocalStart = convertDateTimeStringUTCLocal(line[1], line[2], line[3])
          convertDateToLocalEnd = convertDateTimeStringUTCLocal(line[4], line[5], line[6])
          // this.dayOffList.push({ type: 'rangeByDate', startDate: line[1], endDate: line[2] })
          this.dayOffList.push({ type: 'rangeByDate', startDate: convertDateToLocalStart.date, endDate: convertDateToLocalEnd.date })
        }
        if (line[0] === 'rangeBytime') {
          convertDateToLocalStart = convertDateTimeStringUTCLocal(line[1], line[2], line[3])
          convertDateToLocalEnd = convertDateTimeStringUTCLocal(line[1], line[4], line[5])
          // this.dayOffList.push({ type: 'rangeBytime', startDate: line[1], startHH: line[2], startMM: line[3], endHH: line[4], endMM: line[5] })
          this.dayOffList.push({ type: 'rangeBytime', startDate: convertDateToLocalStart.date, startHH: convertDateToLocalStart.HH, startMM: convertDateToLocalStart.MM, endHH: convertDateToLocalEnd.HH, endMM: convertDateToLocalEnd.MM })
        }
      }
    }
  }

  // reverse function compare to serialiseDayOffList
  unserialiseDayOffListForCalendar (storage, event) {
    // console.log('unserialize the day off')
    if (storage.length === 0) {
      return
    }
    var line = ''
    var startdateFetched, endDateFetched
    var convertDateToLocalStart, convertDateToLocalEnd
    for (var i = 0; i < storage.length; i++) {
      if (storage[i] !== '') {
        line = storage[i].split(',')
        // console.log(line)
        if (line[0] === 'rangeByDate') {
          // console.log('-------------------------line')
          // console.log(line)
          convertDateToLocalStart = convertDateTimeStringUTCLocal(line[1], line[2], line[3])
          // console.log(convertDateToLocalStart)
          convertDateToLocalEnd = convertDateTimeStringUTCLocal(line[4], line[5], line[6])
          // console.log(convertDateToLocalEnd)
          // startdateFetched = new Date(line[1])
          // endDateFetched = new Date(line[2])
          // startdateFetched = new Date(convertDateToLocalStart.date)
          startdateFetched = new Date(convertDateToLocalStart.date.substring(0, 4), parseInt(convertDateToLocalStart.date.substring(5, 7)) - 1, convertDateToLocalStart.date.substring(8, 10), convertDateToLocalStart.HH, convertDateToLocalStart.MM)
          endDateFetched = new Date(convertDateToLocalEnd.date.substring(0, 4), parseInt(convertDateToLocalEnd.date.substring(5, 7)) - 1, convertDateToLocalEnd.date.substring(8, 10), convertDateToLocalEnd.HH, convertDateToLocalEnd.MM)
          // console.log(startdateFetched)
          // endDateFetched = new Date(convertDateToLocalEnd.date)
          // console.log(endDateFetched)
          // console.log('startdateFetched')
          // console.log(startdateFetched)
          // console.log('endDateFetched')
          // console.log(endDateFetched)
          event.push({ type: 'Holiday', color: 'concrete', start: startdateFetched, startISO: convertDateToLocalStart.dateISO, end: endDateFetched, endISO: convertDateToLocalEnd.dateISO, name: 'Calendar.holiday', timed: false })
        }
        if (line[0] === 'rangeBytime') {
          convertDateToLocalStart = convertDateTimeStringUTCLocal(line[1], line[2], line[3])
          convertDateToLocalEnd = convertDateTimeStringUTCLocal(line[1], line[4], line[5])
          // startdateFetched = new Date(line[1].substring(0, 4), parseInt(line[1].substring(5, 7)) - 1, line[1].substring(8, 10), line[2], line[3])
          // endDateFetched = new Date(line[1].substring(0, 4), parseInt(line[1].substring(5, 7)) - 1, line[1].substring(8, 10), line[4], line[5])
          startdateFetched = new Date(convertDateToLocalStart.date.substring(0, 4), parseInt(convertDateToLocalStart.date.substring(5, 7)) - 1, convertDateToLocalStart.date.substring(8, 10), convertDateToLocalStart.HH, convertDateToLocalStart.MM)
          endDateFetched = new Date(convertDateToLocalStart.date.substring(0, 4), parseInt(convertDateToLocalStart.date.substring(5, 7)) - 1, convertDateToLocalStart.date.substring(8, 10), convertDateToLocalEnd.HH, convertDateToLocalEnd.MM)
          event.push({ type: 'Holiday', color: 'concrete', start: startdateFetched, startISO: convertDateToLocalStart, end: endDateFetched.dateISO, endISO: convertDateToLocalEnd.dateISO, name: 'Calendar.holiday', timed: true })
        }
      }
    }
  }

  // Data of the working day are stored using this principle:
  //    weekWorkingDays = [[{ type: 'Working', startHH: '10', startMM: '00', endHH: '17', endMM: '00' },
  //    { type: 'Break', startHH: '12', startMM: '00', endHH: '14', endMM: '00' }],
  //   [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
  //    { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
  //   [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
  //    { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
  //   [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
  //    { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
  //   [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
  //    { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
  //   [{ type: 'Working', startHH: '12', startMM: '00', endHH: '16', endMM: '00' },
  //   { type: 'Break', startHH: '09', startMM: '00', endHH: '10', endMM: '00' }],
  //  [{ type: 'Working', startHH: '12', startMM: '00', endHH: '16', endMM: '00' },
  //   { type: 'Break', startHH: '08', startMM: '00', endHH: '12', endMM: '00' }],
  // ]
  // We need to convert them into a table of string to store them in the DB.
  // this example will be converted into
  // storage = ['Working,10,00,17,00-Break,12,00,14,00',
  //            'Working,09,00,17,00-Break,13,00,14,00',
  //             etc.]
  serialiseWeekWorkingDays (storage) {
    // console.log('load the weekWorkingDays')
    if (this.weekWorkingDays.length === 0) {
      storage.push('')
      return
    }
    var line
    var j = 0
    for (var i = 0; i < this.weekWorkingDays.length; i++) {
        line = ''
      for (j = 0; j < this.weekWorkingDays[i].length; j++) {
        line = line + this.weekWorkingDays[i][j].type + ',' + this.weekWorkingDays[i][j].startHH + ',' + this.weekWorkingDays[i][j].startMM + ',' + this.weekWorkingDays[i][j].endHH + ',' + this.weekWorkingDays[i][j].endMM
        // console.log(line)
        if (j !== this.weekWorkingDays[i].length - 1) {
          line = line + '-'
        }
      }
      storage.push(line)
    }
  }

  // reverse function of serialiseWeekWorkingDays
  unserialiseWeekWorkingDays (storage) {
    // console.log('unserialise the weekWorkingDays')
    // empty the weekWorkingDays
    this.weekWorkingDays.splice(0, this.weekWorkingDays.length)
    // console.log(storage)
    if (storage.length === 0) {
      // We push one empty record for each day.
      this.weekWorkingDays.push([])
      this.weekWorkingDays.push([])
      this.weekWorkingDays.push([])
      this.weekWorkingDays.push([])
      this.weekWorkingDays.push([])
      this.weekWorkingDays.push([])
      this.weekWorkingDays.push([])
      return
    }
    var j = 0
    var line = ''
    var subLine = ''
    var dayTable = []
    var monday = []
    var tuesday = []
    var wednesday = []
    var thursday = []
    var friday = []
    var saturday = []
    var sunday = []

    for (var i = 0; i < storage.length; i++) {
      dayTable.splice(0, dayTable.length)
      if (storage[i] !== '') {
        line = storage[i].split('-')
        // console.log(line)
        j = 0
        while (j < line.length) {
          subLine = line[j].split(',')
          // console.log(subLine)
          if (subLine[0] === 'Working') {
            dayTable.push({ type: 'Working', startHH: subLine[1], startMM: subLine[2], endHH: subLine[3], endMM: subLine[4] })
          }
          if (subLine[0] === 'Break') {
            dayTable.push({ type: 'Break', startHH: subLine[1], startMM: subLine[2], endHH: subLine[3], endMM: subLine[4] })
          }
          j++
        }
        switch (i) {
          case 0: monday = [...dayTable]; break
          case 1: tuesday = [...dayTable]; break
          case 2: wednesday = [...dayTable]; break
          case 3: thursday = [...dayTable]; break
          case 4: friday = [...dayTable]; break
          case 5: saturday = [...dayTable]; break
          case 6: sunday = [...dayTable]; break
        }
      }
    }
    this.weekWorkingDays.push(monday)
    this.weekWorkingDays.push(tuesday)
    this.weekWorkingDays.push(wednesday)
    this.weekWorkingDays.push(thursday)
    this.weekWorkingDays.push(friday)
    this.weekWorkingDays.push(saturday)
    this.weekWorkingDays.push(sunday)
    // console.log(this.weekWorkingDays)
  }

  // delete the ressource from the DB
  async deleteResource () {
    try {
      // We make the list of the elements to be deleted in
      const myFilter = {
        resourceID: { eq: this.id },
      }
      // console.log('deletteeee')
      // console.log('myFilter:', myFilter)
      const apiData = await API.graphql({ query: listServiceResourcesSimplified, variables: { filter: myFilter }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      // console.log(apiData)
      const apiDataSimplified = apiData.data.listServiceResources.items
      // console.log(apiDataSimplified)
      for (var i = 0; i < apiDataSimplified.length; i++) {
        // We delete the reference Service/Ressource in ServiceResources table
        await API.graphql({ query: deleteServiceResourcesMutation, variables: { input: { id: apiDataSimplified[i].id } }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      }
      // Then we are deleting the ressource itself.
      await API.graphql({ query: deleteResourceMutation, variables: { input: { id: this.id } }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      // deleting cognitoUser
      await manageCognitoUsers(this.firstname, this.lastname, this.email, this.phone, this.serviceProviderID, this.webSiteAccess, 'DELETE', this.login)
      return true
    } catch (err) {
      logger.error(err)
      return false
    }
  }
}
