<!------------------------------------------------------------------------------
// Kidiwi Digital property
// Component to display the detailled information of the service provider (Name, Address, etc.):
// Props:
// - businessInfoCpyName: Name of the company.<template>
// - businessInfo : Array of object containing the contact info for the company.
// Example of usage:
  <kdw-dialog-infoeusp
    v-model="dialogCardInfo"
    :businessInfoCpyName="businessInfoCpyName"
    :businessInfo="businessInfo"
  />
  with dialogCardInfo that need to be set to true to display the Card result
// ------------------------------------------------------------------------------>

<template>
  <v-dialog
    max-width="500px"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <v-card-text>
        <base-section
          id="info-alt"
          :space="20"
        >
          <v-avatar
            v-if="urlLogo !== ''"
            width="50%"
            height="50%"
          >
            <img
              id="logo_display"
              :src="urlLogo"
              alt="Logo"
            >
          </v-avatar>
          <kdw-business-contact
            :title="businessInfoCpyName"
            :business-prop="businessInfo"
            dense
          />
        </base-section>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <kdw-btn
          id="closeDialogInfoEUButton"
          @click="closeDialog"
        >
          {{ $t('button.cancel') }}
        </kdw-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'KDWInfoEuSpDisplay',

    mixins: [Heading],

    props: {
      businessInfoCpyName: String,
      businessInfo: Array,
      urlLogo: {
        type: String,
        default: '',
      },
    },

    data: () => {
      return {
        dialogStatus: false,
      }
    },

    created: function () {
      // console.log('this.$attr = ', this.$attrs)
      // console.log('this.$listeners = ', this.$listeners)
      // console.log(this.urlLogo)
      // console.log(this.businessInfo)
      this.dialogStatus = false
    },
    methods: {
      emitResult () {
        this.$emit('input', this.dialogStatus)
      },
      closeDialog () {
        this.dialogStatus = false
        this.emitResult()
      },
    },
  }
</script>
