/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $input: CreateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    createAppointment(input: $input, condition: $condition) {
      id
      dateTime
      resourceID
      resourceFirstname
      resourceLastname
      serviceID
      serviceName
      serviceDuration
      enduserID
      enduserFirstName
      enduserLastname
      enduserPhone
      enduserMail
      answers {
        items {
          id
          appointmentID
          answerID
          createdAt
          updatedAt
          owner
          otherOwners
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $input: UpdateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    updateAppointment(input: $input, condition: $condition) {
      id
      dateTime
      resourceID
      resourceFirstname
      resourceLastname
      serviceID
      serviceName
      serviceDuration
      enduserID
      enduserFirstName
      enduserLastname
      enduserPhone
      enduserMail
      answers {
        items {
          id
          appointmentID
          answerID
          createdAt
          updatedAt
          owner
          otherOwners
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment(
    $input: DeleteAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      dateTime
      resourceID
      resourceFirstname
      resourceLastname
      serviceID
      serviceName
      serviceDuration
      enduserID
      enduserFirstName
      enduserLastname
      enduserPhone
      enduserMail
      answers {
        items {
          id
          appointmentID
          answerID
          createdAt
          updatedAt
          owner
          otherOwners
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createServiceResources = /* GraphQL */ `
  mutation CreateServiceResources(
    $input: CreateServiceResourcesInput!
    $condition: ModelServiceResourcesConditionInput
  ) {
    createServiceResources(input: $input, condition: $condition) {
      id
      serviceResourcesId
      service {
        id
        serviceProviderID
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        serviceDescription
        notePersonal
        serviceLegaldescription
        isFixedPrice
        isActivated
        keywords
        serviceAutoAccepted
        serviceDuration
        bufferTimeBefore
        bufferTimeAfter
        ServicePrice
        currency
        onlineBooking
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        requests {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceCategoryId
        serviceSubCategoryId
        servicePostcodecityId
        owner
      }
      resourceServicesId
      resource {
        id
        firstname
        lastname
        phone
        email
        login
        webSiteAccess
        canBookOnline
        picture
        interval
        weekWorkingDays
        dayOffList
        serviceProviderResourcesId
        serviceProvider {
          id
          otherOwners
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          namecpy
          logocpy
          siret
          siretVerified
          addresscpy1
          addresscpy2
          postcodecpy
          phonecpy
          emailcpy
          consentcpy
          rcs
          legalStatus
          parameters
          countMonthMaxActiveService
          walletSize
          countMonthAppearInSearch
          MaxWalletReached
          banned
          logoHistory
          recordHistory
          createdAt
          updatedAt
          serviceProviderPostcodecityId
          serviceProviderCountryId
          serviceProviderPostcodecitycpyId
          serviceProviderCountrycpyId
          owner
        }
        services {
          nextToken
        }
        parameters
        createdAt
        updatedAt
        owner
      }
      resourceID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateServiceResources = /* GraphQL */ `
  mutation UpdateServiceResources(
    $input: UpdateServiceResourcesInput!
    $condition: ModelServiceResourcesConditionInput
  ) {
    updateServiceResources(input: $input, condition: $condition) {
      id
      serviceResourcesId
      service {
        id
        serviceProviderID
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        serviceDescription
        notePersonal
        serviceLegaldescription
        isFixedPrice
        isActivated
        keywords
        serviceAutoAccepted
        serviceDuration
        bufferTimeBefore
        bufferTimeAfter
        ServicePrice
        currency
        onlineBooking
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        requests {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceCategoryId
        serviceSubCategoryId
        servicePostcodecityId
        owner
      }
      resourceServicesId
      resource {
        id
        firstname
        lastname
        phone
        email
        login
        webSiteAccess
        canBookOnline
        picture
        interval
        weekWorkingDays
        dayOffList
        serviceProviderResourcesId
        serviceProvider {
          id
          otherOwners
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          namecpy
          logocpy
          siret
          siretVerified
          addresscpy1
          addresscpy2
          postcodecpy
          phonecpy
          emailcpy
          consentcpy
          rcs
          legalStatus
          parameters
          countMonthMaxActiveService
          walletSize
          countMonthAppearInSearch
          MaxWalletReached
          banned
          logoHistory
          recordHistory
          createdAt
          updatedAt
          serviceProviderPostcodecityId
          serviceProviderCountryId
          serviceProviderPostcodecitycpyId
          serviceProviderCountrycpyId
          owner
        }
        services {
          nextToken
        }
        parameters
        createdAt
        updatedAt
        owner
      }
      resourceID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteServiceResources = /* GraphQL */ `
  mutation DeleteServiceResources(
    $input: DeleteServiceResourcesInput!
    $condition: ModelServiceResourcesConditionInput
  ) {
    deleteServiceResources(input: $input, condition: $condition) {
      id
      serviceResourcesId
      service {
        id
        serviceProviderID
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        serviceDescription
        notePersonal
        serviceLegaldescription
        isFixedPrice
        isActivated
        keywords
        serviceAutoAccepted
        serviceDuration
        bufferTimeBefore
        bufferTimeAfter
        ServicePrice
        currency
        onlineBooking
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        requests {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceCategoryId
        serviceSubCategoryId
        servicePostcodecityId
        owner
      }
      resourceServicesId
      resource {
        id
        firstname
        lastname
        phone
        email
        login
        webSiteAccess
        canBookOnline
        picture
        interval
        weekWorkingDays
        dayOffList
        serviceProviderResourcesId
        serviceProvider {
          id
          otherOwners
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          namecpy
          logocpy
          siret
          siretVerified
          addresscpy1
          addresscpy2
          postcodecpy
          phonecpy
          emailcpy
          consentcpy
          rcs
          legalStatus
          parameters
          countMonthMaxActiveService
          walletSize
          countMonthAppearInSearch
          MaxWalletReached
          banned
          logoHistory
          recordHistory
          createdAt
          updatedAt
          serviceProviderPostcodecityId
          serviceProviderCountryId
          serviceProviderPostcodecitycpyId
          serviceProviderCountrycpyId
          owner
        }
        services {
          nextToken
        }
        parameters
        createdAt
        updatedAt
        owner
      }
      resourceID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createResource = /* GraphQL */ `
  mutation CreateResource(
    $input: CreateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    createResource(input: $input, condition: $condition) {
      id
      firstname
      lastname
      phone
      email
      login
      webSiteAccess
      canBookOnline
      picture
      interval
      weekWorkingDays
      dayOffList
      serviceProviderResourcesId
      serviceProvider {
        id
        otherOwners
        firstname
        lastname
        address1
        address2
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        namecpy
        logocpy
        siret
        siretVerified
        addresscpy1
        addresscpy2
        postcodecpy
        postcodecitycpy {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        countrycpy {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phonecpy
        emailcpy
        consentcpy
        rcs
        legalStatus
        parameters
        answers {
          nextToken
        }
        countMonthMaxActiveService
        walletSize
        countMonthAppearInSearch
        MaxWalletReached
        banned
        logoHistory
        recordHistory
        costs {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceProviderPostcodecityId
        serviceProviderCountryId
        serviceProviderPostcodecitycpyId
        serviceProviderCountrycpyId
        owner
      }
      services {
        items {
          id
          serviceResourcesId
          resourceServicesId
          resourceID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      parameters
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateResource = /* GraphQL */ `
  mutation UpdateResource(
    $input: UpdateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    updateResource(input: $input, condition: $condition) {
      id
      firstname
      lastname
      phone
      email
      login
      webSiteAccess
      canBookOnline
      picture
      interval
      weekWorkingDays
      dayOffList
      serviceProviderResourcesId
      serviceProvider {
        id
        otherOwners
        firstname
        lastname
        address1
        address2
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        namecpy
        logocpy
        siret
        siretVerified
        addresscpy1
        addresscpy2
        postcodecpy
        postcodecitycpy {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        countrycpy {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phonecpy
        emailcpy
        consentcpy
        rcs
        legalStatus
        parameters
        answers {
          nextToken
        }
        countMonthMaxActiveService
        walletSize
        countMonthAppearInSearch
        MaxWalletReached
        banned
        logoHistory
        recordHistory
        costs {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceProviderPostcodecityId
        serviceProviderCountryId
        serviceProviderPostcodecitycpyId
        serviceProviderCountrycpyId
        owner
      }
      services {
        items {
          id
          serviceResourcesId
          resourceServicesId
          resourceID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      parameters
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteResource = /* GraphQL */ `
  mutation DeleteResource(
    $input: DeleteResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    deleteResource(input: $input, condition: $condition) {
      id
      firstname
      lastname
      phone
      email
      login
      webSiteAccess
      canBookOnline
      picture
      interval
      weekWorkingDays
      dayOffList
      serviceProviderResourcesId
      serviceProvider {
        id
        otherOwners
        firstname
        lastname
        address1
        address2
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        namecpy
        logocpy
        siret
        siretVerified
        addresscpy1
        addresscpy2
        postcodecpy
        postcodecitycpy {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        countrycpy {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phonecpy
        emailcpy
        consentcpy
        rcs
        legalStatus
        parameters
        answers {
          nextToken
        }
        countMonthMaxActiveService
        walletSize
        countMonthAppearInSearch
        MaxWalletReached
        banned
        logoHistory
        recordHistory
        costs {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceProviderPostcodecityId
        serviceProviderCountryId
        serviceProviderPostcodecitycpyId
        serviceProviderCountrycpyId
        owner
      }
      services {
        items {
          id
          serviceResourcesId
          resourceServicesId
          resourceID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      parameters
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createEndUser = /* GraphQL */ `
  mutation CreateEndUser(
    $input: CreateEndUserInput!
    $condition: ModelEndUserConditionInput
  ) {
    createEndUser(input: $input, condition: $condition) {
      id
      otherOwners
      firstname
      lastname
      address1
      address2
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      postcode
      country {
        id
        alpha2
        name
        createdAt
        updatedAt
        owner
      }
      phone
      email
      consent
      cookieParameters
      cookieUpdateDate
      parameters
      requests {
        items {
          id
          otherOwners
          endUserRequestsId
          serviceName
          categoryID
          qna
          isFixedPrice
          isProposalSP
          datetimeExpiry
          state
          createdAt
          updatedAt
          requestCategoryId
          requestSubCategoryId
          requestPostcodecityId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      endUserPostcodecityId
      endUserCountryId
      owner
    }
  }
`;
export const updateEndUser = /* GraphQL */ `
  mutation UpdateEndUser(
    $input: UpdateEndUserInput!
    $condition: ModelEndUserConditionInput
  ) {
    updateEndUser(input: $input, condition: $condition) {
      id
      otherOwners
      firstname
      lastname
      address1
      address2
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      postcode
      country {
        id
        alpha2
        name
        createdAt
        updatedAt
        owner
      }
      phone
      email
      consent
      cookieParameters
      cookieUpdateDate
      parameters
      requests {
        items {
          id
          otherOwners
          endUserRequestsId
          serviceName
          categoryID
          qna
          isFixedPrice
          isProposalSP
          datetimeExpiry
          state
          createdAt
          updatedAt
          requestCategoryId
          requestSubCategoryId
          requestPostcodecityId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      endUserPostcodecityId
      endUserCountryId
      owner
    }
  }
`;
export const deleteEndUser = /* GraphQL */ `
  mutation DeleteEndUser(
    $input: DeleteEndUserInput!
    $condition: ModelEndUserConditionInput
  ) {
    deleteEndUser(input: $input, condition: $condition) {
      id
      otherOwners
      firstname
      lastname
      address1
      address2
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      postcode
      country {
        id
        alpha2
        name
        createdAt
        updatedAt
        owner
      }
      phone
      email
      consent
      cookieParameters
      cookieUpdateDate
      parameters
      requests {
        items {
          id
          otherOwners
          endUserRequestsId
          serviceName
          categoryID
          qna
          isFixedPrice
          isProposalSP
          datetimeExpiry
          state
          createdAt
          updatedAt
          requestCategoryId
          requestSubCategoryId
          requestPostcodecityId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      endUserPostcodecityId
      endUserCountryId
      owner
    }
  }
`;
export const createServiceProvider = /* GraphQL */ `
  mutation CreateServiceProvider(
    $input: CreateServiceProviderInput!
    $condition: ModelServiceProviderConditionInput
  ) {
    createServiceProvider(input: $input, condition: $condition) {
      id
      otherOwners
      firstname
      lastname
      address1
      address2
      postcode
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      country {
        id
        alpha2
        name
        createdAt
        updatedAt
        owner
      }
      phone
      email
      consent
      cookieParameters
      cookieUpdateDate
      namecpy
      logocpy
      siret
      siretVerified
      addresscpy1
      addresscpy2
      postcodecpy
      postcodecitycpy {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      countrycpy {
        id
        alpha2
        name
        createdAt
        updatedAt
        owner
      }
      phonecpy
      emailcpy
      consentcpy
      rcs
      legalStatus
      parameters
      answers {
        items {
          id
          otherOwners
          serviceProviderAnswersId
          requestAnswersId
          servicePrice
          currency
          quotation
          datetimeExpiry
          datetimeBookingQuotation
          datetimeBookingJob
          noteCustomer
          notePersonal
          serviceLegaldescription
          state
          onlineBooking
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      countMonthMaxActiveService
      walletSize
      countMonthAppearInSearch
      MaxWalletReached
      banned
      logoHistory
      recordHistory
      costs {
        items {
          id
          serviceProviderCostsId
          date
          price
          cost
          invoice
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      resources {
        items {
          id
          firstname
          lastname
          phone
          email
          login
          webSiteAccess
          canBookOnline
          picture
          interval
          weekWorkingDays
          dayOffList
          serviceProviderResourcesId
          parameters
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      serviceProviderPostcodecityId
      serviceProviderCountryId
      serviceProviderPostcodecitycpyId
      serviceProviderCountrycpyId
      owner
    }
  }
`;
export const updateServiceProvider = /* GraphQL */ `
  mutation UpdateServiceProvider(
    $input: UpdateServiceProviderInput!
    $condition: ModelServiceProviderConditionInput
  ) {
    updateServiceProvider(input: $input, condition: $condition) {
      id
      otherOwners
      firstname
      lastname
      address1
      address2
      postcode
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      country {
        id
        alpha2
        name
        createdAt
        updatedAt
        owner
      }
      phone
      email
      consent
      cookieParameters
      cookieUpdateDate
      namecpy
      logocpy
      siret
      siretVerified
      addresscpy1
      addresscpy2
      postcodecpy
      postcodecitycpy {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      countrycpy {
        id
        alpha2
        name
        createdAt
        updatedAt
        owner
      }
      phonecpy
      emailcpy
      consentcpy
      rcs
      legalStatus
      parameters
      answers {
        items {
          id
          otherOwners
          serviceProviderAnswersId
          requestAnswersId
          servicePrice
          currency
          quotation
          datetimeExpiry
          datetimeBookingQuotation
          datetimeBookingJob
          noteCustomer
          notePersonal
          serviceLegaldescription
          state
          onlineBooking
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      countMonthMaxActiveService
      walletSize
      countMonthAppearInSearch
      MaxWalletReached
      banned
      logoHistory
      recordHistory
      costs {
        items {
          id
          serviceProviderCostsId
          date
          price
          cost
          invoice
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      resources {
        items {
          id
          firstname
          lastname
          phone
          email
          login
          webSiteAccess
          canBookOnline
          picture
          interval
          weekWorkingDays
          dayOffList
          serviceProviderResourcesId
          parameters
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      serviceProviderPostcodecityId
      serviceProviderCountryId
      serviceProviderPostcodecitycpyId
      serviceProviderCountrycpyId
      owner
    }
  }
`;
export const deleteServiceProvider = /* GraphQL */ `
  mutation DeleteServiceProvider(
    $input: DeleteServiceProviderInput!
    $condition: ModelServiceProviderConditionInput
  ) {
    deleteServiceProvider(input: $input, condition: $condition) {
      id
      otherOwners
      firstname
      lastname
      address1
      address2
      postcode
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      country {
        id
        alpha2
        name
        createdAt
        updatedAt
        owner
      }
      phone
      email
      consent
      cookieParameters
      cookieUpdateDate
      namecpy
      logocpy
      siret
      siretVerified
      addresscpy1
      addresscpy2
      postcodecpy
      postcodecitycpy {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      countrycpy {
        id
        alpha2
        name
        createdAt
        updatedAt
        owner
      }
      phonecpy
      emailcpy
      consentcpy
      rcs
      legalStatus
      parameters
      answers {
        items {
          id
          otherOwners
          serviceProviderAnswersId
          requestAnswersId
          servicePrice
          currency
          quotation
          datetimeExpiry
          datetimeBookingQuotation
          datetimeBookingJob
          noteCustomer
          notePersonal
          serviceLegaldescription
          state
          onlineBooking
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      countMonthMaxActiveService
      walletSize
      countMonthAppearInSearch
      MaxWalletReached
      banned
      logoHistory
      recordHistory
      costs {
        items {
          id
          serviceProviderCostsId
          date
          price
          cost
          invoice
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      resources {
        items {
          id
          firstname
          lastname
          phone
          email
          login
          webSiteAccess
          canBookOnline
          picture
          interval
          weekWorkingDays
          dayOffList
          serviceProviderResourcesId
          parameters
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      serviceProviderPostcodecityId
      serviceProviderCountryId
      serviceProviderPostcodecitycpyId
      serviceProviderCountrycpyId
      owner
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      nameTag
      countryCode
      keywords
      validated
      questions
      subCategorys {
        items {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      nameTag
      countryCode
      keywords
      validated
      questions
      subCategorys {
        items {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      nameTag
      countryCode
      keywords
      validated
      questions
      subCategorys {
        items {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSubCategory = /* GraphQL */ `
  mutation CreateSubCategory(
    $input: CreateSubCategoryInput!
    $condition: ModelSubCategoryConditionInput
  ) {
    createSubCategory(input: $input, condition: $condition) {
      id
      categorySubCategorysId
      category {
        id
        name
        nameTag
        countryCode
        keywords
        validated
        questions
        subCategorys {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      name
      nameTag
      countryCode
      validated
      questions
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSubCategory = /* GraphQL */ `
  mutation UpdateSubCategory(
    $input: UpdateSubCategoryInput!
    $condition: ModelSubCategoryConditionInput
  ) {
    updateSubCategory(input: $input, condition: $condition) {
      id
      categorySubCategorysId
      category {
        id
        name
        nameTag
        countryCode
        keywords
        validated
        questions
        subCategorys {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      name
      nameTag
      countryCode
      validated
      questions
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSubCategory = /* GraphQL */ `
  mutation DeleteSubCategory(
    $input: DeleteSubCategoryInput!
    $condition: ModelSubCategoryConditionInput
  ) {
    deleteSubCategory(input: $input, condition: $condition) {
      id
      categorySubCategorysId
      category {
        id
        name
        nameTag
        countryCode
        keywords
        validated
        questions
        subCategorys {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      name
      nameTag
      countryCode
      validated
      questions
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      serviceProviderID
      categoryID
      category {
        id
        name
        nameTag
        countryCode
        keywords
        validated
        questions
        subCategorys {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      subCategory {
        id
        categorySubCategorysId
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        nameTag
        countryCode
        validated
        questions
        createdAt
        updatedAt
        owner
      }
      name
      serviceDescription
      notePersonal
      serviceLegaldescription
      isFixedPrice
      isActivated
      keywords
      serviceAutoAccepted
      serviceDuration
      bufferTimeBefore
      bufferTimeAfter
      ServicePrice
      currency
      onlineBooking
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      requests {
        items {
          id
          otherOwners
          serviceProviderID
          createdAtShort
          serviceRequestsId
          requestServicesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      resources {
        items {
          id
          serviceResourcesId
          resourceServicesId
          resourceID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      serviceCategoryId
      serviceSubCategoryId
      servicePostcodecityId
      owner
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      serviceProviderID
      categoryID
      category {
        id
        name
        nameTag
        countryCode
        keywords
        validated
        questions
        subCategorys {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      subCategory {
        id
        categorySubCategorysId
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        nameTag
        countryCode
        validated
        questions
        createdAt
        updatedAt
        owner
      }
      name
      serviceDescription
      notePersonal
      serviceLegaldescription
      isFixedPrice
      isActivated
      keywords
      serviceAutoAccepted
      serviceDuration
      bufferTimeBefore
      bufferTimeAfter
      ServicePrice
      currency
      onlineBooking
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      requests {
        items {
          id
          otherOwners
          serviceProviderID
          createdAtShort
          serviceRequestsId
          requestServicesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      resources {
        items {
          id
          serviceResourcesId
          resourceServicesId
          resourceID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      serviceCategoryId
      serviceSubCategoryId
      servicePostcodecityId
      owner
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      serviceProviderID
      categoryID
      category {
        id
        name
        nameTag
        countryCode
        keywords
        validated
        questions
        subCategorys {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      subCategory {
        id
        categorySubCategorysId
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        nameTag
        countryCode
        validated
        questions
        createdAt
        updatedAt
        owner
      }
      name
      serviceDescription
      notePersonal
      serviceLegaldescription
      isFixedPrice
      isActivated
      keywords
      serviceAutoAccepted
      serviceDuration
      bufferTimeBefore
      bufferTimeAfter
      ServicePrice
      currency
      onlineBooking
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      requests {
        items {
          id
          otherOwners
          serviceProviderID
          createdAtShort
          serviceRequestsId
          requestServicesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      resources {
        items {
          id
          serviceResourcesId
          resourceServicesId
          resourceID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      serviceCategoryId
      serviceSubCategoryId
      servicePostcodecityId
      owner
    }
  }
`;
export const createServiceRequests = /* GraphQL */ `
  mutation CreateServiceRequests(
    $input: CreateServiceRequestsInput!
    $condition: ModelServiceRequestsConditionInput
  ) {
    createServiceRequests(input: $input, condition: $condition) {
      id
      otherOwners
      serviceProviderID
      createdAtShort
      serviceRequestsId
      service {
        id
        serviceProviderID
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        serviceDescription
        notePersonal
        serviceLegaldescription
        isFixedPrice
        isActivated
        keywords
        serviceAutoAccepted
        serviceDuration
        bufferTimeBefore
        bufferTimeAfter
        ServicePrice
        currency
        onlineBooking
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        requests {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceCategoryId
        serviceSubCategoryId
        servicePostcodecityId
        owner
      }
      requestServicesId
      request {
        id
        otherOwners
        endUserRequestsId
        endUser {
          id
          otherOwners
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          parameters
          createdAt
          updatedAt
          endUserPostcodecityId
          endUserCountryId
          owner
        }
        services {
          nextToken
        }
        serviceName
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        qna
        isFixedPrice
        isProposalSP
        datetimeExpiry
        state
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        requestCategoryId
        requestSubCategoryId
        requestPostcodecityId
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateServiceRequests = /* GraphQL */ `
  mutation UpdateServiceRequests(
    $input: UpdateServiceRequestsInput!
    $condition: ModelServiceRequestsConditionInput
  ) {
    updateServiceRequests(input: $input, condition: $condition) {
      id
      otherOwners
      serviceProviderID
      createdAtShort
      serviceRequestsId
      service {
        id
        serviceProviderID
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        serviceDescription
        notePersonal
        serviceLegaldescription
        isFixedPrice
        isActivated
        keywords
        serviceAutoAccepted
        serviceDuration
        bufferTimeBefore
        bufferTimeAfter
        ServicePrice
        currency
        onlineBooking
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        requests {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceCategoryId
        serviceSubCategoryId
        servicePostcodecityId
        owner
      }
      requestServicesId
      request {
        id
        otherOwners
        endUserRequestsId
        endUser {
          id
          otherOwners
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          parameters
          createdAt
          updatedAt
          endUserPostcodecityId
          endUserCountryId
          owner
        }
        services {
          nextToken
        }
        serviceName
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        qna
        isFixedPrice
        isProposalSP
        datetimeExpiry
        state
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        requestCategoryId
        requestSubCategoryId
        requestPostcodecityId
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteServiceRequests = /* GraphQL */ `
  mutation DeleteServiceRequests(
    $input: DeleteServiceRequestsInput!
    $condition: ModelServiceRequestsConditionInput
  ) {
    deleteServiceRequests(input: $input, condition: $condition) {
      id
      otherOwners
      serviceProviderID
      createdAtShort
      serviceRequestsId
      service {
        id
        serviceProviderID
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        serviceDescription
        notePersonal
        serviceLegaldescription
        isFixedPrice
        isActivated
        keywords
        serviceAutoAccepted
        serviceDuration
        bufferTimeBefore
        bufferTimeAfter
        ServicePrice
        currency
        onlineBooking
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        requests {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceCategoryId
        serviceSubCategoryId
        servicePostcodecityId
        owner
      }
      requestServicesId
      request {
        id
        otherOwners
        endUserRequestsId
        endUser {
          id
          otherOwners
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          parameters
          createdAt
          updatedAt
          endUserPostcodecityId
          endUserCountryId
          owner
        }
        services {
          nextToken
        }
        serviceName
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        qna
        isFixedPrice
        isProposalSP
        datetimeExpiry
        state
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        requestCategoryId
        requestSubCategoryId
        requestPostcodecityId
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      otherOwners
      endUserRequestsId
      endUser {
        id
        otherOwners
        firstname
        lastname
        address1
        address2
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        postcode
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        parameters
        requests {
          nextToken
        }
        createdAt
        updatedAt
        endUserPostcodecityId
        endUserCountryId
        owner
      }
      services {
        items {
          id
          otherOwners
          serviceProviderID
          createdAtShort
          serviceRequestsId
          requestServicesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      serviceName
      categoryID
      category {
        id
        name
        nameTag
        countryCode
        keywords
        validated
        questions
        subCategorys {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      subCategory {
        id
        categorySubCategorysId
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        nameTag
        countryCode
        validated
        questions
        createdAt
        updatedAt
        owner
      }
      qna
      isFixedPrice
      isProposalSP
      datetimeExpiry
      state
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      answers {
        items {
          id
          otherOwners
          serviceProviderAnswersId
          requestAnswersId
          servicePrice
          currency
          quotation
          datetimeExpiry
          datetimeBookingQuotation
          datetimeBookingJob
          noteCustomer
          notePersonal
          serviceLegaldescription
          state
          onlineBooking
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      requestCategoryId
      requestSubCategoryId
      requestPostcodecityId
      owner
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      otherOwners
      endUserRequestsId
      endUser {
        id
        otherOwners
        firstname
        lastname
        address1
        address2
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        postcode
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        parameters
        requests {
          nextToken
        }
        createdAt
        updatedAt
        endUserPostcodecityId
        endUserCountryId
        owner
      }
      services {
        items {
          id
          otherOwners
          serviceProviderID
          createdAtShort
          serviceRequestsId
          requestServicesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      serviceName
      categoryID
      category {
        id
        name
        nameTag
        countryCode
        keywords
        validated
        questions
        subCategorys {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      subCategory {
        id
        categorySubCategorysId
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        nameTag
        countryCode
        validated
        questions
        createdAt
        updatedAt
        owner
      }
      qna
      isFixedPrice
      isProposalSP
      datetimeExpiry
      state
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      answers {
        items {
          id
          otherOwners
          serviceProviderAnswersId
          requestAnswersId
          servicePrice
          currency
          quotation
          datetimeExpiry
          datetimeBookingQuotation
          datetimeBookingJob
          noteCustomer
          notePersonal
          serviceLegaldescription
          state
          onlineBooking
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      requestCategoryId
      requestSubCategoryId
      requestPostcodecityId
      owner
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      otherOwners
      endUserRequestsId
      endUser {
        id
        otherOwners
        firstname
        lastname
        address1
        address2
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        postcode
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        parameters
        requests {
          nextToken
        }
        createdAt
        updatedAt
        endUserPostcodecityId
        endUserCountryId
        owner
      }
      services {
        items {
          id
          otherOwners
          serviceProviderID
          createdAtShort
          serviceRequestsId
          requestServicesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      serviceName
      categoryID
      category {
        id
        name
        nameTag
        countryCode
        keywords
        validated
        questions
        subCategorys {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      subCategory {
        id
        categorySubCategorysId
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        nameTag
        countryCode
        validated
        questions
        createdAt
        updatedAt
        owner
      }
      qna
      isFixedPrice
      isProposalSP
      datetimeExpiry
      state
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      answers {
        items {
          id
          otherOwners
          serviceProviderAnswersId
          requestAnswersId
          servicePrice
          currency
          quotation
          datetimeExpiry
          datetimeBookingQuotation
          datetimeBookingJob
          noteCustomer
          notePersonal
          serviceLegaldescription
          state
          onlineBooking
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      requestCategoryId
      requestSubCategoryId
      requestPostcodecityId
      owner
    }
  }
`;
export const createPostalCode = /* GraphQL */ `
  mutation CreatePostalCode(
    $input: CreatePostalCodeInput!
    $condition: ModelPostalCodeConditionInput
  ) {
    createPostalCode(input: $input, condition: $condition) {
      id
      codeINSEE
      countryCode
      townName
      postalCode
      latitude
      longitude
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePostalCode = /* GraphQL */ `
  mutation UpdatePostalCode(
    $input: UpdatePostalCodeInput!
    $condition: ModelPostalCodeConditionInput
  ) {
    updatePostalCode(input: $input, condition: $condition) {
      id
      codeINSEE
      countryCode
      townName
      postalCode
      latitude
      longitude
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePostalCode = /* GraphQL */ `
  mutation DeletePostalCode(
    $input: DeletePostalCodeInput!
    $condition: ModelPostalCodeConditionInput
  ) {
    deletePostalCode(input: $input, condition: $condition) {
      id
      codeINSEE
      countryCode
      townName
      postalCode
      latitude
      longitude
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry(
    $input: CreateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    createCountry(input: $input, condition: $condition) {
      id
      alpha2
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry(
    $input: UpdateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    updateCountry(input: $input, condition: $condition) {
      id
      alpha2
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry(
    $input: DeleteCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    deleteCountry(input: $input, condition: $condition) {
      id
      alpha2
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      otherOwners
      serviceProviderAnswersId
      serviceProvider {
        id
        otherOwners
        firstname
        lastname
        address1
        address2
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        namecpy
        logocpy
        siret
        siretVerified
        addresscpy1
        addresscpy2
        postcodecpy
        postcodecitycpy {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        countrycpy {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phonecpy
        emailcpy
        consentcpy
        rcs
        legalStatus
        parameters
        answers {
          nextToken
        }
        countMonthMaxActiveService
        walletSize
        countMonthAppearInSearch
        MaxWalletReached
        banned
        logoHistory
        recordHistory
        costs {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceProviderPostcodecityId
        serviceProviderCountryId
        serviceProviderPostcodecitycpyId
        serviceProviderCountrycpyId
        owner
      }
      requestAnswersId
      request {
        id
        otherOwners
        endUserRequestsId
        endUser {
          id
          otherOwners
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          parameters
          createdAt
          updatedAt
          endUserPostcodecityId
          endUserCountryId
          owner
        }
        services {
          nextToken
        }
        serviceName
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        qna
        isFixedPrice
        isProposalSP
        datetimeExpiry
        state
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        requestCategoryId
        requestSubCategoryId
        requestPostcodecityId
        owner
      }
      servicePrice
      currency
      quotation
      datetimeExpiry
      datetimeBookingQuotation
      datetimeBookingJob
      noteCustomer
      notePersonal
      serviceLegaldescription
      state
      onlineBooking
      appointments {
        items {
          id
          appointmentID
          answerID
          createdAt
          updatedAt
          owner
          otherOwners
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      otherOwners
      serviceProviderAnswersId
      serviceProvider {
        id
        otherOwners
        firstname
        lastname
        address1
        address2
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        namecpy
        logocpy
        siret
        siretVerified
        addresscpy1
        addresscpy2
        postcodecpy
        postcodecitycpy {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        countrycpy {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phonecpy
        emailcpy
        consentcpy
        rcs
        legalStatus
        parameters
        answers {
          nextToken
        }
        countMonthMaxActiveService
        walletSize
        countMonthAppearInSearch
        MaxWalletReached
        banned
        logoHistory
        recordHistory
        costs {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceProviderPostcodecityId
        serviceProviderCountryId
        serviceProviderPostcodecitycpyId
        serviceProviderCountrycpyId
        owner
      }
      requestAnswersId
      request {
        id
        otherOwners
        endUserRequestsId
        endUser {
          id
          otherOwners
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          parameters
          createdAt
          updatedAt
          endUserPostcodecityId
          endUserCountryId
          owner
        }
        services {
          nextToken
        }
        serviceName
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        qna
        isFixedPrice
        isProposalSP
        datetimeExpiry
        state
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        requestCategoryId
        requestSubCategoryId
        requestPostcodecityId
        owner
      }
      servicePrice
      currency
      quotation
      datetimeExpiry
      datetimeBookingQuotation
      datetimeBookingJob
      noteCustomer
      notePersonal
      serviceLegaldescription
      state
      onlineBooking
      appointments {
        items {
          id
          appointmentID
          answerID
          createdAt
          updatedAt
          owner
          otherOwners
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      otherOwners
      serviceProviderAnswersId
      serviceProvider {
        id
        otherOwners
        firstname
        lastname
        address1
        address2
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        namecpy
        logocpy
        siret
        siretVerified
        addresscpy1
        addresscpy2
        postcodecpy
        postcodecitycpy {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        countrycpy {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phonecpy
        emailcpy
        consentcpy
        rcs
        legalStatus
        parameters
        answers {
          nextToken
        }
        countMonthMaxActiveService
        walletSize
        countMonthAppearInSearch
        MaxWalletReached
        banned
        logoHistory
        recordHistory
        costs {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceProviderPostcodecityId
        serviceProviderCountryId
        serviceProviderPostcodecitycpyId
        serviceProviderCountrycpyId
        owner
      }
      requestAnswersId
      request {
        id
        otherOwners
        endUserRequestsId
        endUser {
          id
          otherOwners
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          parameters
          createdAt
          updatedAt
          endUserPostcodecityId
          endUserCountryId
          owner
        }
        services {
          nextToken
        }
        serviceName
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        qna
        isFixedPrice
        isProposalSP
        datetimeExpiry
        state
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        requestCategoryId
        requestSubCategoryId
        requestPostcodecityId
        owner
      }
      servicePrice
      currency
      quotation
      datetimeExpiry
      datetimeBookingQuotation
      datetimeBookingJob
      noteCustomer
      notePersonal
      serviceLegaldescription
      state
      onlineBooking
      appointments {
        items {
          id
          appointmentID
          answerID
          createdAt
          updatedAt
          owner
          otherOwners
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMonthlyCost = /* GraphQL */ `
  mutation CreateMonthlyCost(
    $input: CreateMonthlyCostInput!
    $condition: ModelMonthlyCostConditionInput
  ) {
    createMonthlyCost(input: $input, condition: $condition) {
      id
      serviceProviderCostsId
      serviceProvider {
        id
        otherOwners
        firstname
        lastname
        address1
        address2
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        namecpy
        logocpy
        siret
        siretVerified
        addresscpy1
        addresscpy2
        postcodecpy
        postcodecitycpy {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        countrycpy {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phonecpy
        emailcpy
        consentcpy
        rcs
        legalStatus
        parameters
        answers {
          nextToken
        }
        countMonthMaxActiveService
        walletSize
        countMonthAppearInSearch
        MaxWalletReached
        banned
        logoHistory
        recordHistory
        costs {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceProviderPostcodecityId
        serviceProviderCountryId
        serviceProviderPostcodecitycpyId
        serviceProviderCountrycpyId
        owner
      }
      date
      price
      cost
      invoice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateMonthlyCost = /* GraphQL */ `
  mutation UpdateMonthlyCost(
    $input: UpdateMonthlyCostInput!
    $condition: ModelMonthlyCostConditionInput
  ) {
    updateMonthlyCost(input: $input, condition: $condition) {
      id
      serviceProviderCostsId
      serviceProvider {
        id
        otherOwners
        firstname
        lastname
        address1
        address2
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        namecpy
        logocpy
        siret
        siretVerified
        addresscpy1
        addresscpy2
        postcodecpy
        postcodecitycpy {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        countrycpy {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phonecpy
        emailcpy
        consentcpy
        rcs
        legalStatus
        parameters
        answers {
          nextToken
        }
        countMonthMaxActiveService
        walletSize
        countMonthAppearInSearch
        MaxWalletReached
        banned
        logoHistory
        recordHistory
        costs {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceProviderPostcodecityId
        serviceProviderCountryId
        serviceProviderPostcodecitycpyId
        serviceProviderCountrycpyId
        owner
      }
      date
      price
      cost
      invoice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteMonthlyCost = /* GraphQL */ `
  mutation DeleteMonthlyCost(
    $input: DeleteMonthlyCostInput!
    $condition: ModelMonthlyCostConditionInput
  ) {
    deleteMonthlyCost(input: $input, condition: $condition) {
      id
      serviceProviderCostsId
      serviceProvider {
        id
        otherOwners
        firstname
        lastname
        address1
        address2
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        namecpy
        logocpy
        siret
        siretVerified
        addresscpy1
        addresscpy2
        postcodecpy
        postcodecitycpy {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        countrycpy {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phonecpy
        emailcpy
        consentcpy
        rcs
        legalStatus
        parameters
        answers {
          nextToken
        }
        countMonthMaxActiveService
        walletSize
        countMonthAppearInSearch
        MaxWalletReached
        banned
        logoHistory
        recordHistory
        costs {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceProviderPostcodecityId
        serviceProviderCountryId
        serviceProviderPostcodecitycpyId
        serviceProviderCountrycpyId
        owner
      }
      date
      price
      cost
      invoice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAppointmentAnswers = /* GraphQL */ `
  mutation CreateAppointmentAnswers(
    $input: CreateAppointmentAnswersInput!
    $condition: ModelAppointmentAnswersConditionInput
  ) {
    createAppointmentAnswers(input: $input, condition: $condition) {
      id
      appointmentID
      answerID
      appointment {
        id
        dateTime
        resourceID
        resourceFirstname
        resourceLastname
        serviceID
        serviceName
        serviceDuration
        enduserID
        enduserFirstName
        enduserLastname
        enduserPhone
        enduserMail
        answers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      answer {
        id
        otherOwners
        serviceProviderAnswersId
        serviceProvider {
          id
          otherOwners
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          namecpy
          logocpy
          siret
          siretVerified
          addresscpy1
          addresscpy2
          postcodecpy
          phonecpy
          emailcpy
          consentcpy
          rcs
          legalStatus
          parameters
          countMonthMaxActiveService
          walletSize
          countMonthAppearInSearch
          MaxWalletReached
          banned
          logoHistory
          recordHistory
          createdAt
          updatedAt
          serviceProviderPostcodecityId
          serviceProviderCountryId
          serviceProviderPostcodecitycpyId
          serviceProviderCountrycpyId
          owner
        }
        requestAnswersId
        request {
          id
          otherOwners
          endUserRequestsId
          serviceName
          categoryID
          qna
          isFixedPrice
          isProposalSP
          datetimeExpiry
          state
          createdAt
          updatedAt
          requestCategoryId
          requestSubCategoryId
          requestPostcodecityId
          owner
        }
        servicePrice
        currency
        quotation
        datetimeExpiry
        datetimeBookingQuotation
        datetimeBookingJob
        noteCustomer
        notePersonal
        serviceLegaldescription
        state
        onlineBooking
        appointments {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
      otherOwners
    }
  }
`;
export const updateAppointmentAnswers = /* GraphQL */ `
  mutation UpdateAppointmentAnswers(
    $input: UpdateAppointmentAnswersInput!
    $condition: ModelAppointmentAnswersConditionInput
  ) {
    updateAppointmentAnswers(input: $input, condition: $condition) {
      id
      appointmentID
      answerID
      appointment {
        id
        dateTime
        resourceID
        resourceFirstname
        resourceLastname
        serviceID
        serviceName
        serviceDuration
        enduserID
        enduserFirstName
        enduserLastname
        enduserPhone
        enduserMail
        answers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      answer {
        id
        otherOwners
        serviceProviderAnswersId
        serviceProvider {
          id
          otherOwners
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          namecpy
          logocpy
          siret
          siretVerified
          addresscpy1
          addresscpy2
          postcodecpy
          phonecpy
          emailcpy
          consentcpy
          rcs
          legalStatus
          parameters
          countMonthMaxActiveService
          walletSize
          countMonthAppearInSearch
          MaxWalletReached
          banned
          logoHistory
          recordHistory
          createdAt
          updatedAt
          serviceProviderPostcodecityId
          serviceProviderCountryId
          serviceProviderPostcodecitycpyId
          serviceProviderCountrycpyId
          owner
        }
        requestAnswersId
        request {
          id
          otherOwners
          endUserRequestsId
          serviceName
          categoryID
          qna
          isFixedPrice
          isProposalSP
          datetimeExpiry
          state
          createdAt
          updatedAt
          requestCategoryId
          requestSubCategoryId
          requestPostcodecityId
          owner
        }
        servicePrice
        currency
        quotation
        datetimeExpiry
        datetimeBookingQuotation
        datetimeBookingJob
        noteCustomer
        notePersonal
        serviceLegaldescription
        state
        onlineBooking
        appointments {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
      otherOwners
    }
  }
`;
export const deleteAppointmentAnswers = /* GraphQL */ `
  mutation DeleteAppointmentAnswers(
    $input: DeleteAppointmentAnswersInput!
    $condition: ModelAppointmentAnswersConditionInput
  ) {
    deleteAppointmentAnswers(input: $input, condition: $condition) {
      id
      appointmentID
      answerID
      appointment {
        id
        dateTime
        resourceID
        resourceFirstname
        resourceLastname
        serviceID
        serviceName
        serviceDuration
        enduserID
        enduserFirstName
        enduserLastname
        enduserPhone
        enduserMail
        answers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      answer {
        id
        otherOwners
        serviceProviderAnswersId
        serviceProvider {
          id
          otherOwners
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          namecpy
          logocpy
          siret
          siretVerified
          addresscpy1
          addresscpy2
          postcodecpy
          phonecpy
          emailcpy
          consentcpy
          rcs
          legalStatus
          parameters
          countMonthMaxActiveService
          walletSize
          countMonthAppearInSearch
          MaxWalletReached
          banned
          logoHistory
          recordHistory
          createdAt
          updatedAt
          serviceProviderPostcodecityId
          serviceProviderCountryId
          serviceProviderPostcodecitycpyId
          serviceProviderCountrycpyId
          owner
        }
        requestAnswersId
        request {
          id
          otherOwners
          endUserRequestsId
          serviceName
          categoryID
          qna
          isFixedPrice
          isProposalSP
          datetimeExpiry
          state
          createdAt
          updatedAt
          requestCategoryId
          requestSubCategoryId
          requestPostcodecityId
          owner
        }
        servicePrice
        currency
        quotation
        datetimeExpiry
        datetimeBookingQuotation
        datetimeBookingJob
        noteCustomer
        notePersonal
        serviceLegaldescription
        state
        onlineBooking
        appointments {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
      otherOwners
    }
  }
`;
export const updateSpCounters = /* GraphQL */ `
  mutation UpdateSpCounters($spId: String!) {
    updateSpCounters(spId: $spId)
  }
`;
export const sendEmailGQL = /* GraphQL */ `
  mutation SendEmailGQL($templateName: String!, $templateParams: String!) {
    sendEmailGQL(templateName: $templateName, templateParams: $templateParams)
  }
`;
export const manageCognitoUser = /* GraphQL */ `
  mutation ManageCognitoUser($userData: String!) {
    manageCognitoUser(userData: $userData)
  }
`;
export const stateMachine = /* GraphQL */ `
  mutation StateMachine($params: String!) {
    stateMachine(params: $params)
  }
`;
