<!------------------------------------------------------------------------------
// Kidiwi Digital property
// Component to display the detailled information of the service provided by the service provided (Service Name, description of the service, Price, etc.):
// Props:
// - ServiceDetails: that contains the details elements of the service as an object: ServicePrice, serviceDuration,serviceDescription, category, subCategory
// Example of usage:
      <kdw-dialog-info-service
        :value="dialog"
        :serviceDetails="editedItem"
      />
  with dialog that need to be set to true to display the Dialog Card.
// ------------------------------------------------------------------------------>

<template>
  <v-dialog
    max-width="500px"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-container
      style="padding: 0px"
    >
      <v-card
        class="rounded-xl"
      >
        <v-card-title
          class="primary--text text-h5 ml-4"
          style="white-space: break-spaces"
        >
          {{ serviceDetails.name }}
        </v-card-title>
        <!--- THIS IS THE DESKTOP PART ----->
        <v-card-text
          class="hidden-sm-and-down"
        >
          <v-list-item>
            <!------- Company logo   ---->
            <v-list-item-avatar
              v-if="serviceDetails.cpyLogo === '' || serviceDetails.cpyLogo === null || serviceDetails.cpyLogo === undefined"
              size="60"
            >
              <v-img :src="require('@/assets/Avatar-02.svg')" />
            </v-list-item-avatar>
            <v-list-item-avatar
              v-if="serviceDetails.cpyLogo !== '' && serviceDetails.cpyLogo !== null && serviceDetails.cpyLogo !== undefined"
              size="60"
            >
              <v-img :src="urlLogo" />
            </v-list-item-avatar>
            <!------- 3 lignes with: SubCategory, Short description, Town Name  ---->
            <v-list-item-content>
              <v-list-item-subtitle
                style="white-space: normal"
              >
                <v-icon small>
                  mdi-folder-pound
                </v-icon>
                {{ serviceDetails.category }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="serviceDetails.subCategory !== undefined && serviceDetails.subCategory !== ''"
                style="white-space: normal"
              >
                <v-icon small>
                  mdi-folder-pound-outline
                </v-icon>
                {{ serviceDetails.subCategory }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                style="white-space: normal"
              >
                <v-icon
                  small
                >
                  mdi-map-marker
                </v-icon>
                {{ serviceDetails.townName }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-spacer />
            <v-list-item-action>
              <kdw-label
                :text="serviceDetails.ServicePriceDisplay"
              />
            </v-list-item-action>
          </v-list-item>
          <!-------  Description  & Duration ---->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle
                class="font-italic"
              >
                {{ $t('hero.descriptionTable') }}:
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <strong
                  style="white-space: pre-wrap"
                >
                  {{ serviceDetails.serviceDescription || $t('hero.nodescription') }}
                </strong>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <kdw-label
                :text="serviceDetails.serviceDuration"
                :white="false"
              />
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle>
              <strong
                style="white-space: pre-wrap"
              >
                {{ serviceDetails.serviceLegaldescription || $t('hero.nodescription') }}
              </strong>
            </v-list-item-subtitle>
          </v-list-item>
        </v-card-text>
        <!-- THIS IS FOR MOBILE --->
        <v-card-text
          class="hidden-md-and-up"
        >
          <v-list-item>
            <!------- Company logo   ---->
            <v-list-item-avatar
              v-if="serviceDetails.cpyLogo === '' || serviceDetails.cpyLogo === null || serviceDetails.cpyLogo === undefined"
              size="60"
            >
              <v-img :src="require('@/assets/Avatar-02.svg')" />
            </v-list-item-avatar>
            <v-list-item-avatar
              v-if="serviceDetails.cpyLogo !== '' && serviceDetails.cpyLogo !== null && serviceDetails.cpyLogo !== undefined"
              size="60"
            >
              <v-img :src="urlLogo" />
            </v-list-item-avatar>
            <!------- 3 lignes with: SubCategory, Short description, Town Name  ---->
            <v-list-item-content>
              <v-list-item-subtitle
                style="white-space: normal"
              >
                <v-icon small>
                  mdi-folder-pound
                </v-icon>
                {{ serviceDetails.category }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="serviceDetails.subCategory !== undefined && serviceDetails.subCategory !== ''"
                style="white-space: normal"
              >
                <v-icon small>
                  mdi-folder-pound-outline
                </v-icon>
                {{ serviceDetails.subCategory }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                style="white-space: normal"
              >
                <v-icon
                  small
                >
                  mdi-map-marker
                </v-icon>
                {{ serviceDetails.townName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-------  Description  & Duration ---->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle
                class="font-italic"
              >
                {{ $t('hero.descriptionTable') }}:
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <strong
                  style="white-space: pre-wrap"
                >
                  {{ serviceDetails.serviceDescription || $t('hero.nodescription') }}
                </strong>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <kdw-label
                :text="serviceDetails.ServicePriceDisplay"
              />
            </v-list-item-action>
            <v-spacer />
            <v-list-item-action>
              <kdw-label
                :text="serviceDetails.serviceDuration"
                :white="false"
              />
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle>
              <strong
                style="white-space: pre-wrap"
              >
                {{ serviceDetails.serviceLegaldescription || $t('hero.nodescription') }}
              </strong>
            </v-list-item-subtitle>
          </v-list-item>
        </v-card-text>

        <!-------  Cancel button  ---->
        <v-card-actions>
          <v-spacer />
          <!-- TODO cancel is scary, maybe not the best wording -->
          <kdw-btn
            color="primary"
            text
            outlined
            class="mr-5"
            @click="closeDialog"
          >
            {{ $t('button.ok') }}
          </kdw-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'
  import { Logger, Storage } from 'aws-amplify'

  const logger = new Logger('DialogInfoService')

  export default {
    name: 'KDWServiceDetailResult',
    mixins: [Heading],
    props: {
      serviceDetails: Object,
      logo: String,
    },

    data: () => {
      return {
        dialogStatus: false,
        urlLogo: null,
      }
    },

    watch: {
      logo: function (val) {
        // console.log('logo changed:', val)
        if ((this.logo !== undefined) && (this.logo !== null) && (this.logo !== '')) {
          Storage.get(val, { level: 'public' })
            .then(result => {
              this.urlLogo = result
              // console.log('result of the url we got from the storage:')
              // console.log(result)
            })
            .catch(err => logger.error(err))
        }
      },
    },

    created: function () {
      // console.log('this.$attr = ', this.$attrs)
      // console.log('this.$listeners = ', this.$listeners)
      this.dialogStatus = false
    },
    methods: {
      emitResult () {
        this.$emit('input', this.dialogStatus)
      },
      closeDialog () {
        this.dialogStatus = false
        this.emitResult()
      },
    },
  }
</script>
