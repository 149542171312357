// ------------------------------------------------------------------------------
// Kidiwi Digital property
// Management of the Files
// ------------------------------------------------------------------------------

export const FILEHEADERLOGO = '-LOGO-'
// Be carefull as the space between the 2 lines is important !!
export const FILEHEADERQUOTATION = '-QUOTATION-'

export const FILEHEADERPICTURERESSOURCE = '-PICTURERESOURCE-'
