<!------------------------------------------------------------------------------
// Kidiwi Digital property
// Component to display the detailled information of the service provider (Name, Address, etc.).
// This is used to display the resource with an appointment.
// Props:
// - businessInfoCpyName: Name of the company.<template>
// - businessInfo : Array of object containing the contact info for the company.
// Example of usage:
  <kdw-dialog-info-resource
    v-model="dialogCardInfo"
  />
  with dialogCardInfo that need to be set to true to display the Card result
// ------------------------------------------------------------------------------>

<template>
  <v-theme-provider :dark="dark">
    <v-dialog
      max-width="500px"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card>
        <v-card-text>
          <base-title
            :title="title"
            class="text-uppercase"
            space="1"
          />
          <base-divider :color="color" />
        </v-card-text>
        <!--
        <v-row
          :justify="justify"
          no-gutters
        >
          <v-col
            v-if="icon"
            :class="`text-${align}`"
            cols="12"
            class="mb-4"
          >
            <base-icon :color="color">
              {{ icon }}
            </base-icon>
          </v-col>

          <v-col
            v-if="title || subtitle"
            :cols="callout ? 9 : 12"
          >
            <base-subtitle
              v-if="subtitle"
              :title="$t(subtitle)"
              space="1"
            />

            <base-title
              :title="$t(title)"
              class="text-uppercase"
              space="1"
            />

            <base-divider :color="color" />
            <base-body
              v-if="text || $slots.default"
              :text="$t(text)"
              space="6"
            >
              <slot />
            </base-body>
          </v-col>
        </v-row>
        -->
        <v-card-text
          v-if="callout"
        >
          {{ callout }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <kdw-btn
            @click="closeDialog"
          >
            {{ $t('button.cancel') }}
          </kdw-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-theme-provider>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'ResourceInfo',

    mixins: [Heading],

    props: {
      dark: Boolean,
      callout: String,
      color: {
        type: String,
        default: 'primary',
      },
      icon: String,
      subtitle: String,
      text: String,
      title: String,
    },

    data: () => {
      return {
        dialogStatus: false,
      }
    },

    created: function () {
      this.dialogStatus = false
    },

    methods: {
      emitResult () {
        this.$emit('input', this.dialogStatus)
      },
      closeDialog () {
        this.dialogStatus = false
        this.emitResult()
      },
    },
  }
</script>
