import vuetify from 'vuetify/es5/locale/fr'

export default {
    $vuetify: { ...vuetify },
    parameters: {
        yes: 'Oui',
    },
    meta: {
        description: 'TPE et PME, voici un ensemble de services digitaux pour vous faire gagner du temps. Passez du temps sur votre activité, nous nous occupons du reste.',
        titleTemplate: 'Kidiwi Services | Transformation digitale accélérée pour les fournisseurs de services',
        star: '*',
    },
    error404: {
        mainline: 'Ouups! Il semblerait que cette page n’existe pas',
        getMeOutOfHere: 'Veuillez me sortir de là!',
    },
    topmenu: {
        login: 'Connectez-vous',
        professional: 'Professionels',
    },
    topMenuEU: {
        myRequestServices: 'Mes requêtes',
        agenda: 'Mon Agenda',
    },
    sideMenuEU: {
        account: 'Mon compte',
        parameters: 'Mes paramètres',
        param: 'Les paramètres de l’agenda',
        gdpr: 'GDPR',
        signOut: 'Déconnection',
    },
    topMenuSP: {
        market: 'Le marché',
        customer: 'Requêtes',
        miniweb: 'MiniWeb',
        agenda: 'Mon Agenda',
        rating: 'Rating',
    },
    sideMenuSP: {
        account: 'Mon compte',
        ressource: 'Mes ressources',
        services: 'Mes services',
        product: 'Mes produits',
        param: 'Mes paramètres',
        gdpr: 'GDPR',
        cost: 'Mes coûts',
        signOut: 'Déconnection',
    },
    faq: {
        title: 'Foire Aux Questions (FAQ)',
    },
    login: {
        title: 'Se Connecter et Créer un compte',
        signupheaderEU: 'Créer un compte Utilisateur',
        signupheaderSP: 'Créer un compte Fournisseur de Services',
    },
    hero: {
        title: 'Chercher un service',
        line1: {
            html: 'La <b>1ère plateforme</b>',
        },
        line2: {
            html: 'de <b>SERVICES MULTI-ACTIVITÉS</b>',
        },
        line3: '(Beauté & Bien-être, Déménagement, Service Médicaux, Artisans, etc...).',
        line4: {
            html: '<b>100% digital. Zéro commission.</b>',
        },
        postalCode: 'Code postal',
        city: 'Rechercher par ',
        cityPostal: 'Code postal ou Ville',
        radius: 'Rayon',
        searchService: 'Chercher un service',
        searchBtn: 'Rechercher...',
        details: 'Details',
        serviceTable: 'Nom du service',
        descriptionTable: 'Description',
        priceTable: 'Prix (TTC)',
        fixedPrice: 'Prix fixe (',
        quotationBased: 'Sur devis',
        durationTable: 'Durée',
        categoryTable: 'Catégorie',
        catTable: 'Cat.',
        subcategoryTable: 'Sous-catégorie',
        subcatTable: 'Sous-cat.',
        whereTable: 'Où',
        actionTable: 'Actions',
        searchAlert: 'Sélectionnez la ville et le type de service recherché',
        search: 'Recherche',
        dateRequest: 'Date demande',
        whoRequest: 'Qui demande',
        whereRequest: 'Provenant de',
        authenticator: 'Authentification',
        alertAuthenticatedNotEU: 'La personne qui vient de s’authentifier est un fournisseur de service, donc la demande de service effectuée précedement ne sera pas prise en compte',
        nodescription: '...',
    },
    worktimeDay: {
        dialogEditSlotTitle: 'Modifier les horaires',
        start: 'Début',
        end: 'Fin',
        working: 'Heures travaillées',
        work: 'Travail',
        break: 'Pause',
        errorStartTimeBiggerEndTime: 'L’heure de fin doit être inférieure à l’heure de début.',
        slotdeleted: 'Plage horaire détruite',
        workingSlotAlreadyExist: 'Une plage d’heures travaillées existe déja. Modifiez la si vous avez besoin de l’étendre.',
        breakSlotAdded: 'Une nouvelle plage de pause a été ajoutée.',
    },
    worktimeWeek: {
        monday: 'Lundi',
        tuesday: 'Mardi',
        wednesday: 'Mercredi',
        thursday: 'Jeudi',
        friday: 'Vendredi',
        saturday: 'Samedi',
        sunday: 'Dimanche',
    },
    dayOff: {
        byDaysPeriod: 'Par date',
        byTimePeriod: 'Par intervalle temps',
        dialogEditDayOffTitleByTime: 'Mise à jour du congés par intervalle de temps',
        dialogEditDayOffTitleByDate: 'Mise à jour du congés par intervalle de date',
        errorStartDateBiggerEndDate: 'La date de début doit être inférieure à la date de fin.',
    },
    calendar: {
        start: 'Début',
        end: 'Fin',
        by: 'Par',
        for: 'Pour',
    },
    ressourceService: {
        dialogRessourceService: 'Ajouter un service à cette ressource',
        deleteService: 'Supprimer ce service',
        alertSucessCreateRes: 'Service ajouté',
    },
    ressources: {
        title: 'Gérer mes ressources',
        mainTitle: 'Gérer mes ressources',
        tableTitle: 'Ressources',
        update: 'Mise à jour de la ressource',
        ressourceCreation: 'Création ressource',
        ressourceModification: 'Modification ressource',
        details: 'Détails',
        workhours: 'Heures travail',
        visibleExternally: 'Cette ressource a un acces externe (via site web)',
        idLogin: 'Login ID',
        dayOff: 'Congés',
        services: 'Services',
        bookingExternally: 'Cette ressource peut être utilisée par le système de réservation en ligne',
        resendCode: 'Renvoyer le code',
        alertFailCreateRes: 'Nous n’avons pas réussi à créer cette ressource.',
        alertSucessCreateRes: 'Ressource crée',
        alertFailUpdateRes: 'Nous n’avons pas réussi à mettre à jour cette ressource.',
        alertSucessUpdateRes: 'Ressource mise à jour',
        alertFailDeleteRes: 'Nous n’avons pas réussi à supprimer cette ressource.',
        alertSucessDeleteRes: 'Ressource supprimée',
        deleteQuestion: 'Etes-vous sûr de vouloir supprimer cette ressource ?',
        ressourceMissingParam: 'Merci de compléter les données obligatoires pour enregistrer la ressource',
        ressourcePhoneInvalid: 'Le numéro de téléphone est invalide',
        interval: 'Intervalle',
        interval_hint: 'Intervalle entre rendez-vous',
    },
    Booking: {
        dialogBookingTitle: 'Réservation de votre horaire',
        ressourceTitle: 'Ressources disponibles:',
        AppointmentTitle: 'Rendez-vous disponibles:',
        selectRessource: 'Selectionner votre ressource',
        selectAppointment: 'Selectionner un horaire',
        selectAnyRessources: 'Selectionner n’importe quelle ressource',
        anyRessourceSelected: 'Toutes',
        noSlotSelected: 'Pas d’horaire sélectionné',
        noBookingPast: 'Vous ne pouvez pas sélectionner une date dans le passé ou le jour même.',
        noSlotRessourceSelected: 'Selectionnez une ressource et une plage horaire!',
        bookingDone: 'Le rendez-vous est pris.',
    },
    Calendar: {
        month: 'Mois',
        week: 'Semaine',
        day: 'Jour',
        today: 'Ce jour',
        holiday: 'Vacance',
        all: 'Toutes',
    },
    button: {
        ok: 'OK',
        cancel: 'Annuler',
        previous: 'Précédent',
        validateQuestion: 'Valider',
        nextQuestion: 'Continuer',
        contactUs: 'Nous contacter',
        moreInfo: 'En savoir Plus',
        createAccountInfo: 'Créer un compte',
        deleteLogo: 'Supprimer logo',
        deleteRequestEU: 'Supprimer',
        stopRequestEU: 'Stop',
        startRequestEU: 'Active',
        question: 'Questions',
        answer: 'Réponses',
        newService: 'Nouveau Service',
        newRessource: 'Nouvelle ressource',
        close: 'Fermer',
        copyCpy: 'Copie données société',
        accept: 'Accepter',
        addBreakSlot: 'Ajouter une pause',
        addDayOffByRangeDate: 'Ajouter un congés de plusieurs jours',
        addDayOffByRangeTime: 'Ajouter un congés pour une date spécifiée et une période de temps',
        addWorkingSlot: 'Ajouter une plage de travail',
        copyWorkingSlot5Days: 'Copier les heures de travail de lundi sur les jours de Mardi à Vendredi',
        copyWorkingSlotAllDays: 'Copier les heures de travail de Lundi sur tous les jours de la semaine',
        deleteWorkingSlot: 'Supprimer les horaires',
        modifyWorkingSlot: 'Modifier les horaires',
        saveRessource: 'Sauver',
        addServiceInRessource: 'Ajouter un service',
        addAppointmentWithoutBooking: 'Ajouter une réservation',
    },
    tooltip: {
        validateEURequest: 'Valider la demande de service',
        cardVisitEU: 'Information sur le demandeur du service',
        cardVisitSP: 'Information sur le fournisseur du service',
        openQuotation: 'Ouverture du devis',
        editQuotation: 'Création/Edition du devis',
        createQuotation: 'Création du devis',
        onSiteQuotation: 'Me déplacer chez le demandeur du service pour faire le devis',
        question: 'Liste des réponses fournies par le demandeur de service',
        EUValidateSPProposal: 'Je valide cette proposition effectuée par le fournisseur de service',
        EURefuseProposal: 'Je refuse le devis effectuée par le fournisseur de service',
        PickSP: 'Sélectionner ce service',
        serviceOverview: 'Aperçu du service',
        deleteSP: 'Supprimer la proposition effectuée et son devis',
        deleteRessource: 'Supprimer',
        saveRessource: 'Sauver',
    },
    aboutOurProduct: {
        mainTitle: 'Notre produit',
        item1Title: 'Selectionner le lieu',
        item1SubTitle: 'Localisation',
        item1Text: 'Entrez votre code postal ou le nom de la ville ou vous souhaitez obtenir votre service. Ajuster le rayon de recherche de votre fournisseur.',
        item2Title: 'Choisir le service',
        item2SubTitle: 'Pas d’intermédiaire',
        item2Text: 'Tapez quelques lettres pour choisir votre service parmis toutes une liste.',
        item3Title: 'Recherche',
        item3SubTitle: 'Pas de commision',
        item3Text: 'Cliquez sur le bouton "Rechercher" pour faire apparaitre la liste des services disponibles. Sélectionnez en cliquant sur la flèche et démarrer votre demande. Si nous ne trouvons pas de fournisseur de service, nous enregistrons votre demande qui pourra être consultée par les fournisseurs de services ultérieurement.',
    },
    contactUs: {
        text1: 'DES QUESTIONS? FAITES LE NOUS SAVOIR!',
        text2: 'Nous répondons à l’ensemble de vos questions.',
    },
    kidiwiInfo: {
        address: 'Adresse',
        phone: 'Téléphone',
        mail: 'E-mail',
        contact: 'Contact',
        general: 'KidiwiDigital est une plateforme de services 100% digitale qui permet la mise en relation entre professionnels et particuliers.',
        legal_title: 'Légal',
        legal: 'Mention légales',
        cgu: 'Conditions d’utilisation',
        gdpr: 'Charte données personnelles',
    },
    heroPro: {
        title: 'Inscription',
        line1: 'Vendez vos services efficacement.',
        line2: 'Rejoignez l’expérience KidiwiDigital et donnez de la visibilité à votre entreprise afin de gagner des parts de marché.',
        or: 'ou',
    },
    addAppointmentWithoutBooking: {
        mainTitle: 'Ajouter un rendez-vous',
        resource: 'Ressource',
        service: 'Service',
        guess: 'Client',
        lackDate: 'Veuillez entrer la date du RDV',
        lackNameInfo: 'veuillez entrer des informations sur le nom du client',
        lackContactInfo: 'Veuillez entrer des informations pour contacter le client (mail ou téléphone)',
        okCreationAppoint: 'Le rendez-vous a été crée.',
        koCreationAppoint: 'Une erreur est survenue pendant la création du rendez-vous',
        deletedAppoint: 'RDV supprimé',
        addedAppoint: 'RDV ajouté',
    },
    themeFeature: {
        mainTitle: 'A PROPOS DE NOTRE PRODUIT',
        line1: 'KidiwiDigital vous facilite la mise en relation avec vos prospects et clients.',
        line2: 'Le service est à la portée de tous.',
        line3: 'Testez le service et faites-vous votre idée en toute sécurité.',
        title1: 'Services',
        text1: 'Kidiwi Services vous offre la possibilité de définir vos services simplement. Les services peuvent être à prix fixe ou sur la base de quotation. Lorsque le service est prêt vous, en un click il devient disponible à tous les utilisateurs de la plateforme.',
        title2: 'Multi-plateformes',
        text2: 'Utilisateurs et Fournisseurs de Services peuvent avoir accès à nos services sur PCs, MACs, Tablettes ou téléphonnes mobiles. Notre solution intègre par défaut les différents types de navigateur.',
        title3: 'GRATUIT',
        text3: 'Nous vous offrons ce service GRATUITEMENT pour les utilisateurs finaux et les fournisseurs de service. SIMPLE et TRANSPARENT.',
        title4: 'Devis ou Prix fixe',
        text4: 'Lisez les réponses aux questions fournies par les utilisateurs pour faire votre devis. Sinon definissez vos prix standards. Vous pouvez également définir votre zone de recherche pour répondre aux demandes des utilisateurs qui recherche du services.',
        title5: 'Catégories & Sous-Catégories',
        text5: 'La plateforme permet de classer vos services par catégories et/ou sous catégories. Si aucune catégorie ou sous-catégorie prédéfinies ne vous convient, vous avez la possibilité d’en créer à la volée.',
        title6: '24/7 disponible',
        text6: 'Simple d’utilisation, notre plateforme est disponible en 24/7.',
        title7: 'Management des ressources',
        text7: 'Definissez la liste de vos employés et associés les avec des services particuliers.',
        title8: 'Réservation en ligne',
        text8: 'Tout client peut reserver votre activité en ligne avec sa ressource associée. Vous pouvez vous concentrer sur votre activité.',
    },
    weHelpYourSuccess: {
        mainTitle: 'Fournisseurs de services',
        line1: 'Nous construisons des outils digitaux pour faciliter votre travail et vous accompagner dans votre transformation. Nous souhaitons construire une offre 360 degrés.',
        title1: 'Psychologues',
        text1: 'Vous cherchez une solutions simples pour obtenir de nouveaux patients.',
        title2: 'Déménageurs',
        text2: 'Enfin une plateforme pour vous aider à passer dans le monde digital. Notre solution simple d’utilisation est là pour vous aider.',
        title3: 'Artisans',
        text3: 'Vous avez besoin d’enchainer et d’organiser votre travail, de limiter le temps perdu entre vos chantiers. Nous avons la solution.',
        title4: 'Coiffeurs',
        text4: 'Vendre vos services à prix fixe complété avec un système de gestion de rendez-vous pour vous et vos employés.',
    },
    identity: {
        firstname: 'Prénom',
        lastname: 'Nom',
        address1: 'Adresse ligne 1',
        address2: 'Adresse ligne 2',
        postcode: 'Code postal',
        city: 'Ville',
        country: 'Pays',
        phone: 'Téléphone',
        mail: 'E-mail',
        companyname: 'Nom de la société',
        siret: 'Numéro de Siret',
        consent: 'Consentement',
        rcs: 'Registre du Commerce et des Sociétés',
        legalStatus: 'Statut juridique',
        cpyFRIndividual: 'Entreprise individuelle',
        cpyFRAutoEntrepreneur: 'Auto-entrepreneur',
        cpyFRSARL: 'SARL (Société à responsabilité limitée)',
        cpyFRSAS: 'SAS (Société par action simplifiée)',
        cpyFRSA: 'SA (Société anonyme)',
        cpyFRSNC: 'SNC (Société en nom collectif)',
    },
    AccountEU: {
        title: 'Paramètres de mon compte',
        mainTitle: 'Paramètres de mon compte',
        consent1: 'En vous inscrivant, vous reconnaissez avoir lu et accepté les <a href=',
        consent2: ' target="_blank"> Conditions d’utilisation</a> et les <a href=',
        consent3: ' target="_blank"> conditions légales </a> ainsi que la <a href=',
        consent4: ' target="_blank"> Politique de confidentialité des données</a>.',
        cookie1: 'Kidiwi Services utilise des cookies pour vous donner la meilleure expérience de navigation. Voir la <a href=',
        cookie2: ' target="_blank">charte des données personnelles</a>.',
        alertEUNoFound: 'Aucun utilisateur avec ce nom n’a été trouvé.',
        alertEULoadDataIssue: 'Nous ne sommes pas arrivé à télécharger vos données.',
        alertEUFailedUpdate: 'Nous ne sommes pas arrivé à mettre à jour les données de l’utilisateur.',
        alertEUSuccessUpdate: 'Mise à jour effectuée avec succés.',
    },
    AccountSP: {
        title: 'Paramètres de mon compte',
        mainTitle: 'Paramètres de mon compte',
        tabCpyAccount: 'Ma société, détails',
        tabAccount: 'Le responsable, détails',
        tabInvoice: 'Factures',
        siretVerified: 'Je confirme que ma société est référencée et que toutes les informations fournies sont correctes',
        pickLogo: 'Choisir un logo',
        sizeLogo: 'La taille du fichier doit etre inférieure à 2M!',
        alertSPNoFound: 'Aucun utilisateur avec ce nom n’a été trouvé.',
        alertSPLoadDataIssue: 'Nous ne sommes pas arrivé à télécharger vos données.',
        alertSPFailedUpdate: 'Nous ne sommes pas arrivé à mettre à jour les données de l’utilisateur.',
        alertSPSuccessUpdate: 'Mise à jour effectuée avec succés.',
        mailFormat: 'Entrez un mail valide!',
        dataCopied: 'Données copiées',
    },
    CalendarEU: {
        title: 'Mes Rendez-vous',
    },
    CalendarSP: {
        title: 'Mon Agenda',
    },
    RequestEU: {
        title: 'Gérer mes requêtes',
        mainTitle: 'Gérer mes requêtes',
        dialogQuestion: 'Liste des questions et des réponses',
        proposalSPYes: 'OK pour nouvelle proposition',
        proposalSPNo: 'Aucune nouvelle proposition',
        serviceProvider: 'Fournisseur',
        state: 'Statut',
        price: 'Prix (TTC)',
        quotation: 'Devis',
        note: 'Note',
        legal: 'Légal',
        action: 'Actions',
        appoint: 'RDV',
        addAppoint: 'Ajouter RDV',
        delAppoint: 'Supprimer',
        serviceName: 'Nom service',
        requester: 'Demandeur',
        dateRequest: 'Date requête',
        fixedPrice: 'Prix fixe',
        quotationBased: 'Base de devis',
        expirationDate: 'Date expiration',
        answerState: 'Etat réponse',
        cancelRequest: 'Est-ce que vous souhaitez annuler cette demande de quotation ?',
        refuseQuote: 'Est-ce que vous souhaitez refuser cette quotation ?',
        validateQuote: 'Est-ce que vous souhaitez valider cette quotation ?',
        ascendingDate: 'Tri croissant par date de création',
        descendingDate: 'Tri décroissant par date de création',
        category: 'Trier par catégorie',
        search: 'Recherche services',
        delete: 'Supprimer',
        validprop: 'Valider proposition',
        infoprov: 'Détail fournisseur',
        resource: 'Ressource',
        resourceName: 'Nom de la ressource',
    },
    marketSP: {
        title: 'Recherche d’opportunités',
        mainTitle: 'Recherche d’opportunités',
        line1: 'Nous vous aidons à trouver des opportunités. Vous pouvez ajuster le nombre de kilomètres de recherche autour de l’adresse de votre société.',
        categoryList: 'Les catégories de recherche correspondant à vos services',
        result: 'Résultats',
        proposeQuotation: 'Faire une offre pour cette demande',
        expirationDate: 'Date d’expiration',
        pickService: 'Choisir le service',
        quotationFile: 'Fichier devis',
        pickQuotation: 'Choisir le devis',
        noteCustomer: 'Vos commentaires pour le client',
        noteInternal: 'Vos commentaires internes (non partagés)',
        legalCustomer: 'Vos notes légale pour le client',
        alertSPPostcodeNotDefined: 'Vous devez définir le code postal et la ville de votre société (Mon Compte)!',
        alertNoResult: 'Aucun résultat trouvé pour la catégorie de service que vous proposez ou choisit!',
        alertNoQuotation: 'Vous devez sélectionner un devis avant de valider votre proposition!',
        alertNoService: 'Vous devez avoir définis un service et sélectionner un service avant de valider votre proposition!',
        fixedPrice: 'Prix fixe',
        quotationBased: 'Sur devis',
        delete: 'Supprimer',
        questions: 'Questions',
        quotationOnsite: 'Devis sur place',
        editQuotation: 'Votre devis',
        downloadQuotation: 'Télécharger le devis',
        contactEU: 'Contacter l’utilisateur',
        Accept: 'Accepter',
    },
    customerSP: {
        title: 'Gérer mes demandes',
        mainTitle: 'Gérer mes demandes',
        serviceRequest: 'Liste des demandes',
        cancelOffer: 'Est ce que vous êtes sûr de vouloir annuler votre devis et d’annuler votre offre ?',
        validateRequest: 'Est ce que vous êtes sûr de vouloir valider la requête de l’utilisateur?',
    },
    serviceSP: {
        title: 'Gérer mes services',
        mainTitle: 'Gérer mes services',
        subTitle: 'Ma liste de service',
        categories: 'Catégories',
        subcategories: 'Sous catégories',
        newCategory: 'Nouvelle catégorie?',
        newSubCategory: 'Nouvelle sous-catégorie?',
        serviceDescription: 'Description du service',
        serviceName: 'Nom du service',
        internalNote: 'Note interne (pas visible au client)',
        legalNote: 'Note légale',
        fixedPriceService: 'Service à prix fixe?',
        defaultLegal: 'Valeur légale par défaut',
        serviceOpen: 'Service ouvert à la clientèle?',
        serviceAutoConfirmed: 'Service confirmé automatiquement?',
        serviceDuration: 'Durée du service',
        DurationDD: 'Durée (JJ)',
        DurationDD_hint: 'Jour(s)',
        DurationHH: 'Durée (HH)',
        DurationHH_hint: 'Heure(s)',
        DurationMM: 'Durée (MM)',
        DurationMM_hint: 'Minute(s)',
        beforeDuration: 'Buffer avant',
        beforeDuration_hint: 'Minute(s)',
        afterDuration: 'Buffer après',
        afterDuration_hint: 'Minute(s)',
        note: 'Note: Ce service prend en compte le code postal et la ville enregistré au nom de la société',
        deleteQuestion: 'Est-ce que vous êtes sûr de vouloir détruire ce service ?',
        ajoutServiceNote: 'Veuillez ajouter un service en appuyant sur le bouton : Nouveau service.',
        alertFailFetchCategory: 'Nous n’avons pas réussi à télécharger les catégories.',
        alertFailFetchSubCategory: 'Nous n’avons pas réussi à télécharger les sous-catégories.',
        alertFailDeleteService: 'Nous n’avons pas réussi à supprimer le service.',
        alertFailLoadService: 'Nous n’avons pas réussi à charger vos services.',
        alertFailLoadSubCategories: 'Nous n’avons pas réussi à télécharger les sous-catégories.',
        alertFailUpdateService: 'Nous n’avons pas réussi à mettre à jour votre service.',
        alertSuccessUpdateService: 'Nous avons mis à jour votre service.',
        alertFailUpdateCategory: 'Nous n’avons pas réussi à mettre à jour la catégorie.',
        alertSuccessUpdateCategory: 'Nous avons mis à jour votre catégorie.',
        alertFailUpdateSubCategory: 'Nous n’avons pas réussi à mettre à jour la catégorie.',
        alertSuccessUpdateSubCategory: 'Nous avons mis à jour votre sous-catégorie.',
        alertSuccessServiceCreated: 'Service créé',
        alertFailServiceCreated: 'La création du service a échouée',
        alertSuccessCategoryCreated: 'Catégorie créée',
        alertFailCategoryCreated: 'La création de la catégorie a échouée',
        alertSuccessSubCategoryCreated: 'Sous catégorie créée',
        alertFailSubCategoryCreated: 'La création de la sous-catégorie a échouée',
        activated: 'Activé',
        deactivated: 'Désactivé',
        onlineBooking: 'RDV en ligne',
        serviceUpdate: 'Mise à jour du service',
        serviceCreation: 'Création service',
        serviceModification: 'Modification service',
        alertCategorySelected: 'La catégorie doit etre selectionnée ou une catégorie doit être définie',
        alertServiceNameMandatory: 'Le nom du service est obligatoire',
        alertPriceNumberOnly: 'Le prix du service doit contenir uniquement des chiffres',
        alertPriceMustDefine: 'Le prix du service doit etre définit si vous choississez cette option',
        alertDayNumber: 'Le nombre de jour doit contenir uniquement des chiffres',
        alertHourNumber: 'Le nombre d’heure doit contenir uniquement des chiffres',
        alertMinutesNumber: 'Le nombre de minutes doit contenir uniquement des chiffres',
        alertDurationBeforeNumber: 'La durée avant le service doit contenir uniquement des chiffres',
        alertDurationAfterNumber: 'La durée après le service doit contenir uniquement des chiffres',
        bookingOnline: 'Réservation en ligne',
        alertCanNotDeleteService: 'Le service ne peut pas être détruit car il a déjà été utilisé par des utilisateurs finaux. Seule une désactivation du service est possible.',
        alertCanNotModifyServicePriceType: 'Le type de prix du service ne peut pas être modifié car il a déjà été utilisé par des utilisateurs finaux. Veuillez en créer un nouveau.',
        noServices: 'Pas de services définit encore. Vous pouvez en ajouter!',
    },
    paramEU: {
        title: 'Gérer les paramètres de votre compte',
        mainTitle: 'Gérer les paramètres de votre compte',
        language: 'Langue',
        language_line1: 'Définir vos préférences de communication. Choisir la langue utilisée lors de l’envoi des emails.',
        english: 'Anglais',
        french: 'Français',
    },
    paramSP: {
        title: 'Gérer les paramètres de votre compte',
        mainTitle: 'Gérer les paramètres de votre compte',
        searchRadiusOption: 'Périmètre recherche',
        searchRadiusOption_line1: 'Périmètre de recherche par défault sur "Le Marché" définit en kilomètres.',
        searchRadiusOption_line2: 'Le point de référence est le code postal et le nom de la ville de votre société (Mon compte).',
        currentSubscriptionOption: 'Souscription active',
        stopService: 'Arrêt service',
        kidiwiBasic: 'Kidiwi Basic',
        alertUpdateOK: 'Vos paramètres ont été mis à jours.',
        walletOption: 'Controlleur-Coûts',
        walletOption_line1: 'Cette section permet de définir la valeur maximale par défaut des coût à l’utilisation le mois prochain. Cette valeur ne pourra pas être diminuée pendant le mois en cours.',
        minWalletValue: '€ (Coût mini usage)',
        legalOption: 'Légal',
        legalOption_line1: 'Définissez les mentions légales utilisées par défaut lors de la création d’un service',
        language: 'Langue',
        language_line1: 'Cette option vous permet de définir vos préférences en termes de communication. Ce choix va influencer le language choisit pour l’envoi des mails par exemple.',
        english: 'Anglais',
        french: 'Français',
        planBased: 'Votre plan souscrit:',
        textYearMonthBased: 'Choississez votre type de souscription :',
        monthBased: 'Mensuel',
        yearBased: 'Annual',
        subscriptionDate: 'Date de souscription au plan :',
        canNotChangeSubscriptionTooEarly: 'Vous avez opté pour un paiment annuel, vous ne pouvez pas encore changer de souscription !',
    },
    costSP: {
        title: 'Gestion des coûts',
        mainTitle: 'Gestion des coûts',
        pastMonthlyInvoiceOption: 'Factures des mois précédents',
        pastMonthlyInvoiceOption_title: 'Factures par mois',
        pastMonthlyInvoiceOption_date: 'Date',
        MonthlyConsumptionOption: 'Consommation du mois',
        amountWallet: 'Montant maximum de service à l’usage :',
        costNature: 'Nature des coûts',
        hit: 'Nombre d’unité',
        cost: 'Coût HT(€)',
        total: 'Consommation actuelle à l’usage sur le mois :',
        freeTrial: 'Période de Gratuitée (',
        freeTrial_Ending: ' mois) :',
        freeTrial_OnGoing: 'Active',
        freeTrial_Ended: 'Finie',
        totalBill: 'Consommation actuelle à facturer sur le mois :',
        countMonthRelation: 'Nombre de mise en relation pendant le mois',
        countMonthLogoUsed: 'Utilisation du logo pendant le mois',
        countMonthMaxActiveService: 'Nombre maximum de service utilisé pendant le mois',
        monthlyFee: 'Abonnement mensuel :',
        yearlyFee: 'Abonnement annuel ce mois :',
        yes: 'OUI',
        no: 'NON',
        walletIncreaseBtn: 'Porte monnaie + 1€',
        walletOver: 'Dépassement',
    },
    pricingSection: {
        titleLine1: 'Choississez votre plan',
        titleLine2: 'Voici les plans que vous nous proposons pour commencer :',
        monthly: 'Mensuel',
        yearly: 'Annuel',
        basic_Title_monthly: 'Basic Mensuel',
        basic_Title_yearly: 'Basic Annuel',
        per: 'PAR',
        month: 'MOIS',
        year: 'ANNEE',
        basic_SubTitle_monthly: 'Le meilleur plan pour le business par opportunité',
        basic_SubTitle_yearly: 'Le meilleur plan pour votre business récurrent',
        payPerUse: 'Coût à l’utilisation',
        payPerUse_Basic_Relation: 'Coût de mise en relation',
        payPerUse_Basic_Logo: 'Coût d’affichage du logo',
        payPerUse_Basic_Service: 'Coût d’un service (>1)',
        payPerUse_Basic_Spending: 'Contrôleur de dépenses',
        payPerUse_Basic_Logo_description: 'Si le logo est activé une seule fois dans le mois, le coût mensuel est applicable.',
        payPerUse_Basic_Relation_description: 'Compte 1 chaque fois que la plateforme KidiwiServices met en relation un Membre Utilisateur et un Membre Prestataire et inversement.',
        payPerUse_Basic_Service_description: 'Montant multiplié par le nombre maximum de services activés dans le mois moins 1 service offert.',
        payPerUse_Basic_Spending_description: 'Méchanisme qui limite les charges mensuelles au Membre Prestataire. Il définit un maximum entre 5 euros et la valeur définie par le Membre Prestataire. Le Membre Prestataire peut augmenter le montant maximum pendant le mois. Cette valeur sera remise à la valeur définie dans les paramêtres au début de chaque mois. Cette valeur pourra etre ajustée par le Membre Prestataire dans les paramêtres le mois précédent pour s’appliquer le mois suivant.',
        price: 'Prix (€ HT)',
        description: 'Description',
    },
    generalSearchResults: {
        filter: 'Filtre',
        filterNameUp: 'Nom↑',
        filterNameDown: 'Nom↓',
        filterPriceUp: 'Prix↑',
        filterPriceDown: 'Prix↓',
        filterCategoryUp: 'Catégorie↑',
        filterCategoryDown: 'Catégorie↓',
        filterSubCategoryUp: 'Sous Catégorie↑',
        filterSubCategoryDown: 'Sous Catégorie↓',
        filterServiceRequestNameUp: 'Nom service↑',
        filterServiceRequestNameDown: 'Nom servicej↓',
        filterServiceRequestRequestDateUp: 'Date de réception↑',
        filterServiceRequestRequestDateDown: 'Date de réception↓',
        filterServiceRequestExpiryDateUp: 'Date Expiration↑',
        filterServiceRequestExpiryDateDown: 'Date Expiration↓',
        filterServiceRequestAppointDateUp: 'Date RDV↑',
        filterServiceRequestAppointDateDown: 'Date RDV↓',
        rowsByPage: 'Lignes par page:',
        all: 'Toutes',
    },
    requestMng: {
        noServiceRequest: 'Il n’y a pas de demande pour vos services pour le moment!',
    },
    marketSearchResults: {
        noNewOpportunity: 'Nous n’avons pas trouvé de nouvelle opportunité pour l’instant!',
        someOpportunity: 'DES OPPORTUNITES POUR VOUS',
    },
    categoryLib: {
        alertFailFetchCat: 'Nous n’avons pas réussi à télécharger la liste des catégories.',
        alertFailCreateCat: 'Nous n’avons pas réussi à créer cette catégorie.',
    },
    consentLib: {
        alertNoSPFound: 'Nous n’avons pas trouvé les informations correspondantes.',
        alertSetLastName: 'Merci d’indiquer votre nom dans Mon Compte.',
        alertSetFirstName: 'Merci d’indiquer votre prénom dans Mon Compte.',
        alertSetCompanyName: 'Merci d’indiquer le nom de votre société dans Mon Compte.',
        alertSetSiretValue: 'Merci de définir votre numéro de Siret dans Mon compte.',
        alertSetCompanyAddressLine1: 'Merci de définir la première ligne de votre adresse dans Mon compte.',
        alertSetCompanyPostCode: 'Merci de définir votre code postal dans Mon compte.',
        alertSetCompanyEmail: 'Merci de définir votre mail dans Mon compte.',
        alertSetCompanyConsent: 'Merci de valider votre consentement dans Mon compte.',
        alertSetCity: 'Merci de définir le nom de la ville dans Mon Compte.',
        alertSetCountry: 'Merci de définir le nom du Pays dans Mon compte.',
        alertSetValidateSiret: 'Merci de confirmer que votre numéro de siret est valide en cochant la case associé dans Mon compte.',
        alertBanned: 'veuiller contacter notre service client car vous avez été bloqué par nos soins (paiement non effectué, non respect de nos chartes, etc.)',
    },
    costLib: {
        alertNoCostLoaded: 'Nous n’avons pas réussi à télécharger l’historique de vos factures.',
    },
    distanceLib: {
        alertIssueFetchingRequest: 'Nous avons eu un problème pour récupérer l’ensemble des requêtes dans le périmètre indiqué.',
    },
    parameterLib: {
        alertFailLoad: 'Nous n’avons pas réussi à télécharger vos paramètres.',
        alertFailSave: 'Nous n’avons pas réussi à sauvegarder vos paramètres.',
    },
    postalCodeLib: {
        alertFailLoad: 'Nous n’avons pas réussi à télécharger les codes postaux.',
        alertFailGPSCoordinate: 'Nous n’avons pas réussi à trouver les coordonnées GPS pour un code postal.',
        alertFailGetTownFromPostalCode: 'Nous n’avons pas réussi à trouver la ville pour un code postal particulier.',
    },
    requestLib: {
        alertQuotationIdentical: 'le fichier de quotation est le même, nous ne le mettons pas à jour.',
        toQuote: 'A quoter',
    },
    serviceLib: {
        alertFailLoad: 'Nous n’avons pas réussi à télécharger les services.',
    },
    formatterLib: {
        quotationBased: 'Sur Devis',
    },
    stateMachineLib: {
        answer_PENDING_QUOTATION: 'Attente Devis',
        answer_ONSITE_QUOTATION: 'Devis sur site',
        answer_QUOTED: 'Devis émis',
        answer_CONTRACT_VALIDATED: 'Contrat validé',
        answer_QUOTE_EXPIRED: 'Devis expiré',
        answer_QUOTE_REFUSED_BY_EU: 'Devis refusé par utilisateur',
        answer_QUOTE_CANCELLED_BY_SP: 'Devis annulé par fournisseur',
        answer_REFUSE_TO_QUOTE_BY_SP: 'Fournisseur refuse faire devis',
        answer_CONTRACT_CANCELLED_BY_EU: 'Contrat annulé par Utilisateur',
        answer_CONTRACT_CANCELLED_BY_SP: 'Contrat annulé par fournisseur',
        answer_REQUEST_CANCELLED_BY_EU: 'Demande annulée par utilisateur',
        answer_REQUEST_EXPIRED: 'Demande expirée',
        request_STARTED: 'Demande commencée',
        request_VALIDATED: 'Demande validée',
        request_CANCELLED_BY_EU: 'Demande annulée par utilisateur',
        request_EXPIRED: 'Requête expirée',
    },
    cat: {
        elevator: 'Ascenseur',
        airConditioning: 'Climatisation',
        doorWindow: 'Fenetre - Porte',
        garden: 'Jardin - Extérieur',
        painting: 'Peinture',
        craft: 'Bricolage',
        housebuilding: 'Contruction - Renovation - Maison',
        roof: 'Couverture - Toiture',
        housediagnotic: 'Diagnostic immobilier',
        demolition: 'Démolition - Evacuation',
        isolation: 'Isolation',
        cabinetry: 'Ebenesterie',
        plumbing: 'Plomberie',
        locksmith: 'Serrurier',
        floortiling: 'Sol - Carrelage',
        plaster: 'Platre',
        heating: 'Chauffage',
        pestcontrol: 'Traitement nuisible',
        security: 'Securité',
        cleaning: 'Nettoyage',
        kitchen: 'Cuisine',
        moving: 'Déménagement',
        electricity: 'Electricité',
        event: 'Evenement',
        music: 'Musique',
        medical: 'Medical',
        beauty: 'Beaute & Bien-etre',
        // immo: 'Immobilier',
    },
    subCat: {
        stair: 'Escalier',
        blinder: 'Volet',
        gardenHouse: 'Abri de jardin',
        setDressing: 'Aménagement de placards ou d’un dressing',
        accessHandicap: 'Aménagements pour pers. à mobilité réduite',
        architectBuilding: 'Architecte (projet de rénovation, construction)',
        achitectGardener: 'Architecte paysagiste',
        automaticWaterDispenser: 'Arrosage automatique',
        centralisedAirCollect: 'Aspiration centralisée',
        helpSmallCraft: 'Assistance installation - réparation (petit travaux)',
        computerCable: 'Cablage informatique',
        tile: 'Carrelage',
        coldChamber: 'Chambre froide',
        roofCarpenter: 'Charpente bois et métallique',
        heaterCentralMaintenance: 'Chaudière (entretien et réparation)',
        hearterInstall: 'Chauffage (installation, remplacement)',
        heaterOutside: 'Chauffage extérieur ou de terrasse',
        hearderSwimmingPool: 'Chauffage piscine',
        gazContainer: 'Citerne Gaz',
        airCond: 'Climatisation réversible',
        garageBuilding: 'Construction garage',
        buildBuilding: 'Construction immeuble',
        buildHouse: 'Construction maison',
        buildMezzanine: 'Création d’une mezzanine',
        buildGarden: 'Création de jardin et d’espaces verts',
        buildOutsideTile: 'Dallage',
        diagnosisWater: 'Diagnostic installation assainissement',
        diagnosisElectric: 'Diagnostic installation électrique',
        diagnosticIsolation: 'Diagnostic performance énergétique (DPE)',
        diagnosisLead: 'Diagnostic plomb ou diagnostic installation gaz',
        destruction: 'Démolition (bâtiment, murs...)',
        killRoofMousse: 'Démoussage',
        killPigeon: 'Dépigeonage',
        killRats: 'Dératisation',
        removeAsbestos: 'Désamiantage ou diagnostic amiante',
        killInsects: 'Désinsectisation',
        ligthOutside: 'Eclairage extérieur',
        ligthInside: 'Eclairage intérieur',
        gardenMaintenance: 'Entretien de jardin et d’espaces verts',
        roofLeak: 'Etanchéité toiture terrasse',
        frontage: 'Façade',
        windowsInstall: 'Fenêtre - Porte fenêtre: Installation et remplacement',
        windowRoof: 'Fenêtre de toit',
        houseBase: 'Fondation',
        fountain: 'Fontaine d’eau',
        stairRailing: 'Garde corps',
        mainHousework: 'Gros oeuvre',
        hearterInstallReplace: 'Installation - Remplacement radiateur',
        bathroomInstall: 'Installation d’une salle de bain complète',
        roofInstall: 'Installation ou rénovation couverture/toiture',
        electricInstall: 'Installation électrique',
        elevatorInstall: 'Installer ou remplacer un ascenseur',
        loadElevator: 'Installer ou remplacer un monte-charges',
        intercom: 'Interphone / Vidéophone',
        roofIsolation: 'Isolation des combles',
        isolationOutside: 'Isolation par l’extérieur',
        isolationSound: 'Isolation phonique et accoustique',
        isolationThermal: 'Isolation thermique',
        cleaningWindow: 'Lavage de vitres',
        cleaningOffice: 'Locaux',
        buildingProjectManager: 'Maître d’oeuvre',
        carpet: 'Moquette',
        removeCleanIndustrial: 'Nettoyage industriel - Evacuation de gravats',
        solarPanel: 'Panneaux solaires / Photovoltaiques',
        paintInside: 'Peinture Intérieure',
        paintOutside: 'Peinture extérieur',
        smallElectricRepair: 'Petit travaux électrique',
        smallBuildingRepair: 'Petits travaux de maçonnerie',
        smallPlumberingRepair: 'Petits travaux de plomberie',
        SwimingPool: 'Piscine',
        ceilingHeater: 'Plafond rayonnant',
        groundHeater: 'Plancher chauffant',
        plumbering: 'Plomberie (neuf et rénovation)',
        heatPump: 'Pompe à chaleur',
        entranceDoor: 'Porte d’entrée / Blindage porte',
        insideDoor: 'Porte d’intérieur',
        garageDoor: 'Porte de garage',
        renewWoodFlooring: 'Pose nouveau parquet',
        well: 'Puit',
        replaceWindow: 'Remplacement vitre',
        colletRainWater: 'Récupérateur d’eau de pluie',
        collectHeat: 'Récupérateur de chaleur',
        repairWoodingFloor: 'Rénovation d’un parquet (ponçage, vitrification)',
        openWall: 'Sciage et carottage (ouverture dans un mur)',
        buildConcreteGround: 'Sol béton',
        buildPlasticGround: 'Sol plastique',
        houseElevation: 'Surélévation',
        earthwork: 'Terrassement',
        roofPlant: 'Toiture végétalisée',
        analysisHouse: 'Traitement ou diagnostic immobilier',
        difficultAccessJob: 'Travaux acrobatiques ou d’accès difficile',
        houseAirControl: 'VMC (ventilation mécanique contrôlée)',
        windowShop: 'Vitrine de magasin',
        automaticBlinder: 'Volet roulant',
        birthday: 'Anniversaire',
        mariage: 'Mariage',
        wineTesting: 'Oenologie',
        guitar: 'Cours de guitare',
        piano: 'Cours de piano',
        violon: 'Cours de violon',
        drums: 'Cours de batterie',
        bass: 'Cours de contrebasse',
        haircutter: 'Coiffeur',
        pedicure: 'Pedicure',
        manucure: 'Manucure',
        massage: 'Massage',
        hairremoval: 'Epilation',
        anatomo: 'Anatomo-Cyto-Pathologiste',
        anesthesiologist: 'Anesthésiste réanimateur',
        radiation: 'Cancerologue radiotherapeute',
        medicalOncologist: 'Cancerologue medical',
        cardiologist: 'Cardiologue',
        generalSurgeon: 'Chirurgien general',
        infantSurgeon: 'Chirurgien infantile',
        maxillofacialSurgeon: 'Chirurgien maxillo-facial',
        maxillofacialSurgeonStomatologist: 'Chirurgien maxillo-facial et stomatologiste',
        oralSurgeon: 'Chirurgien oral',
        orthopedicSurgeon: 'Chirurgien orthopediste',
        orthopedicSurgeonTraumatologist: 'Chirurgien orthopediste et traumatologue',
        plasticSurgeon: 'Chirurgien plasticien',
        thoracicCardiovascularSurgeon: 'Chirurgien thoracique et cardio-vasculaire',
        urologicalSurgeon: 'Chirurgien urologue',
        vascularSurgeon: 'Chirurgien vasculaire',
        visceralSurgeon: 'Chirurgien viscéral',
        dentist: 'Dentiste',
        dentalSurgeonSpecializingInDentofacialOrthopedics: 'Chirurgien dentiste spécialiste en orthopédie dento-faciale',
        dentalSurgeonsSpecializingInOralSurgery: 'Chirurgiens-dentistes specialiste en chirurgie orale',
        dentalSurgeonsSpecializingInOralMedecine: 'Chirurgiens-dentistes specialiste en medecine bucco-dentaire',
        dermatolog: 'Dermatologue et vénérologue',
        endocrinologistDiabetologist: 'Endocrinologue-diabetologue',
        gastroenterologistHepatologist: 'Gastro-enterologue et hepatologue',
        geriatrician: 'Geriatre',
        gynecologist: 'Gynecologue',
        gynecologistObstetrician: 'Gynecologue et obstetricien',
        hematologist: 'Hematologue',
        biologist: 'Biologiste',
        doctor: 'Docteur - Medecin generaliste',
        geneticist: 'Geneticien',
        nuclearMedicineSpecialist: 'Medecin specialiste en medecine nucleaire',
        medicalSpecialistInPublicHealthAndSocialMedicine: 'Medecin specialiste en sante publique et medecine sociale',
        nephrologist: 'Néphrologue',
        neurosurgeon: 'Neurochirurgien',
        neurologist: 'Neurologue',
        neuropsychiatrist: 'Neuropsychiatre',
        obstetrician: 'Obstetricien',
        ophthalmologist: 'Ophthalmologiste',
        otorhinolaryngologist: 'Oto-Rhino-Laryngologue (ORL)',
        OtorhinolaryngologistCervicoFacialSurgery: 'Oto-Rhino-Laryngologue (ORL) et chirurgien cervico-facial',
        pediatrics: 'Pediatre',
        pulmonologist: 'Pneumologue',
        psychiatrist: 'Psychiatre',
        psychiatristChild: 'Psychiatre de l’enfant et de l’adolescent',
        radiologist: 'Radiologue',
        radiotherapist: 'Radiothérapeute',
        medicalResuscitator: 'Reanimateur',
        rheumatologist: 'Rhumatologue',
        midwife: 'Sage femme',
        internalMedicineSpecialist: 'Specialiste en medecine interne',
        specialistInPhysicalMedicineAndRehabilitation: 'Specialiste en medecine physique et de readaptation',
        Stomatologue: 'Stomatologist',
        psychologist: 'Psychologue',
        psychoanalyst: 'Psychanalyste',
        nutrionist: 'Nutrionniste',
    },
    k: {
        elev: {
            load: 'Monte charge',
            floor: 'Etage',
            auto: 'Porte Automatique',
            },
        craft: {
            hammer: 'Marteau',
            screw: 'Vis',
            screwdrive: 'Tourne-vis',
            pin: 'Clou',
            repair: 'Réparer',
            fix: 'Fixer',
            },
        heat: {
            heater: 'Radiateur',
            climatisation: 'Climatisation',
            climMachine: 'Climatiseur',
            inertial: 'Inertie',
            fluid: 'Fluide',
            solar: 'Solaire',
            },
        aircon: {
            rev: 'Reversible',
            split: 'split',
            },
        build: {
            blockP: 'Parpaing',
            iron: 'Ferraillage',
            blockA: 'Agglo',
            concrete: 'Béton',
            ciment: 'Ciment',
            tile: 'Tuile',
            gutter: 'Gouttière',
            cladding: 'Bardage',
            scaffolding: 'Echafaudage',
            fence: 'Cloture',
            },
        roof: {
            tile: 'Tuile',
            photovoltaic: 'Photovoltaique',
            frame: 'Charpente',
            },
        kit: {
            designer: 'Cuisiniste',
            furniture: 'Meuble en kit',
            fridge: 'Frigidere',
            plate: 'Plaque',
            ceramic: 'Vitroceramique',
            oven: 'Four',
            microwave: 'Micro onde',
            equip: 'Cuisine equipé',
            tailored: 'Cuisine sur mesure',
            hood: 'Hotte aspirante',
            induction: 'Cuisiniere induction',
            piano: 'Cuisiniere piano',
            },
        diag: {
            estate: 'Immobilier',
            asbestos: 'Amiante',
            switchboard: 'Tableau électrique',
            differential: 'Différentiel',
            termite: 'Termite',
            thermite: 'Thermique',
            risk: 'Risque naturel',
            dpe: 'DPE Diagnostic de performance énergétique',
            },
        break: {
            rubble: 'Gravas',
            hammer: 'Massue',
            truck: 'Camion',
            rubblebag: 'Sac a gravats',
            disposal: 'Décharge',
            jackhammer: 'Marteau piqueur',
            },
        cabinetry: {
            woordwork: 'Boiserie',
            window: 'Fenetre',
            furniture: 'Meuble',
            kitchen: 'Cuisine',
            door: 'Porte',
            firedoor: 'Coupe feu',
            hatch: 'Trappe',
            },
        elec: {
            switchboard: 'Tableau électrique',
            differential: 'Différentiel',
            lamp: 'Ampoule',
            led: 'Led',
            lead: 'Plombs',
            socket: 'Prise',
            switch: 'Interrupteur',
            groundPlug: 'Prise de terre',
            spot: 'Spot',
            electrician: 'Electricien',
            },
        window: {
            carpentry: 'Menuiserie',
            uisserie: 'Uisserie',
            servicedoor: 'Porte de service',
            pvc: 'PVC',
            alu: 'Aluminium',
            },
        isolation: {
            wool: 'Laine de verre',
            wadding: 'Ouate',
            insulating: 'Isolant',
            },
        clean: {
            cleaning: 'Ménage',
            cleansing: 'Nettoyage',
            maintenance: 'Entretien',
            desinfection: 'Désynfection',
            },
        paint: {
            after: 'Apres',
            layer: 'Sous couche',
            pincel: 'Pinceau',
            roll: 'Rouleaux',
            stain: 'Lasure',
            varnish: 'Vernis',
            water: 'Peinture à l’eau',
            painter: 'Peintre',
            },
        plaster: {
            tile: 'Carreau de platre',
            placo: 'Placo',
            layer: 'Enduit',
            plasterer: 'platrier',
            ba13: 'BA13',
            },
        plumb: {
            connection: 'Tuyau raccordement',
            tap: 'Robinetterie',
            toilet: 'WC',
            unblocking: 'Débouchage',
            bathroom: 'Sanitaire',
            cleaning: 'Curage',
            leak: 'Fuite eau',
            shower: 'Douche',
            washbasin: 'Vasque',
            bidet: 'Bidet',
            enclosure: 'Paroi de douche',
            bathtab: 'Baignoire',
            mirror: 'Miroir',
            furniture: 'Meuble SDB',
            toiletEl: 'WC suspendu',
            accessory: 'Accessoires sanitaires',
            plumber: 'Plombier',
            },
        lock: {
            key: 'Cle',
            key1: 'Clef',
            lock: 'Serrure',
            door: 'Porte',
            },
        ground: {
            floorTile: 'Carrelage',
            tiler: 'Carreleur',
            tiles: 'Carreau',
            sandstone: 'Gres',
            travertin: 'Travertin',
            woodfloor: 'Parquet',
            slate: 'Ardoise',
            wood: 'Bois',
            oak: 'Chene',
            laminate: 'Stratifié',
            gerflex: 'Gerflex',
            carpet: 'Moquette',
            marble: 'Marbre',
            coco: 'Coco',
            waxedconcrete: 'Béton Ciré',
            },
        secu: {
            alarm: 'Alarme',
            system: 'Système',
            videoSurveillance: 'Video surveillance',
            surveillance: 'Surveillance',
            gardeCorps: 'Garde du corps',
            bodyGuard: 'Sécurité rapprochée',
            interphone: 'Interphone',
            visiophone: 'Visiophone',
            camera: 'Camera',
            gaz: 'Gaz défense',
            defense: 'Défense',
            safe: 'Coffre fort',
            },
        pest: {
            rat: 'Rats',
            mouse: 'Souris',
            ant: 'Fourmis',
            cockroach: 'Blattes',
            cockroaches: 'Cafards',
            phytosanitary: 'Phyto',
            ecologic: 'Ecologique',
            cleaning: 'Entretien',
            desinfection: 'Désynfection',
            disinfestation: 'Dératisation',
            fumigation: 'Fumigation',
        },
        event: {
            party: 'Fete',
            dance: 'Danser',
            wedding: 'Mariage',
            weddingplanner: 'Organisateur de Mariage / Wedding Planner',
            dj: 'DJ',
            music: 'Musiciens',
            photograph: 'Photographe',
            cameraman: 'Cameraman',
            caterer: 'Traiteur',
            printer: 'Imprimeur',
            announcement: 'Faire-Part / Invitations',
        },
        beauty: {
            nail: 'Ongle',
            leg: 'Jambe',
            suit: 'Maillot',
            esthetic: 'Esthetique',
        },
    },
}
