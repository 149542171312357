<!------------------------------------------------------------------------------
// Kidiwi Digital property
// Component to display an icon, inside a button. This icon is able to manage a click.
// The icon should be set in the parent component.<template>
// This will be able to display a tips on top of the component.
// Props:
// - color: able to add a color
// - tipkey: identifier of the tip to be translated. It should belong to i18n (en.js or fr.js for example)
// Example of usage:
        <kdw-icon-action-tooltip
          tipkey="button.modifyWorkingSlot"
          @click="openDialogEditSlot(timeSlot, i)"
        >
          mdi-pencil
        </kdw-icon-action-tooltip>
// ------------------------------------------------------------------------------>

<template>
  <v-tooltip
    top
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        :min-width="minWidth"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          :color="color"
          small
          v-on="$listeners"
        >
          <slot />
        </v-icon>
      </v-btn>
    </template>
    <span
      v-if="tipkey !== ''"
    >
      {{ $t(tipkey) }}
    </span>
  </v-tooltip>
</template>

<script>
  export default {
    name: 'BaseIconActionTooltip',

    props: {
      color: {
        type: String,
        // default: 'primary',
      },
      tipkey: {
        type: String,
        default: '',
      },
      minWidth: {
        type: [Number, String],
        default: 144,
      },
    },
  }
</script>
