<!------------------------------------------------------------------------------
// Kidiwi Digital property
// This component is used to add, edit, cancel services for a specific service provider.
// Props:
// - searchResultFlag: Trigger this to true when a seach must occur.
// Events:
// -ResetSearchEvent: Emit an event to reset the searchResultFlag
// Component:
    <kdw-service-list-management
      @alertSnackBarMessage="alertSnackBarMessage($event)"
    />
// ------------------------------------------------------------------------------>

<template>
  <div>
    <!-- button to add services for mobiles --->
    <v-row
      class="hidden-md-and-up"
    >
      <v-spacer />
      <kdw-btn
        id="newServiceButtonMobile"
        class="mr-3"
        @click="editItem1()"
      >
        {{ $t('button.newService') }}
      </kdw-btn>
    </v-row>
    <!--- Management of the services for mobiles --->
    <v-row
      class="hidden-md-and-up"
    >
      <v-col
        cols="12"
        sm="12"
      >
        <v-card
          v-show="displayServices"
          elevation="11"
          class="mx-auto my-12 rounded"
          shaped
        >
          <v-system-bar
            color="primary"
            class="grey--text text--lighten-3"
          >
            <v-icon
              class="ml-1 grey--text text--lighten-3"
            >
              mdi-fountain-pen-tip
            </v-icon>
            <!--
            <span class="font-weight-bold">{{ (foundServices.length > 0)? $t(foundServices[0].categoryNameTag).toUpperCase() : '' }}</span>
            --->
          </v-system-bar>
          <v-card-text
            v-for="(serviceItem, r) in myServices"
            :key="r"
          >
            <v-divider
              v-if="r !== 0"
              light
            />
            <!------- Main title  ---->
            <v-list-item-title
              class="primary--text ml-4 mt-4"
              style="white-space: normal"
            >
              <strong>{{ serviceItem.name }}</strong>
            </v-list-item-title>
            <v-list-item>
              <v-list-item-content>
                <!-- category -->
                <v-list-item-subtitle
                  style="white-space: normal"
                  class="concrete--text"
                >
                  <v-icon
                    small
                    class="concrete--text"
                  >
                    mdi-folder-pound
                  </v-icon>
                  {{ $t(serviceItem.categoryNameTag) }}
                </v-list-item-subtitle>
                <!-- sub category -->
                <v-list-item-subtitle
                  v-show="serviceItem.subCategory !== ''"
                  style="white-space: normal"
                  class="concrete--text"
                >
                  <v-icon
                    small
                    class="concrete--text"
                  >
                    mdi-folder-pound-outline
                  </v-icon>
                  {{ $t(serviceItem.subCategoryNameTag) }}
                </v-list-item-subtitle>
                <!-- Duration -->
                <v-list-item-subtitle
                  class="concrete--text"
                >
                  <v-icon
                    small
                    class="concrete--text"
                  >
                    mdi-calendar-week
                  </v-icon>
                  {{ serviceItem.Duration }}
                </v-list-item-subtitle>
                <!-- Activated -->
                <v-list-item-subtitle
                  v-show="serviceItem.isActivated"
                  style="white-space: normal"
                  class="primary--text"
                >
                  <v-icon
                    small
                    class="primary--text"
                  >
                    mdi-checkbox-marked
                  </v-icon>
                  {{ $t('serviceSP.activated') }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-show="!(serviceItem.isActivated)"
                  style="white-space: normal"
                  class="concrete--text"
                >
                  <v-icon
                    small
                    class="concrete--text"
                  >
                    mdi-checkbox-blank-outline
                  </v-icon>
                  {{ $t('serviceSP.deactivated') }}
                </v-list-item-subtitle>
                <!-- Online booking -->
                <v-list-item-subtitle
                  v-show="(serviceItem.isFixedPrice) && (serviceItem.onlineBooking)"
                  style="white-space: normal"
                  class="primary--text"
                >
                  <v-icon
                    small
                    class="primary--text"
                  >
                    mdi-checkbox-marked
                  </v-icon>
                  {{ $t('serviceSP.onlineBooking') }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-show="(serviceItem.isFixedPrice) && !(serviceItem.onlineBooking)"
                  style="white-space: normal"
                  class="concrete--text"
                >
                  <v-icon
                    small
                    class="concrete--text"
                  >
                    mdi-checkbox-blank-outline
                  </v-icon>
                  {{ $t('serviceSP.onlineBooking') }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- Pricing  -->
              <v-list-item-action>
                <v-spacer />
                <kdw-label
                  :text="displayServicePrice(serviceItem.isFixedPrice, serviceItem.ServicePrice)"
                />
              </v-list-item-action>
            </v-list-item>
            <!-- Buttons -->
            <v-list-item>
              <v-spacer />
              <v-list-item-group>
                <v-list-item-action>
                  <kdw-btn
                    id="btnServiceDeleteMobile"
                    color="primary"
                    elevation="10"
                    min-width="50"
                    small
                    @click="deleteItem(serviceItem)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </kdw-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <kdw-btn
                    id="btnServiceEditMobile"
                    color="sunflower"
                    elevation="10"
                    min-width="50"
                    small
                    @click="editItem(serviceItem)"
                  >
                    <v-icon
                      color="white"
                    >
                      mdi-pencil
                    </v-icon>
                  </kdw-btn>
                </v-list-item-action>
              </v-list-item-group>
            </v-list-item>
          </v-card-text>
          <!---- We are taking care of the bottom of the card for mobile --->
          <v-divider />
          <v-card-actions
            class="mt-4"
          >
            <v-combobox
              :key="'ComboFilter1' + displayTrick"
              v-model="filterOptionsSelected"
              :items="getFilterOptions"
              item-text="name"
              item-value="id"
              :label="$t('generalSearchResults.filter')"
              light
              dense
              class="mx-2 ml-5"
              @change="filterOptionsChanged()"
            />
            <v-combobox
              :key="'ComboFilter2' + displayTrick"
              v-model="rowByPageSelected"
              :items="getRowByPage"
              item-text="name"
              item-value="id"
              :label="$t('generalSearchResults.rowsByPage')"
              class="mx-2"
              dense
              disabled
              light
            />
            <v-spacer />
            <v-chip
              disabled
              label
              class="mb-4"
            >
              1/1
            </v-chip>
            <kdw-btn
              min-width="10"
              small
              class="ml-3 mb-4"
              color="rgba(60,60,59,0.6)"
              disabled
            >
              <v-icon>mdi-chevron-left </v-icon>
            </kdw-btn>
            <kdw-btn
              min-width="10"
              small
              class="mr-5 mb-4"
              color="rgba(60,60,59,0.6)"
              disabled
            >
              <v-icon> mdi-chevron-right </v-icon>
            </kdw-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- No service for Mobile ---->
    <v-row
      v-show="!displayServices"
      class="primary--text hidden-md-and-up"
    >
      <strong>{{ $t('serviceSP.noServices') }}</strong>
    </v-row>
    <!-- We manage the DESKTOP interface --->
    <v-row
      class="hidden-sm-and-down"
    >
      <v-col
        cols="12"
        sm="12"
      >
        <v-data-table
          id="serviceListTable"
          :headers="headers"
          :items="myServices"
          sort-by="name"
          class="elevation-1"
          :search="search"
        >
          <template #top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>{{ $t('serviceSP.subTitle') }}</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              />
              <v-spacer />
              <kdw-btn
                id="newServiceButtonDesktop"
                color="primary"
                dark
                class="mb-2"
                @click="editItem1()"
              >
                {{ $t('button.newService') }}
              </kdw-btn>
            </v-toolbar>
          </template>
          <template #body.append>
            <v-text-field
              v-model="search"
              :label="$t('hero.search')"
              class="mx-4"
            />
          </template>
          <template #item.isActivated="{ item }">
            <v-simple-checkbox
              v-model="item.isActivated"
              disabled
            />
          </template>
          <template #item.onlineBooking="{ item }">
            <v-simple-checkbox
              v-model="item.onlineBooking"
              disabled
            />
          </template>
          <template #item.ServicePrice="{ item }">
            <span class="primary--text">
              <strong>{{ displayServicePrice(item.isFixedPrice, item.ServicePrice) }}</strong>
            </span>
          </template>
          <template #item.actions="{ item }">
            <v-icon
              id="iconServiceDeleteDesktop"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
            <v-icon
              id="iconServiceEditDesktop"
              color="primary"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template #no-data>
            {{ $t('serviceSP.ajoutServiceNote') }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!----   Dialog box to confirm the delete of the Service ---------------------------->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-text>
          <kdw-avatar-card
            :key="'myKey1'"
            :icon="'mdi-help-circle-outline'"
            :outlined="false"
            title="serviceSP.deleteQuestion"
            color="transparent"
            horizontal
            space="0"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <kdw-btn
            id="dialogDeleteServiceCancel"
            text
            @click="closeDelete"
          >
            {{ $t('button.cancel') }}
          </kdw-btn>
          <kdw-btn
            id="dialogDeleteServiceConfirm"
            @click="deleteItemConfirm"
          >
            {{ $t('button.validateQuestion') }}
          </kdw-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--- Dialog to create or edit a service  --------------------------------------------------------->
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <base-info-card
            :title="formTitle ()"
            color="primary"
          />
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-card>
              <v-card-title>
                <base-info-card
                  :title="$t('serviceSP.categories')"
                  color="primary"
                />
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-autocomplete
                      v-if="myCategories.length != 0"
                      id="inputCategory"
                      :key="myCatKey"
                      v-model="selectedCategory"
                      class="text-field-single-line-label-top text-field-single-line-prepend-append-margin-top"
                      :items="myCategories"
                      item-text="name"
                      item-value="id"
                      auto-select-first
                      dense
                      prepend-icon="mdi-folder-pound"
                      :label="$t('serviceSP.categories')"
                      @change="categorySelectionChanged"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-autocomplete
                      v-if="mySubCategories.length != 0"
                      id="inputSubCategory"
                      v-model="selectedSubCategory"
                      class="text-field-single-line-label-top text-field-single-line-prepend-append-margin-top"
                      :items="mySubCategories"
                      item-text="name"
                      item-value="id"
                      auto-select-first
                      dense
                      prepend-icon="mdi-folder-pound-outline"
                      :label="$t('serviceSP.subcategories')"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="10"
                    sm="6"
                  >
                    <kdw-text-field
                      v-if="selectedCategory.length == 0"
                      id="inputNewCategory"
                      v-model="newCategory"
                      class="text-field-single-line-label-top"
                      :label="$t('serviceSP.newCategory')"
                      prepend-icon="mdi-folder-pound"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <kdw-text-field
                      v-if="(selectedCategory.length !== 0 && selectedSubCategory.length === 0) || (newCategory.length !== 0)"
                      id="inputNewSubCategory"
                      v-model="newSubCategory"
                      class="text-field-single-line-label-top"
                      :label="$t('serviceSP.newSubCategory')"
                      hint="Optionel"
                      prepend-icon="mdi-folder-pound-outline"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              class="mt-2 mb-3"
            >
              <v-card-title>
                <base-info-card
                  :title="$t('serviceSP.serviceDescription')"
                  color="primary"
                />
              </v-card-title>
              <v-card-text>
                <v-row>
                  <kdw-text-field
                    id="inputServiceName"
                    v-model="myService.name"
                    :label="$t('serviceSP.serviceName')"
                    prepend-icon="mdi-fountain-pen-tip"
                  />
                </v-row>
                <v-row>
                  <v-textarea
                    id="inputServiceDescription"
                    v-model="myService.serviceDescription"
                    :label="$t('serviceSP.serviceDescription')"
                    auto-grow
                    outlined
                    prepend-icon="mdi-comment-eye-outline"
                    rows="1"
                  />
                </v-row>
                <v-row>
                  <v-textarea
                    id="inputServiceNotePersonal"
                    v-model="myService.notePersonal"
                    :label="$t('serviceSP.internalNote')"
                    auto-grow
                    outlined
                    prepend-icon="mdi-comment-minus"
                    rows="1"
                  />
                </v-row>
                <v-row>
                  <v-col
                    cols="8"
                    sm="8"
                  >
                    <v-textarea
                      id="inputServiceLegal"
                      v-model="myService.serviceLegaldescription"
                      :label="$t('serviceSP.legalNote')"
                      auto-grow
                      outlined
                      prepend-icon="mdi-comment-flash-outline"
                      rows="1"
                    />
                  </v-col>
                  <v-col
                    cols="2"
                    sm="2"
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          id="btnLoadLegal"
                          color="primary"
                          dark
                          rounded
                          v-bind="attrs"
                          v-on="on"
                          @click="defaultLegalLoad"
                        >
                          <v-icon>
                            mdi-content-copy
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('serviceSP.defaultLegal') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              class="mt-2 mb-3"
            >
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-checkbox
                      id="checkboxFixedPriceID"
                      v-model="myService.isFixedPrice"
                      :label="$t('serviceSP.fixedPriceService')"
                      type="checkbox"
                      required
                      @click="servicePriceChange()"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <kdw-text-field
                      v-if="myService.isFixedPrice"
                      id="inputServicePrice"
                      v-model="myService.ServicePrice"
                      :label="$t('hero.priceTable')"
                      prefix="€"
                    />
                  </v-col>
                </v-row>
                <v-row
                  v-show="true"
                  v-if="myService.isFixedPrice"
                >
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-checkbox
                      id="checkboxServiceOnlineBooking"
                      v-model="myService.onlineBooking"
                      :label="$t('serviceSP.bookingOnline')"
                      type="checkbox"
                      required
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-checkbox
                      id="checkboxServiceActivated"
                      v-model="myService.isActivated"
                      :label="$t('serviceSP.serviceOpen')"
                      type="checkbox"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-checkbox
                      v-if="myService.isFixedPrice === true"
                      id="checkboxServiceAutoAccepted"
                      v-model="myService.serviceAutoAccepted"
                      :label="$t('serviceSP.serviceAutoConfirmed')"
                      type="checkbox"
                      required
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-title>
                <base-info-card
                  :title="$t('serviceSP.serviceDuration')"
                  color="primary"
                />
              </v-card-title>
              <v-card-text>
                <v-row
                  class="mx-0 my-0"
                >
                  <v-col
                    class="mx-0 my-0"
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      id="inputServiceDurationDay"
                      v-model="serviceDurationDay"
                      type="number"
                      class="mx-0 my-0"
                      outlined
                      dense
                      :label="$t('serviceSP.DurationDD')"
                      :hint="$t('serviceSP.DurationDD_hint')"
                      prepend-icon="mdi-calendar-week"
                    />
                  </v-col>
                  <v-spacer />
                  <v-col
                    class="mx-0 my-0"
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      id="inputServiceDurationHour"
                      v-model="serviceDurationHour"
                      type="number"
                      class="mx-0 my-0"
                      outlined
                      dense
                      :label="$t('serviceSP.DurationHH')"
                      :hint="$t('serviceSP.DurationHH_hint')"
                      prepend-icon="mdi-calendar-week"
                    />
                  </v-col>
                  <v-spacer />
                  <v-col
                    class="mx-0 my-0"
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      id="inputServiceDurationMinute"
                      v-model="serviceDurationMinute"
                      type="number"
                      class="mx-0 my-0"
                      outlined
                      dense
                      :label="$t('serviceSP.DurationMM')"
                      :hint="$t('serviceSP.DurationMM_hint')"
                      prepend-icon="mdi-calendar-week"
                    />
                  </v-col>
                </v-row>
                <v-row
                  class="mx-0 my-0"
                >
                  <v-col
                    class="mx-0 my-0"
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      id="inputServiceDurationBefore"
                      v-model="myService.bufferTimeBefore"
                      type="number"
                      class="mx-0 my-0"
                      outlined
                      dense
                      :label="$t('serviceSP.beforeDuration')"
                      :hint="$t('serviceSP.beforeDuration_hint')"
                      prepend-icon="mdi-calendar-today"
                    />
                  </v-col>
                  <v-spacer />
                  <v-col
                    class="mx-0 my-0"
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      id="inputServiceDurationAfter"
                      v-model="myService.bufferTimeAfter"
                      type="number"
                      class="mx-0 my-0"
                      outlined
                      dense
                      :label="$t('serviceSP.afterDuration')"
                      :hint="$t('serviceSP.afterDuration_hint')"
                      prepend-icon="mdi-calendar"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <p>
                    {{ $t('serviceSP.note') }}
                  </p>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <kdw-btn
            id="btnDialogNewServiceCancel"
            text
            small
            @click="close"
          >
            {{ $t('button.cancel') }}
          </kdw-btn>
          <kdw-btn
            id="btnDialogNewServiceSave"
            small
            @click="save"
          >
            {{ formButtonTitle() }}
          </kdw-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  // import { normaliseTextFR } from '@/helpers/GenericFunctions'
  // import { formatServicePriceString } from '@/helpers/Formatter'
  import { fetchCategory } from '@/services/Category'
  import { checkSPConsent } from '@/services/Consent'
  import { Logger, API } from 'aws-amplify'
  import { listServices, getCategory, getSubCategory, getServiceProvider } from '@/graphql/queries'
  import { listServiceResourcesSimplified, listServiceRequestsServiceCheck } from '@/graphql/kdwqueries'
  import { updateServiceProvider, deleteService as deleteServiceMutation, updateSubCategory as updateSubCategoryMutation, updateCategory as updateCategoryMutation, updateService as updateServiceMutation, createService as createServiceMutation, createCategory as createCategoryMutation, createSubCategory as createSubCategoryMutation, deleteServiceResources as deleteServiceResourcesMutation } from '@/graphql/mutations'
  import { formatServiceDurationFromM, formatServicePrice, formatCategoryOrSubCategoryName, formatServicePriceString } from '@/helpers/Formatter'
  import { getUserSub } from '@/services/User'
  import { compare } from '@/helpers/GenericFunctions'
  import { loadServiceProviderParameters } from '@/services/Parameters'

  const logger = new Logger('ServiceListManagement')

  export default {
    name: 'ServiceListManagement',

    data: () => ({
      rowByPage: [],
      rowByPageSelected: { id: 'all' },
      filterOptions: [],
      filterOptionsSelected: { id: 'NAME↓' },
      displayTrick: 0,
      displayServices: false,
      foundServices: [],
      dialogInfoService: false,
      infoServiceItem: { name: '' },
      // New variables
      myServices: [],
      consentOK: '',
      search: '',
      myService: { id: '', serviceProviderID: '', categoryID: '', serviceCategoryId: '', serviceSubCategoryId: '', name: '', serviceDescription: '', isFixedPrice: false, isActivated: false, keywords: [], serviceAutoAccepted: false, serviceDuration: 0, bufferTimeBefore: 0, bufferTimeAfter: 0, ServicePrice: 0, notePersonal: '', serviceLegaldescription: '', onlineBooking: false },
      serviceAutoAcceptedFirstTime: true,
      myCategories: [],
      mySubCategories: [],
      selectedCategory: '',
      selectedSubCategory: '',
      newCategory: '',
      newSubCategory: '',
      serviceDurationMinute: 0,
      serviceDurationDay: 0,
      serviceDurationHour: 1,
      myCatKey: 1,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: { name: '', ServicePrice: 0, isActivated: false, serviceDuration: 0, categoryID: '', serviceCategoryId: '', serviceSubCategoryId: '', id: '' },
      defaultItem: { name: '', ServicePrice: 0, isActivated: false, serviceDuration: 0, categoryID: '', serviceCategoryId: '', serviceSubCategoryId: '', id: '' },
    }),

    computed: {
      getRowByPage: function () {
        return this.getRowByPageMethod()
      },
      getFilterOptions: function () {
        return this.getFilterOptionsMethod()
      },
      headers: function () {
        return [{ text: this.$i18n.t('hero.serviceTable'), align: 'start', sortable: true, value: 'name' },
                { text: this.$i18n.t('hero.priceTable'), value: 'ServicePrice' },
                { text: this.$i18n.t('serviceSP.activated'), value: 'isActivated' },
                { text: this.$i18n.t('serviceSP.onlineBooking'), value: 'onlineBooking' },
                { text: this.$i18n.t('hero.durationTable'), value: 'Duration' },
                { text: this.$i18n.t('hero.categoryTable'), value: 'category' },
                { text: this.$i18n.t('hero.subcategoryTable'), value: 'subCategory' },
                { text: this.$i18n.t('hero.actionTable'), value: 'actions', sortable: false },
        ]
      },
    },

    watch: {
    },

    created: function () {
      this.getFilterOptionsMethod()
      this.getRowByPageMethod()
      // console.log('Created')
      this.fetchServices(this.myServices)
        .then(() => {
          if (this.myServices.length > 0) {
            this.sortFoundServices()
            this.displayServices = true
          } else this.displayServices = false
        })
      fetchCategory(this.myCategories)
      checkSPConsent()
        .then(result => {
          this.consentOK = result
        })
      // console.log('My Service after creation: ')
      // console.log(this.myService)
    },

    methods: {
      defaultLegalLoad () {
        loadServiceProviderParameters()
          .then(result => {
            // console.log(result)
            if (result !== false) {
              // console.log('result.parameters')
              // console.log(result.parameters)
              if (result.parameters !== undefined) {
                const parametersLoaded = JSON.parse(result.parameters)
                // console.log('legal:')
                // console.log(parametersLoaded.legal.default)
                this.myService.serviceLegaldescription = parametersLoaded.legal.default
              }
            }
          })
          .catch(err => logger.error(err))
      },

      formTitle () {
        // console.log('form title :' + this.editedIndex)
        if (this.editedIndex === -1) {
          return this.$i18n.t('button.newService')
        } else {
          return this.$i18n.t('serviceSP.serviceUpdate')
        }
      },

      formButtonTitle () {
        // console.log('form title :' + this.editedIndex)
        return this.editedIndex === -1 ? this.$i18n.t('serviceSP.serviceCreation') : this.$i18n.t('serviceSP.serviceModification')
      },

      displayServicePrice (isFixedPrice, ServicePrice) {
        return formatServicePriceString(isFixedPrice, ServicePrice)
      },
      // --------------------- METHODS TO MANAGE THE FILTERS ------------------------------
      getRowByPageMethod: function () {
        const table = [{ id: '1', name: '1' },
                       { id: '2', name: '2' },
                       { id: '5', name: '5' },
                       { id: '10', name: '10' },
                       { id: '15', name: '15' },
                       { id: 'all', name: this.$i18n.t('generalSearchResults.all') }]
        if (this.rowByPageSelected.id === 'all') {
          this.rowByPageSelected.name = this.$i18n.t('generalSearchResults.all')
        }
        return table
      },
      getFilterOptionsMethod () {
        const table = [{ id: 'PRICE↑', name: this.$i18n.t('generalSearchResults.filterPriceUp') },
                       { id: 'PRICE↓', name: this.$i18n.t('generalSearchResults.filterPriceDown') },
                       { id: 'CATEGORY↑', name: this.$i18n.t('generalSearchResults.filterCategoryUp') },
                       { id: 'CATEGORY↓', name: this.$i18n.t('generalSearchResults.filterCategoryDown') },
                       { id: 'SUBCATEGORY↑', name: this.$i18n.t('generalSearchResults.filterSubCategoryUp') },
                       { id: 'SUBCATEGORY↓', name: this.$i18n.t('generalSearchResults.filterSubCategoryDown') },
                       { id: 'NAME↑', name: this.$i18n.t('generalSearchResults.filterNameUp') },
                       { id: 'NAME↓', name: this.$i18n.t('generalSearchResults.filterNameDown') }]
        // console.log('this.filterOptionsSelected before')
        // console.log(this.filterOptionsSelected)
        if (this.filterOptionsSelected.id === 'PRICE↑') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterPriceUp')
          return table
        }
        if (this.filterOptionsSelected.id === 'PRICE↓') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterPriceDown')
          return table
        }
        if (this.filterOptionsSelected.id === 'SUBCATEGORY↑') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterSubCategoryUp')
          return table
        }
        if (this.filterOptionsSelected.id === 'SUBCATEGORY↓') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterSubCategoryDown')
          return table
        }
        if (this.filterOptionsSelected.id === 'CATEGORY↑') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterCategoryUp')
          return table
        }
        if (this.filterOptionsSelected.id === 'CATEGORY↓') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterCategoryDown')
          return table
        }
        if (this.filterOptionsSelected.id === 'NAME↑') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterNameUp')
          return table
        }
        if (this.filterOptionsSelected.id === 'NAME↓') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterNameDown')
        }
        // console.log('this.filterOptionsSelected after')
        // console.log(this.filterOptionsSelected)
        this.displayTrick++
        return table
      },
      // Filter option changed
      filterOptionsChanged () {
        // console.log('filterOptionsChanged', this.filterOptionsSelected)
        this.sortFoundServices()
      },
      // This is managing the ordering options of the search content
      sortFoundServices () {
        // console.log('this.foundServices')
        // console.log(this.foundServices)
        switch (this.filterOptionsSelected.id) {
          case 'NAME↑': // sort name by alphabetic order inverse
            this.myServices.sort(function (a, b) {
              if (a.name.toUpperCase() < b.name.toUpperCase()) { return 1 }
              if (a.name.toUpperCase() > b.name.toUpperCase()) { return -1 }
              return 0
            })
            break
          case 'NAME↓': // sort name by alphabetic order
            this.myServices.sort(function (a, b) {
              if (a.name.toUpperCase() < b.name.toUpperCase()) { return -1 }
              if (a.name.toUpperCase() > b.name.toUpperCase()) { return 1 }
              return 0
            })
            break
          case 'PRICE↑': // sort by price inverse (from big to small)
            this.myServices.sort(function (a, b) {
              if (a.ServicePrice < b.ServicePrice) { return 1 }
              if (a.ServicePrice > b.ServicePrice) { return -1 }
              return 0
            })
            break
          case 'PRICE↓': // sort by price (from small to big)
            this.myServices.sort(function (a, b) {
              if (a.ServicePrice < b.ServicePrice) { return -1 }
              if (a.ServicePrice > b.ServicePrice) { return 1 }
              return 0
            })
            break
          case 'SUBCATEGORY↑': // sort subCategory by alphabetic order inverse
            this.myServices.sort(function (a, b) {
              if (a.subCategory.toUpperCase() < b.subCategory.toUpperCase()) { return 1 }
              if (a.subCategory.toUpperCase() > b.subCategory.toUpperCase()) { return -1 }
              return 0
            })
            break
          case 'SUBCATEGORY↓': // sort subCategory by alphabetic order
            this.myServices.sort(function (a, b) {
              if (a.subCategory.toUpperCase() < b.subCategory.toUpperCase()) { return -1 }
              if (a.subCategory.toUpperCase() > b.subCategory.toUpperCase()) { return 1 }
              return 0
            })
            break
          case 'CATEGORY↑': // sort subCategory by alphabetic order inverse
            this.myServices.sort(function (a, b) {
              if (a.category.toUpperCase() < b.category.toUpperCase()) { return 1 }
              if (a.category.toUpperCase() > b.category.toUpperCase()) { return -1 }
              return 0
            })
            break
          case 'CATEGORY↓': // sort subCategory by alphabetic order
            this.myServices.sort(function (a, b) {
              if (a.category.toUpperCase() < b.category.toUpperCase()) { return -1 }
              if (a.category.toUpperCase() > b.category.toUpperCase()) { return 1 }
              return 0
            })
            break
        }
      },
      // ----------------- METHOD To get the services --------------------
      async fetchServices (myServices) {
        // console.log('fetchServices')
        // const user = await Auth.currentAuthenticatedUser()
        // console.log('userSub: ' + user.attributes.sub)
        try {
          // We are using cognito username to search for the service provider as per model
          const myFilter = {
            serviceProviderID: {
              eq: await getUserSub(),
            },
          }
          const apiData = await API.graphql({ query: listServices, variables: { filter: myFilter }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          // console.log(apiData)
          const listServicesFromAPI = apiData.data.listServices.items
          if (listServicesFromAPI.length === 0) {
            // alert('No services for this service provider')
            return
          }
          Promise.all(listServicesFromAPI.map(async service => {
            if (service.name) {
              // console.log(service.name)
              await this.pushServices(myServices, service)
            }
          }))
          // console.log('myServices:')
          // console.log(myServices)
        } catch (err) {
          logger.error(err)
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertFailLoadService'), -1)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertFailLoadService'), snackbarTimeout: -1 })
          // alert(this.$i18n.t('serviceSP.alertFailLoadService'))
        }
      },
      async pushServices (myServices, service) {
        // console.log('pushServices')
        const duration = formatServiceDurationFromM(service.serviceDuration)

        // Make a function to manage later the internationalisation
        const price = formatServicePrice(service.isFixedPrice, service.ServicePrice)
        // console.log('isFixedPrice dans push service:' + service.isFixedPrice)

        var subCategoryName = ''
        var subCategoryNameTag = ''
        var subCategoryID = ''
        if (service.subCategory !== null) {
          // subCategoryName = service.subCategory.name
          subCategoryName = formatCategoryOrSubCategoryName(service.subCategory.name, service.subCategory.countryCode, service.subCategory.nameTag)
          subCategoryID = service.subCategory.id
          subCategoryNameTag = service.subCategory.nameTag
        }
        const catName = formatCategoryOrSubCategoryName(service.category.name, service.category.countryCode, service.category.nameTag)
        myServices.push({ name: service.name, ServicePrice: price, isActivated: service.isActivated, Duration: duration, category: catName, categoryNameTag: service.category.nameTag, subCategory: subCategoryName, subCategoryNameTag: subCategoryNameTag, id: service.id, categoryID: service.category.id, serviceCategoryId: service.category.id, serviceSubCategoryId: subCategoryID, serviceDuration: service.serviceDuration, serviceDescription: service.serviceDescription, bufferTimeBefore: service.bufferTimeBefore, bufferTimeAfter: service.bufferTimeAfter, serviceAutoAccepted: service.serviceAutoAccepted, isFixedPrice: service.isFixedPrice, serviceProviderID: service.serviceProviderID, servicePostcodecityId: service.postcodecity.id, notePersonal: service.notePersonal, serviceLegaldescription: service.serviceLegaldescription, onlineBooking: service.onlineBooking })
      },

      async checkServiceAlreadyDeployed (serviceID, messTag) {
        try {
          // console.log('serviceID:', serviceID)
          if (serviceID === '') return true
          const myFilter = {
            serviceRequestsId: { eq: serviceID },
          }
          var apiData
          var apiDataSimplified
          var nextTokenStart = 'start'
          while (nextTokenStart !== null) {
            if (nextTokenStart === 'start') {
              apiData = await API.graphql({ query: listServiceRequestsServiceCheck, variables: { filter: myFilter, limit: 4000 }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            } else {
              apiData = await API.graphql({ query: listServiceRequestsServiceCheck, variables: { filter: myFilter, limit: 4000, nextToken: nextTokenStart }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            }
            // console.log('apiData:')
            // console.log(apiData)
            apiDataSimplified = apiData.data.listServiceRequests.items
            nextTokenStart = apiData.data.listServiceRequests.nextToken
            if (apiDataSimplified.length > 0) {
              // Message as the service can not be deleted.
              this.$emit('alertSnackBarMessage', { message: this.$i18n.t(messTag), snackbarTimeout: -1 })
              // this.alertSnackBar(this.$i18n.t(messTag), -1)
              return false
            }
          }
          return true
        } catch (err) {
          console.log(err)
          return false
        }
      },

      async deleteAService (serviceID) {
        try {
          // console.log('serviceID', serviceID)
          // We need first to check if this service has been deployed somewhere.
          var myFilter = {
            serviceRequestsId: { eq: serviceID },
          }
          var apiData
          var apiDataSimplified
          var nextTokenStart = 'start'

          // Now we are deleting the ServiceRessource associated
          myFilter = {
            serviceResourcesId: { eq: serviceID },
          }
          while (nextTokenStart !== null) {
            if (nextTokenStart === 'start') {
              apiData = await API.graphql({ query: listServiceResourcesSimplified, variables: { filter: myFilter, limit: 4000 }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            } else {
              apiData = await API.graphql({ query: listServiceResourcesSimplified, variables: { filter: myFilter, limit: 4000, nextToken: nextTokenStart }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            }
            // console.log('apiData:')
            // console.log(apiData)
            apiDataSimplified = apiData.data.listServiceResources.items
            nextTokenStart = apiData.data.listServiceResources.nextToken
            // console.log('apiDataSimplified:')
            // console.log(apiDataSimplified)
            for (var i = 0; i < apiDataSimplified.length; i++) {
              // We delete the reference Service/Ressource in ServiceResources table
              // console.log('trying to delete', apiDataSimplified[i].id)
              // console.log('before deleteServiceResourcesMutation')
              await API.graphql({ query: deleteServiceResourcesMutation, variables: { input: { id: apiDataSimplified[i].id } }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
              // console.log('deleteServiceResourcesMutation')
            }
            // We are deleting the service itself
            // console.log('before service deleted from function')
            await API.graphql({ query: deleteServiceMutation, variables: { input: { id: serviceID } }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            // console.log('service deleted from function')
          }
        } catch (err) {
          // console.log('We had an error')
          console.log(err)
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertFailDeleteService'), -1)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertFailDeleteService'), snackbarTimeout: -1 })
        }
      },

      // When the Service Provider selects an item
      categorySelectionChanged () {
        const clean = this.getSubCategoryData(this.selectedCategory, this.selectedSubCategory, this.mySubCategories)
        if (clean) {
          this.selectedSubCategory = ''
          this.newCategory = ''
        }
      },

      servicePriceChange () {
        // console.log('service Price changed is clicked')
        // console.log(this.myService)
        // console.log('Service Price : ' + this.myService.ServicePrice)

        // We need to check if the service is already deployed or not before allowing the change
        this.checkServiceAlreadyDeployed(this.myService.id, 'serviceSP.alertCanNotModifyServicePriceType')
          .then((statusResult) => {
            // console.log('statusResult:', statusResult)
            if (!statusResult) {
              // it is already deployed somewhere
              this.myService.isFixedPrice = !this.myService.isFixedPrice
            } else {
              // it is NOT already deployed somewhere
              // We are putting the serviceAutoAccepted true by default for fixed price
              // console.log('serviceAutoAcceptedFirstTime check', this.serviceAutoAcceptedFirstTime)
              if (this.serviceAutoAcceptedFirstTime) {
                this.serviceAutoAcceptedFirstTime = false
                this.myService.serviceAutoAccepted = true
              }
              if ((this.myService.isFixedPrice === true) && isNaN(this.myService.ServicePrice)) {
                // console.log('il fuat changer la valeur... à 0')
                this.myService.ServicePrice = 0
              }
            }
          })
      },

      submit () {
        if (this.selectedCategory === '' && this.newCategory === '') {
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertCategorySelected'), 3000)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertCategorySelected'), snackbarTimeout: 3000 })
          return
        }

        if (this.myService.name === '') {
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertServiceNameMandatory'), 3000)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertServiceNameMandatory'), snackbarTimeout: 3000 })
          return
        }

        if (this.myService.isFixedPrice === '1') {
          // We have to verify if the service price is a number.
          if (isNaN(this.myService.ServicePrice)) {
            // this.alertSnackBar(this.$i18n.t('serviceSP.alertPriceNumberOnly'), 3000)
            this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertPriceNumberOnly'), snackbarTimeout: 3000 })
            return
          }
        }

        if (this.myService.isFixedPrice === '1' && this.myService.ServicePrice === 0) {
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertPriceMustDefine'), 3000)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertPriceMustDefine'), snackbarTimeout: 3000 })
          return
        }
        // We have to verify if the duration, before, after and during are numbers.
        if (isNaN(this.serviceDurationDay)) {
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertDayNumber'), snackbarTimeout: 3000 })
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertDayNumber'), 3000)
          // alert(this.$i18n.t('serviceSP.alertDayNumber'))
          return
        }
        if (isNaN(this.serviceDurationHour)) {
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertHourNumber'), 3000)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertHourNumber'), snackbarTimeout: 3000 })
          return
        }
        if (isNaN(this.serviceDurationMinute)) {
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertMinutesNumber'), 3000)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertMinutesNumber'), snackbarTimeout: 3000 })
          return
        }
        if (isNaN(this.myService.bufferTimeBefore)) {
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertDurationBeforeNumber'), 3000)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertDurationBeforeNumber'), snackbarTimeout: 3000 })
          return
        }
        if (isNaN(this.myService.bufferTimeAfter)) {
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertDurationAfterNumber'), 3000)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertDurationAfterNumber'), snackbarTimeout: 3000 })
          return
        }

        this.myService.serviceDuration = parseInt(this.serviceDurationMinute) + parseInt(this.serviceDurationHour) * 60 + parseInt(this.serviceDurationDay) * 1440
        // console.log('myservice avant createCategoryAndSubCategoryAndService:')
        // console.log(this.myService)
        this.createCategoryAndSubCategoryAndService(this.myServices, this.myService, this.selectedCategory, this.newCategory, this.selectedSubCategory, this.newSubCategory)
          .then(() => {
            this.sortFoundServices()
          })
      },

      // ---------------- Manage the Dialogs ----------------------------------
      initialize () {
        this.myService = { id: '', serviceProviderID: '', categoryID: '', serviceCategoryId: '', serviceSubCategoryId: '', name: '', serviceDescription: '', isFixedPrice: false, isActivated: false, keywords: [], serviceAutoAccepted: false, serviceDuration: 0, bufferTimeBefore: 0, bufferTimeAfter: 0, ServicePrice: 0 }
        this.myCategories = []
        fetchCategory(this.myCategories)
        this.mySubCategories = []
        this.selectedCategory = ''
        this.selectedSubCategory = ''
        this.newCategory = ''
        this.newSubCategory = ''
        this.serviceDurationMinute = 0
        this.serviceDurationDay = 0
        this.serviceDurationHour = 1
      },
      // this is for a new Service
      editItem1 () {
        fetchCategory(this.myCategories)
        this.newCategory = ''
        this.newSubCategory = ''
        this.serviceAutoAcceptedFirstTime = true
        // console.log('My Service: ')
        // console.log(this.myService)
        this.dialog = true
      },
      // this is for editing a Service
      editItem (item) {
        // initforEdition()
        // console.log('editItem')
        fetchCategory(this.myCategories)
        this.getSubCategoryData(item.serviceCategoryId, 'MySelectedSubcategories', this.mySubCategories)
        this.selectedCategory = item.serviceCategoryId
        // console.log('item : ')
        // console.log(item)
        // console.log(item.serviceSubCategoryId)
        if (item.serviceSubCategoryId !== null) {
          this.selectedSubCategory = item.serviceSubCategoryId
        } else {
          this.selectedSubCategory = ''
        }
        // console.log(this.selectedSubCategory)
        this.serviceDurationDay = Math.trunc(item.serviceDuration / 1440)
        this.serviceDurationHour = Math.trunc((item.serviceDuration - (1440 * this.serviceDurationDay)) / 60)
        this.serviceDurationMinute = item.serviceDuration - (1440 * this.serviceDurationDay) - (this.serviceDurationHour * 60)
        this.newCategory = ''
        this.newSubCategory = ''
        this.myService = { id: item.id, serviceProviderID: item.serviceProviderID, categoryID: item.serviceCategoryId, serviceCategoryId: item.serviceCategoryId, serviceSubCategoryId: item.serviceSubCategoryId, name: item.name, serviceDescription: item.serviceDescription, isFixedPrice: item.isFixedPrice, isActivated: item.isActivated, keywords: [], serviceAutoAccepted: item.serviceAutoAccepted, serviceDuration: item.serviceDuration, bufferTimeBefore: item.bufferTimeBefore, bufferTimeAfter: item.bufferTimeAfter, ServicePrice: item.ServicePrice, notePersonal: item.notePersonal, serviceLegaldescription: item.serviceLegaldescription, onlineBooking: item.onlineBooking }
        if (!this.myService.isFixedPrice) {
          this.serviceAutoAcceptedFirstTime = true
        } else {
          this.serviceAutoAcceptedFirstTime = false
        }
        // console.log('My Service: ')
        // console.log(this.myService)
        this.editedIndex = this.myServices.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        // console.log('item to delete:')
        // console.log(item)
        this.checkServiceAlreadyDeployed(item.id, 'serviceSP.alertCanNotDeleteService')
          .then((statusResult) => {
            if (statusResult) {
              this.editedIndex = this.myServices.indexOf(item)
              // console.log(this.editedIndex)
              this.editedItem = Object.assign({}, item)
              // console.log(this.editedItem)
              this.dialogDelete = true
            }
          })
      },

      deleteItemConfirm () {
        this.deleteAService(this.myServices[this.editedIndex].id)
        this.myServices.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.initialize()
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        // We check if the consent was given before we proceed
        if (this.consentOK !== '') {
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t(this.consentOK), snackbarTimeout: -1 })
          // this.alertSnackBar(this.consentOK, -1)
          this.close()
          return
        }
        // Consent have been given, so we can move forward.
        if (this.editedIndex > -1) {
          // console.log('modification')
          // this is a modification
          this.submit()
        } else {
          // this is a creation of a new service
          // console.log('creation of a new service')
          this.submit()
        }
        this.close()
        // })
        // .catch(err => logger.error(err))
      },

      // ---------------- METHOD to manage the categories --------------------
      async fetchACategory (catID) {
        try {
          const myNewCategory = await API.graphql({ query: getCategory, variables: { id: catID }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          return formatCategoryOrSubCategoryName(myNewCategory.data.getCategory.name, myNewCategory.data.getCategory.countryCode, myNewCategory.data.getCategory.nameTag)
        } catch (err) {
          logger.error(err)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertFailFetchCategory'), snackbarTimeout: -1 })
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertFailFetchCategory'), -1)
        }
      },

      async fetchASubCategory (subCatID) {
        try {
          const myNewSubCategory = await API.graphql({ query: getSubCategory, variables: { id: subCatID }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          if ((myNewSubCategory.data.getSubCategory.name === undefined) || (myNewSubCategory.data.getSubCategory.name === '')) {
            return myNewSubCategory.data.getSubCategory.name
          }
          return formatCategoryOrSubCategoryName(myNewSubCategory.data.getSubCategory.name, myNewSubCategory.data.getSubCategory.countryCode, myNewSubCategory.data.getSubCategory.nameTag)
        } catch (err) {
          logger.error(err)
          logger.error(this.$i18n.t('serviceSP.alertFailFetchSubCategory'), -1)
        }
      },

      async getSubCategoryData (myCategoryId, MySelectedSubcategories, mySubCategories) {
        try {
          mySubCategories.splice(0, mySubCategories.length)
          const apiData = await API.graphql({ query: getCategory, variables: { id: myCategoryId }, authMode: 'AWS_IAM' })
          // const apiData = await API.graphql({ query: listSubCategorys, variables: { filter: myFilterSubCategory }, authMode: 'AWS_IAM' })
          // console.log('apiData')
          // console.log(apiData)
          const subCategoriesFromAPI = apiData.data.getCategory.subCategorys.items
          // const subCategoriesFromAPI = apiData.data.listSubCategorys.items
          if (subCategoriesFromAPI.length === 0) {
            return false
          }
          Promise.all(subCategoriesFromAPI.map(async subCategory => {
            if (subCategory.name) {
              // alert(subCategory.name)
              mySubCategories.push({ name: formatCategoryOrSubCategoryName(subCategory.name, subCategory.countryCode, subCategory.nameTag), id: subCategory.id })
              // console.log(subCategory.name)
            }
          }))
          mySubCategories.sort(compare)
          // console.log('end of the list of sub category')
          return true
        } catch (err) {
          logger.error(err)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertFailLoadSubCategories'), snackbarTimeout: -1 })
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertFailLoadSubCategories'), -1)
          return false
        }
      },

      // myServicePassed : correspond to myService of Component
      async createCategoryAndSubCategoryAndService (myServicesDisplayed, myServicePassed, selectedCategory, newCategory, selectedSubCategory, newSubCategory) {
        try {
          // console.log('createCategoryAndSubCategoryAndService ')
          // alert(myServicePassed.name)
          // alert(myServicePassed.id)
          if (myServicePassed.id !== '') {
            // console.log('le service existe deja')
            // TO CHECK THE MNGT OF THE CATEGORY
            if (selectedCategory === '' && newCategory !== '') {
              // CHANGED const nameAcat = await fetchACategory(myServicePassed.categoryID)
              const nameAcat = await this.fetchACategory(myServicePassed.serviceCategoryId)
              // alert(nameAcat)
              if (nameAcat !== newCategory) {
                // We should updte the new category
                const categorytoUpdate = { id: myServicePassed.serviceCategoryId, name: newCategory }
                await this.updateMycategory(categorytoUpdate)
                // alert('la category a été mise à jour')
              }
            }
            // TO CHECK THE MNGT OF THE SUB-CATEGORY
            if (selectedSubCategory === '' && newSubCategory !== '') {
              // console.log('A  sub cat change')
              const nameASubcat = await this.fetchASubCategory(myServicePassed.serviceSubCategoryId)
              // alert(nameASubcat)
              if (nameASubcat === undefined) {
                // ----> Change - We need to create a new subcategory - was not there, so added
                // console.log('A new sub category should be created')
                // console.log('newSubCategory:' + newSubCategory)
                // console.log('id de la category' + myServicePassed.serviceCategoryId)
                const myNewSelectedSubCategory = await this.createSubCategory(newSubCategory, myServicePassed.serviceCategoryId)
                myServicePassed.serviceSubCategoryId = myNewSelectedSubCategory.data.createSubCategory.id
              } else if (nameASubcat !== newSubCategory) {
                // We should update the new category
                const subCategorytoUpdate = { id: myServicePassed.serviceSubCategoryId, name: newSubCategory }
                await this.updateMySubcategory(subCategorytoUpdate)
                // alert('la sub category a été mise à jour')
              }
            }
            if (selectedCategory !== '' && myServicePassed.serviceCategoryId !== selectedCategory) {
              // the end user pick a new category from the category list
              myServicePassed.serviceCategoryId = selectedCategory
              myServicePassed.categoryID = selectedCategory
              const subCategorytoUpdate = { id: myServicePassed.serviceSubCategoryId, subCategoryCategoryId: selectedCategory }
              // --->Change const subCategorytoUpdate = { id: myServicePassed.serviceSubCategoryId, categoryId: selectedCategory }
              await this.updateMySubcategory(subCategorytoUpdate)
            }
            if (selectedSubCategory !== '' && myServicePassed.serviceSubCategoryId !== selectedSubCategory) {
              // the end user pick a new sub category from the category list
              myServicePassed.serviceSubCategoryId = selectedSubCategory
            }
            // Update the service
            this.updateMyService(myServicesDisplayed, myServicePassed)
            // alert('le service est mis à jour')
            return
          }
          // console.log('Myservice passed')
          // console.log(myServicePassed)
          if (selectedCategory === '' && newCategory !== '') {
            // alert('on va créer la nouvelle catégorie')
            const myNewSelectedCategory = await this.createCategory(newCategory)
            if (selectedSubCategory === '' && newSubCategory !== '') {
              // alert('on va créer la sous-catégorie')
              const myNewSelectedSubCategory = await this.createSubCategory(newSubCategory, myNewSelectedCategory.data.createCategory.id)
              await this.createService(myServicesDisplayed, myServicePassed, myNewSelectedCategory.data.createCategory.id, myNewSelectedSubCategory.data.createSubCategory.id)
              return
            } else {
              // The subcategory already exist
              // alert('la sous categorie existe deja on a juste cree la categorie')
              await this.createService(myServicesDisplayed, myServicePassed, myNewSelectedCategory.data.createCategory.id, selectedSubCategory)
              return
            }
          } else {
            // La category existe
            // alert('la categorie existe deja')
            if (selectedSubCategory === '' && newSubCategory !== '') {
              // alert('on va créer la sous-catégorie')
              const myNewSelectedSubCategory = await this.createSubCategory(newSubCategory, selectedCategory)
              await this.createService(myServicesDisplayed, myServicePassed, selectedCategory, myNewSelectedSubCategory.data.createSubCategory.id)
              return
            } else {
              // The subcategory already exist
              // alert('la sous categorie existe deja on a juste cree la categorie')
              await this.createService(myServicesDisplayed, myServicePassed, selectedCategory, selectedSubCategory)
              return
            }
          }
        } catch (err) {
          logger.error(err)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertFailUpdateService'), snackbarTimeout: -1 })
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertFailUpdateService'), -1)
        }
      },

      async updateMyService (myServicesDisplayed, serviceToUpdate) {
        try {
          if (serviceToUpdate.isFixedPrice === false) {
            // console.log('reset price')
            serviceToUpdate.ServicePrice = 0
          }
          // Update the service postal code in case the Service provider changed location
          // const user = await Auth.currentAuthenticatedUser()
          // console.log('sub:' + user.attributes.sub)
          // Get the postcode of the service provider
          var apiData1 = await API.graphql({ query: getServiceProvider, variables: { id: await getUserSub() }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          // console.log('Service provider data:')
          // console.log(apiData)
          var serviceProviderPostCodeCity = apiData1.data.getServiceProvider.postcodecitycpy.id
          // console.log('postcode city:' + serviceProviderPostCodeCity)
          // console.log(serviceToUpdate)
          serviceToUpdate.servicePostcodecityId = serviceProviderPostCodeCity

          // console.log('update du service' + serviceToUpdate.id)
          // console.log(serviceToUpdate)
          const apiData = await API.graphql({ query: updateServiceMutation, variables: { input: serviceToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          const myServiceUpdated = apiData.data.updateService
          // console.log('update du service: db ok')
          // console.log('length:' + myServicesDisplayed.length)
          // console.log(myServicesDisplayed)
          for (let i = 0; i < myServicesDisplayed.length; i++) {
            // console.log(i + '-' + myServicesDisplayed[i].id)
            if (myServicesDisplayed[i].id === serviceToUpdate.id) {
              // console.log('found')
              // alert('found')
              // remove
              myServicesDisplayed.splice(i, 1)
              // console.log('service deleted')
              // console.log('length:' + myServicesDisplayed.length)
              // console.log(myServicesDisplayed)
              // add
              this.pushServices(myServicesDisplayed, myServiceUpdated)
              // console.log('service pushed - New length:' + myServicesDisplayed.length)
              // console.log(myServicesDisplayed)
            }
          }
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertSuccessUpdateService'), snackbarTimeout: 2000 })
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertSuccessUpdateService'), 2000)
        } catch (err) {
          logger.error(err)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertFailUpdateService'), snackbarTimeout: -1 })
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertFailUpdateService'), -1)
        }
      },

      async updateMycategory (categoryToUpdate) {
        try {
          await API.graphql({ query: updateCategoryMutation, variables: { input: categoryToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertSuccessUpdateCategory'), snackbarTimeout: 2000 })
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertSuccessUpdateCategory'), 2000)
        } catch (err) {
          logger.error(err)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertFailUpdateCategory'), snackbarTimeout: -1 })
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertFailUpdateCategory'), -1)
        }
      },

      async updateMySubcategory (subCategoryToUpdate) {
        try {
          await API.graphql({ query: updateSubCategoryMutation, variables: { input: subCategoryToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertSuccessUpdateSubCategory'), snackbarTimeout: 2000 })
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertSuccessUpdateSubCategory'), 2000)
        } catch (err) {
          logger.error(err)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertFailUpdateSubCategory'), snackbarTimeout: -1 })
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertFailUpdateSubCategory'), -1)
        }
      },

      async createService (myServicesDisplayed, myServicePassed, catID, subCatID) {
        try {
          // console.log('create Service')
          // alert('Create Service ')
          // alert(myServicePassed.name)
          // alert(myServicePassed.id)
          // if (myServicePassed.id !== '') {
          // alert('le service existe deja')
          // }
          // https://stackoverflow.com/questions/39223347/should-i-use-aws-cognito-username-or-sub-uid-for-storing-in-database
          // need to use sub as primary key - username can be kept for information
          // const user = await Auth.currentAuthenticatedUser()
          // console.log('sub:' + user.attributes.sub)
          // Get the postcode of the service provider
          var apiData = await API.graphql({ query: getServiceProvider, variables: { id: await getUserSub() }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          // console.log('Service provider data:')
          // console.log(apiData)
          var serviceProviderPostCodeCity = apiData.data.getServiceProvider.postcodecitycpy.id
          // console.log('postcode city:' + serviceProviderPostCodeCity)

          // Create the service object
          const myService = { serviceProviderID: await getUserSub(), servicePostcodecityId: serviceProviderPostCodeCity, categoryID: catID, serviceCategoryId: catID, serviceSubCategoryId: subCatID, name: myServicePassed.name, serviceDescription: myServicePassed.serviceDescription, isFixedPrice: myServicePassed.isFixedPrice, isActivated: myServicePassed.isActivated, keywords: myServicePassed.keywords, serviceAutoAccepted: myServicePassed.serviceAutoAccepted, serviceDuration: myServicePassed.serviceDuration, bufferTimeBefore: myServicePassed.bufferTimeBefore, bufferTimeAfter: myServicePassed.bufferTimeAfter, ServicePrice: myServicePassed.ServicePrice, notePersonal: myServicePassed.notePersonal, serviceLegaldescription: myServicePassed.serviceLegaldescription, onlineBooking: myServicePassed.onlineBooking }
          // console.log('create Service after my service const is created')
          if (myServicePassed.isFixedPrice === '1' || myServicePassed.isFixedPrice === true) {
            myService.isFixedPrice = true
          } else {
            myService.isFixedPrice = false
          }
          if (myServicePassed.isActivated === '1' || myServicePassed.isActivated === true) {
            myService.isActivated = true
          } else {
            myService.isActivated = false
          }
          if (myServicePassed.serviceAutoAccepted === '1' || myServicePassed.serviceAutoAccepted === true) {
            myService.serviceAutoAccepted = true
          } else {
            myService.serviceAutoAccepted = false
          }
          // In case of quotation based, we force the service not to be AutoAccepted.
          if (myService.isFixedPrice === false) {
            myService.serviceAutoAccepted = false
          }
          // console.log(myServicePassed)
          // console.log(myService)
          // create the service itself
          const apiDataNewService = await API.graphql({ query: createServiceMutation, variables: { input: myService }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          const myNewService = apiDataNewService.data.createService
          // console.log('service created in DB')
          // console.log(myNewService)
          myServicePassed.id = myNewService.id
          myServicePassed.serviceCategoryId = myNewService.category.id
          myServicePassed.categoryID = myNewService.category.id
          if (myNewService.subCategory !== null) {
            myServicePassed.serviceSubCategoryId = myNewService.subCategory.id
          } else {
            myServicePassed.serviceSubCategoryId = ''
            // console.log('sub cat is null')
          }
          myServicePassed.serviceProviderID = myNewService.serviceProviderID
          // console.log('service created in DB 1')
          await this.pushServices(myServicesDisplayed, myNewService)
          // console.log('service created in DB 2')
          // to increase the max service count
          // 1. get the current max service count
          var countMonthMaxActiveService = apiData.data.getServiceProvider.countMonthMaxActiveService
          var nextToken
          const spid = await getUserSub()
          const queryServiceBySpId = 'query ServiceBySPID(  $serviceProviderID: String!  $limit: Int  $nextToken: String  ) {  ServiceBySPID(  serviceProviderID: $serviceProviderID  limit: $limit  nextToken: $nextToken  ) {  items {    id    serviceProviderID    name  }  nextToken  }  }'
          // console.log('service created in DB 3:', spid)
          const queryResult = await API.graphql({ query: queryServiceBySpId, variables: { serviceProviderID: spid, limit: 4000, nextToken }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          // console.log('service created in DB 4')
          const currentServiceCount = queryResult.data.ServiceBySPID.items.length
          if (countMonthMaxActiveService <= currentServiceCount) {
            countMonthMaxActiveService = currentServiceCount
          } else {
            countMonthMaxActiveService++
          }
          // console.log('We passe here')
          await API.graphql({ query: updateServiceProvider, variables: { input: { id: spid, countMonthMaxActiveService } }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          // console.log('We passe here1')
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertSuccessServiceCreated'), snackbarTimeout: 2000 })
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertSuccessServiceCreated'), 2000)
          return myNewService
        } catch (err) {
          logger.error(err)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertFailServiceCreated'), snackbarTimeout: -1 })
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertFailServiceCreated'), -1)
        }
      },

      async createCategory (categoryName) {
        try {
          const myCategory = { name: categoryName, validated: false, countryCode: this.$i18n.locale, keywords: [], nameTag: '', questions: '' }
          const myNewCategory = await API.graphql({ query: createCategoryMutation, variables: { input: myCategory }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertSuccessCategoryCreated'), snackbarTimeout: 3000 })
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertSuccessCategoryCreated'), 3000)
          return myNewCategory
        } catch (err) {
          logger.error(err)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertFailCategoryCreated'), snackbarTimeout: -1 })
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertFailCategoryCreated'), -1)
        }
      },

      async createSubCategory (subCategoryName, catID) {
        try {
          // alert(subCategoryName)
          // alert(categoryID)
          // ---> Change const mySubCategory = { categoryId: catID, name: subCategoryName, validated: false }
          // graphqlV1-->V2 (subCategoryCategoryId --> categorySubCategorysId) const mySubCategory = { subCategoryCategoryId: catID, name: subCategoryName, validated: false, countryCode: this.$i18n.locale, nameTag: '', questions: '' }
          const mySubCategory = { categorySubCategorysId: catID, name: subCategoryName, validated: false, countryCode: this.$i18n.locale, nameTag: '', questions: '' }
          const myNewSubCategory = await API.graphql({ query: createSubCategoryMutation, variables: { input: mySubCategory }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          // alert('Sub Category created' + mySubCategory)
          return myNewSubCategory
        } catch (err) {
          logger.error(err)
          // this.alertSnackBar(this.$i18n.t('serviceSP.alertFailSubCategoryCreated'), -1)
          this.$emit('alertSnackBarMessage', { message: this.$i18n.t('serviceSP.alertFailSubCategoryCreated'), snackbarTimeout: -1 })
        }
      },
    },
  }
</script>
