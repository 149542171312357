<!-- great doc about two way binding with components https://www.digitalocean.com/community/tutorials/vuejs-add-v-model-support -->
<template>
  <v-input
    prepend-icon="mdi-phone"
  >
    <vue-tel-input
      :id="id"
      v-model="internal"
      auto-default-country:="false"
      auto-format:="true"
      default-country="FR"
      mode="national"
      @input="setNumber"
      @validate="validateNumber"
    />
    <!-- preferredCountries isnt working for some obscur reason -->
    <!-- TODO use something from browser (locale?) to set defaultCountry -->
  </v-input>
</template>

<script>
  // lib pulled by vue-tel-input
  import { parsePhoneNumberFromString } from 'libphonenumber-js'
  export default {
    name: 'KdwVueTelInput',
    props: {
      value: {
        type: String,
        default: '',
      },
      selectId: {
        type: String,
        default: () => { return 'IDphone' },
      },
    },
    data () {
      // value is given from parent component
      // lets format it to national format
      return {
        internal: parsePhoneNumberFromString(this.value)?.formatNational(),
        id: this.selectId,
      }
    },
    watch: {
      value (val) {
        // value is given from parent component
        if (val) {
          // lets format it to national format
          this.internal = parsePhoneNumberFromString(val)?.formatNational()
        }
      },
      internal (val) {
        if (val) {
          // console.log('val is ', val)
          this.internal = val
        }
      },
    },
    methods: {
      setNumber: function (number, phoneObject) {
        this.internal = phoneObject.formatted
        this.$emit('input', phoneObject.number)
      },
      validateNumber: function (phoneObject) {
        // console.log(`validate ${phoneObject.number} is valid: ${phoneObject.valid}`)
        this.internal = phoneObject.formatted
      },
    },
  }
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
