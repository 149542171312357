var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('kdw-Statusbar-card',{attrs:{"snackbar-timeout":_vm.snackbarTimeout,"snackbar-message":_vm.snackbarMessage},model:{value:(_vm.snackbarUpdate),callback:function ($$v) {_vm.snackbarUpdate=$$v},expression:"snackbarUpdate"}}),_c('v-dialog',{attrs:{"max-width":"900px","scrollable":"","persistent":""},model:{value:(_vm.dialogBooking),callback:function ($$v) {_vm.dialogBooking=$$v},expression:"dialogBooking"}},[_c('v-card',[_c('v-card-title',[_c('base-info-card',{attrs:{"title":_vm.$t('Booking.dialogBookingTitle'),"color":"primary"}})],1),_c('v-card-text',[_c('v-row',{staticClass:"blue lighten-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_vm._v(" "+_vm._s(_vm.serviceName)+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-chip',{attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.serviceDuration)+" min. ")])],1)],1),_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-icon',{attrs:{"x-large":""},on:{"click":function($event){return _vm.previousDate()}}},[_vm._v(" mdi-skip-previous ")]),_c('kdw-Date-Picker',{key:'Date-Picker' + _vm.trickDisplay,on:{"dateValidated":function($event){return _vm.dateValidated()}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('v-icon',{attrs:{"x-large":""},on:{"click":function($event){return _vm.nextDate()}}},[_vm._v(" mdi-skip-next ")])],1),_c('v-row',[_c('v-expansion-panels',{attrs:{"inset":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('Booking.ressourceTitle'))+" "),_c('b',[_vm._v(_vm._s(_vm.selectedRessource.name))])]),_c('v-col',{staticClass:"text--secondary",attrs:{"cols":"8"}})],1)]}}])}),_c('v-expansion-panel-content',[_c('v-sheet',[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"indigo"},on:{"click":function($event){return _vm.selectAllRessources()}}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account-group ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Booking.selectAnyRessources')))])]),_vm._l((_vm.bookingDatas),function(bookingData,i){return _c('v-tooltip',{key:i,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({on:{"click":function($event){return _vm.selectRessource(bookingData, i)}}},'v-avatar',attrs,false),on),[_c('img',{directives:[{name:"show",rawName:"v-show",value:(bookingData.img !== ''),expression:"bookingData.img !== ''"}],attrs:{"src":bookingData.img,"alt":bookingData.shortName}}),_c('v-img',{directives:[{name:"show",rawName:"v-show",value:(bookingData.img === ''),expression:"bookingData.img === ''"}],attrs:{"src":require('@/assets/Avatar-02.svg')}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(bookingData.name))])])})],2)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('Booking.AppointmentTitle'))+" "),_c('b',[_vm._v(" "+_vm._s(_vm.selectedSlot.time))])])],1)]}}])}),_c('v-expansion-panel-content',[_c('v-sheet',[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},_vm._l((_vm.timeSlotForSelectedRessources),function(timeSlot,j){return _c('v-chip-group',{key:j,attrs:{"column":""}},[_c('v-chip',{on:{"click":function($event){return _vm.selectTimeSlot(timeSlot)}}},[_vm._v(" "+_vm._s(timeSlot.time)+" ")])],1)}),1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('kdw-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeDialogEditSlot()}}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")]),_c('kdw-btn',{on:{"click":function($event){return _vm.saveDialogEditSlot()}}},[_vm._v(" "+_vm._s(_vm.$t('button.validateQuestion'))+" ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }