<!------------------------------------------------------------------------------
// Kidiwi Digital property
// Component to display a message to the end user:
// Props:
// - snackbarTimeout: time to display the message in ms (default = 2000). Use -1 to keep it displayed
// - snackbarMessage: Message to be displayed to the user.
// Example of usage:
  <kdw-Statusbar-card
    v-model="snackbarUpdate"
    :snackbar-timeout="snackbarTimeout"
    :snackbar-message="snackbarMessage"
  />
  with snackbarUpdate that need to be set to true to display the StatusBar
// ------------------------------------------------------------------------------>

<template>
  <!--
  v-bind="$attrs" -> to inherit all the other properties
  v-on="$listeners" -> to inherit all the other listeners event
  -->
  <v-snackbar
    id="snackbarDisplay"
    v-bind="$attrs"
    :timeout="snackbarTimeout"
    color="blue-grey"
    rounded="pill"
    top
    v-on="$listeners"
  >
    {{ snackbarMessage }}
    <template #action="{ attrs }">
      <v-btn
        id="btnSnackbarDisplayClose"
        text
        v-bind="attrs"
        @click="closeDialog"
      >
        {{ $t('button.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'KDWStatusBar',

    mixins: [Heading],

    props: {
      snackbarMessage: {
        type: String,
        default: '',
      },

      snackbarTimeout: {
        type: [Number, String],
        default: 2000,
      },
    },

    data: () => {
      return {
        barStatus: false,
      }
    },
    created: function () {
      // console.log('this.$attr = ', this.$attrs)
      // console.log('this.$listeners = ', this.$listeners)
      // console.log(this.value)
      this.barStatus = false
    },
    methods: {
      emitResult () {
        this.$emit('input', this.barStatus)
      },
      closeDialog () {
        this.barStatus = false
        this.emitResult()
      },
    },
  }
</script>
