// ------------------------------------------------------------------------------
// Kidiwi Digital property
// Management of the parameters
// ------------------------------------------------------------------------------

import { updateServiceProvider as updateServiceProviderMutation, updateEndUser as updateEndUserMutation, updateResource as updateResourceMutation } from '@/graphql/mutations'
import { getServiceProvider } from '@/graphql/queries'
import { getEndUserParameters, getServiceProviderParameters, getResourceParameters } from '@/graphql/kdwqueries'
import { getUserSub } from '@/services/User'
import { API, Logger } from 'aws-amplify'
import i18n from '@/plugins/i18n.js'

const logger = new Logger('Parameters library')

export async function loadServiceProviderParametersPure () {
  // console.log('loadServiceProviderParameters')
  try {
    const apiData = await API.graphql({ query: getServiceProviderParameters, variables: { id: await getUserSub() }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
    const ServiceProvider = apiData.data.getServiceProvider
    // console.log('ServiceProvider:')
    // console.log(ServiceProvider)
    return ServiceProvider
  } catch (err) {
    logger.error(err)
    alert(i18n.t('parameterLib.alertFailLoad'))
    return false
  }
}

export async function loadServiceProviderParameters () {
    // console.log('loadServiceProviderParameters')
    try {
      const apiData = await API.graphql({ query: getServiceProvider, variables: { id: await getUserSub() }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      const ServiceProvider = apiData.data.getServiceProvider
      // console.log('ServiceProvider:')
      // console.log(ServiceProvider)
      return ServiceProvider
    } catch (err) {
      logger.error(err)
      alert(i18n.t('parameterLib.alertFailLoad'))
      return false
    }
  }

  export async function saveServiceProviderParameters (mySPParams) {
    // console.log('saveServiceProviderParameters')
    try {
        const ServiceProviderToUpdate = { id: await getUserSub(), parameters: JSON.stringify(mySPParams) }
        await API.graphql({ query: updateServiceProviderMutation, variables: { input: ServiceProviderToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        return true
    } catch (err) {
      logger.error(err)
      // alert(i18n.t('parameterLib.alertFailSave'))
      return false
    }
  }

  export async function loadEndUserParameters () {
    // console.log('loadEndUserParameters')
    try {
      const apiData = await API.graphql({ query: getEndUserParameters, variables: { id: await getUserSub() }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      const ServiceProvider = apiData.data.getEndUser
      // console.log('ServiceProvider:')
      // console.log(ServiceProvider)
      return ServiceProvider
    } catch (err) {
      logger.error(err)
      alert(i18n.t('parameterLib.alertFailLoad'))
      return false
    }
  }

  export async function saveEndUserParameters (mySPParams) {
    // console.log('saveEndUserParameters')
    try {
        const EndUserToUpdate = { id: await getUserSub(), parameters: JSON.stringify(mySPParams) }
        await API.graphql({ query: updateEndUserMutation, variables: { input: EndUserToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        return true
    } catch (err) {
      logger.error(err)
      // alert(i18n.t('parameterLib.alertFailSave'))
      return false
    }
  }

  export async function loadResourceParameters () {
    // console.log('loadResourceParameters')
    try {
      const apiData = await API.graphql({ query: getResourceParameters, variables: { id: await getUserSub() }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      const Resource = apiData.data.getResource
      // console.log('Resource:')
      // console.log(Resource)
      return Resource
    } catch (err) {
      logger.error(err)
      alert(i18n.t('parameterLib.alertFailLoad'))
      return false
    }
  }

    export async function saveResourceParameters (mySPParams) {
    // console.log('saveResourceParameters')
    try {
        const ResourceToUpdate = { id: await getUserSub(), parameters: JSON.stringify(mySPParams) }
        await API.graphql({ query: updateResourceMutation, variables: { input: ResourceToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        return true
    } catch (err) {
      logger.error(err)
      // alert(i18n.t('parameterLib.alertFailSave'))
      return false
    }
  }
