// ------------------------------------------------------------------------------
// Kidiwi Digital property
// Generic functions
// ------------------------------------------------------------------------------

import { Storage } from 'aws-amplify'

// compare 2 elements to sort out a table.
export function compare (a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.name.toUpperCase()
    const bandB = b.name.toUpperCase()

    let comparison = 0
    if (bandA > bandB) {
      comparison = 1
    } else if (bandA < bandB) {
      comparison = -1
    }
    return comparison
  }

  // Check if a point is in an ellipse
  // Given an ellipse centered at (h, k), with semi-major axis a, semi-minor axis b, both aligned with the Cartesian plane.
  // The task is to determine if the point (x, y) is within the area bounded by the ellipse.
  // Math : (x-h)^2/a^2 + (y-k)^2/b^2 <= 1
  // If in the inequation, results comes less than 1 then the point lies within, else if it comes exact 1 then the point lies on the ellipse, and if the inequation is unsatisfied then point lies outside of the ellipse.
  // Example Input: h = 0, k = 0, x = 2, y = 1, a = 4, b = 5 -> Output: Inside
  // Example Input: h = 1, k = 2, x = 200, y = 100, a = 6, b = 5 --> outside
  // Driver code:
  // var h = 0, k = 0, x = 2, y = 1, a = 4, b = 5;
  // if (checkpoint(h, k, x, y, a, b) > 1)
  //   document.write("Outside");
  // else if (checkpoint(h, k, x, y, a, b) == 1)
  //  document.write("On the ellipse");
  // else
  //   document.write("Inside");
  export function checkPointInsideEllipse (h, k, x, y, a, b) {
      // checking the equation of ellipse with the given point
      // var p = (parseFloat(Math.pow((x - h), 2)) / parseFloat(Math.pow(a, 2))) + (parseFloat(Math.pow((y - k), 2)) / parseFloat(Math.pow(b, 2)))
      var p = (Math.pow((x - h), 2) / Math.pow(a, 2)) + (Math.pow((y - k), 2) / Math.pow(b, 2))
      return p
  }

  // This function trigger the request to use an unique 26 letters alphabet (no accent, etc..)
  export function normaliseTextFR (textToNormalise) {
    var textNormalised = textToNormalise
    // replace basic letters in the french alphabet to normalise it
    textNormalised = textNormalised.replace(/é|è|ê|ë/gi, 'e')
    textNormalised = textNormalised.replace(/à|â|ä/gi, 'a')
    textNormalised = textNormalised.replace(/ç/gi, 'c')
    textNormalised = textNormalised.replace(/î|ï/gi, 'i')
    textNormalised = textNormalised.replace(/ô|ö/gi, 'o')
    textNormalised = textNormalised.replace(/û|ü|ù/gi, 'u')
    textNormalised = textNormalised.replace(/ÿ/gi, 'y')
    return textNormalised
  }
// ---------------------  DATE LIBRARY ---------------------------------------------------

// Algo to compte the difference between 2 dates and return the result in month. It is taking care of the leap year.
// https://stackoverflow.com/questions/17732897/difference-between-two-dates-in-years-months-days-in-javascript
// The result is return in month only
// This is not taking into account the time.
// The second date is optional and taking the time now if not defined.
// Example: dateDiff('2019-05-10')
export function dateDiff (startingDate, endingDate) {
  var startDate = new Date(new Date(startingDate).toISOString().substr(0, 10))
  if (!endingDate) {
      // need date in YYYY-MM-DD format
      endingDate = new Date().toISOString().substr(0, 10)
  }
  var endDate = new Date(endingDate)
  if (startDate > endDate) {
      var swap = startDate
      startDate = endDate
      endDate = swap
  }
  var startYear = startDate.getFullYear()
  var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28
  var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  var yearDiff = endDate.getFullYear() - startYear
  var monthDiff = endDate.getMonth() - startDate.getMonth()
  if (monthDiff < 0) {
      yearDiff--
      monthDiff += 12
  }
  var dayDiff = endDate.getDate() - startDate.getDate()
  if (dayDiff < 0) {
      if (monthDiff > 0) {
          monthDiff--
      } else {
          yearDiff--
          monthDiff = 11
      }
      dayDiff += daysInMonth[startDate.getMonth()]
  }

  // return yearDiff + 'Y ' + monthDiff + 'M ' + dayDiff + 'D'
  return yearDiff * 12 + monthDiff
}

// Take a date as AWSDateTime string as an input date and months as a number.
export function addMonths (date, months) {
  var d = new Date(date)
  d.setMonth(d.getMonth() + months)
  /*
  var d = date.getDate()
  date.setMonth(date.getMonth() + +months)
  if (date.getDate() !== d) {
    date.setDate(0)
  }
  */
  return d
}

// Return true if startingDate is bigger than endingDate
export function isStartingDateBigger (startingDate, endingDate) {
  var startDate = new Date(new Date(startingDate).toISOString().substr(0, 10))
  if (!endingDate) {
      // need date in YYYY-MM-DD format
      endingDate = new Date().toISOString().substr(0, 10)
  }
  var endDate = new Date(endingDate)
  if (startDate > endDate) {
      return true
  }
  return false
}

// Take a date as AWSDateTime string and return a short Date 2021-03-30.
export function toShortDate (date) {
  var d = new Date(date)
  const dString = d.toISOString()
  return dString.substring(0, 10)
}

// Compute a default expiration date from current date + 3 MONTHS. The value is returned in AWSDateTime format
export function provideExpirationDate () {
    const numberOfMonthsForExpirationRequest = 3
    var expiryDate = new Date()
    expiryDate.setMonth(expiryDate.getMonth() + numberOfMonthsForExpirationRequest)
    return expiryDate.toISOString()
}

export function lastDayCurrentMonth () {
  var date = new Date()
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  return lastDay.getDate()
}

// ---------------------  End of DATE LIBRARY ---------------------------------------------------

// ---------------------  LEGAL SENTENCE ---------------------------------------------------

// Concatenation of the legal sentence and retrieve the URL to the legal information. The legal sentence is split in 4 pieces.
export async function buildLegalSentenceTagBased (consent1, consent2, consent3, consent4) {
  try {
  // console.log('buildLegalSentenceTagBased')
  var finalSentence = consent1 + 'urlTermsAndConditions' + consent2 + 'urlLegal' + consent3 + 'urlDataManagement' + consent4
  const urlTermsAndConditions = await Storage.get('ConditionsGenerales.pdf', { download: false })
  const urlLegal = await Storage.get('MentionLegales.pdf', { download: false })
  const urlDataManagement = await Storage.get('GDPR.pdf', { download: false })
  finalSentence = consent1 + urlTermsAndConditions + consent2 + urlLegal + consent3 + urlDataManagement + consent4
  return finalSentence
  } catch (err) {
    console.log(err)
    return finalSentence
  }
}

export async function buildCookieSentenceTagBased (cookie1, cookie2) {
  try {
  // console.log('buildCookieSentenceTagBased')
  var finalSentence = cookie1 + 'urlDataManagement' + cookie2
  const urlDataManagement = await Storage.get('GDPR.pdf', { download: false })
  finalSentence = cookie1 + urlDataManagement + cookie2
  return finalSentence
  } catch (err) {
    console.log(err)
    return finalSentence
  }
}
// ---------------------  END of LEGAL SENTENCE ---------------------------------------------------
