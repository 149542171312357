// ------------------------------------------------------------------------------
// Kidiwi Digital property
// Management of the Services library
// ------------------------------------------------------------------------------

import { getUserSub } from '@/services/User'
import { listServices } from '@/graphql/queries'
import { getServiceDescriptionLegalPersonalNotes, getServiceNameDuration } from '@/graphql/kdwqueries'
import { API, Logger } from 'aws-amplify'
import i18n from '@/plugins/i18n.js'

const logger = new Logger('Service Library')

// Simple fetch of the service name and service ID which are Online
export async function fetchServicesSimpleOnline (myServices) {
    // console.log('fetchServicesSimple')
    try {
      // We are using cognito username to search for the service provider as per model
      /*
      const myFilter = {
        serviceProviderID: {
          eq: await getUserSub(),
        },
      }
      */
      const myFilter = { serviceProviderID: { eq: await getUserSub() }, and: { onlineBooking: { eq: true } } }
      const apiData = await API.graphql({ query: listServices, variables: { filter: myFilter }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      // console.log(apiData)
      const listServicesFromAPI = apiData.data.listServices.items
      if (listServicesFromAPI.length === 0) {
        return true
      }
      Promise.all(listServicesFromAPI.map(async service => {
        if (service.name) {
          myServices.push({ name: service.name, id: service.id })
        }
      }))
    } catch (err) {
      logger.error(err)
      alert(i18n.t('serviceLib.alertFailLoad'))
    }
  }

// Simple fetch of the service name and service ID
export async function fetchServicesSimple (myServices) {
    // console.log('fetchServicesSimple')
    try {
      // We are using cognito username to search for the service provider as per model
      const myFilter = {
        serviceProviderID: {
          eq: await getUserSub(),
        },
      }
      const apiData = await API.graphql({ query: listServices, variables: { filter: myFilter }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      // console.log(apiData)
      const listServicesFromAPI = apiData.data.listServices.items
      if (listServicesFromAPI.length === 0) {
        return true
      }
      Promise.all(listServicesFromAPI.map(async service => {
        if (service.name) {
          myServices.push({ name: service.name, id: service.id })
        }
      }))
    } catch (err) {
      logger.error(err)
      alert(i18n.t('serviceLib.alertFailLoad'))
    }
  }

  export async function fetchLegalAndCustomerNote (serviceID, answerToUpdate) {
    const apiData = await API.graphql({ query: getServiceDescriptionLegalPersonalNotes, variables: { id: serviceID }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
    if (answerToUpdate.serviceLegaldescription === '') {
      if (apiData.data.getService.serviceLegaldescription !== '') {
        answerToUpdate.serviceLegaldescription = apiData.data.getService.serviceLegaldescription
      }
    }
    if (answerToUpdate.noteCustomer === '') {
      if (apiData.data.getService.serviceDescription !== '') {
        answerToUpdate.noteCustomer = apiData.data.getService.serviceDescription
      }
    }
  }

  // We return the name of the service and the total duration of the prestation from a serviceID
  export async function fetchNameDuration (serviceID) {
    const apiData = await API.graphql({ query: getServiceNameDuration, variables: { id: serviceID }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
    console.log(apiData)
    const duration = parseInt(apiData.data.getService.bufferTimeBefore) + parseInt(apiData.data.getService.serviceDuration) + parseInt(apiData.data.getService.bufferTimeAfter)
    return ({ name: apiData.data.getService.name, duration: duration })
  }
