<!------------------------------------------------------------------------------
// Kidiwi Digital property
// This component is used to select the postal code, the towns, and the search radius before triggering a search
// Props:
// - searchResultFlag: Trigger this to true when a seach must occur.
// Events:
// -ResetSearchEvent: Emit an event to reset the searchResultFlag
// Component:
    <kdw-general-search-results
      :search-event="searchResultFlag"
      :searched-id="searchedId"
      :localisation="localisation"
      @ResetSearchEvent="resetSearchEvent()"
      @NoServiceFound="NoServiceFound()"
      @ServiceSelected="ServiceSelected($event)"
    />
// ------------------------------------------------------------------------------>

<template>
  <div
    id="generalsearchresults"
  >
    <v-row>
      <v-col
        cols="12"
        sm="12"
      >
        <v-card
          v-show="displayServices"
          elevation="11"
          class="mx-auto my-12 rounded"
          shaped
        >
          <v-system-bar
            color="primary"
            class="grey--text text--lighten-3"
          >
            <v-icon
              class="ml-1 grey--text text--lighten-3"
            >
              mdi-folder-pound
            </v-icon>
            <span class="font-weight-bold">{{ (foundServices.length > 0)? $t(foundServices[0].categoryNameTag).toUpperCase() : '' }}</span>
          </v-system-bar>
          <v-card-text
            v-for="(service, r) in foundServices"
            :key="r"
          >
            <v-divider
              v-if="r !== 0"
              light
            />
            <!------- Main title  ---->
            <v-list-item-title
              class="primary--text ml-4 mt-4"
              style="white-space: normal"
            >
              <strong>{{ service.name }}</strong>
            </v-list-item-title>
            <v-list-item>
              <!------- Company logo   ---->
              <!---
              <v-list-item-avatar
                v-show="service.cpyLogo !== ''"
              >
                <v-img :src="service.cpyLogo" />
              </v-list-item-avatar>
              -->
              <!------- 3 lignes with: SubCategory, Short description, Town Name  ---->
              <v-list-item-content>
                <v-row
                  no-gutters
                  justify="space-between"
                >
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-list-item-subtitle
                      v-show="service.subCategory !== ''"
                      style="white-space: normal"
                      class="concrete--text"
                    >
                      <v-icon
                        small
                        class="concrete--text"
                      >
                        mdi-folder-pound-outline
                      </v-icon>
                      {{ service.subCategory }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="concrete--text"
                    >
                      <v-icon
                        small
                        class="concrete--text"
                      >
                        mdi-map-marker
                      </v-icon>
                      {{ service.townName }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-show="service.serviceDescriptionShort !== ''"
                      class="concrete--text"
                      style="white-space: nowrap"
                    >
                      <v-icon
                        color="concrete"
                        small
                        dark
                      >
                        mdi-information-outline
                      </v-icon>
                      {{ service.serviceDescriptionShort }}
                    </v-list-item-subtitle>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="hidden-sm-and-down"
                  >
                    <v-row
                      no-gutters
                    >
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-row
                          no-gutters
                          class="d-flex align-end"
                        >
                          <v-spacer />
                          <v-col>
                            <v-list-item-action
                              class="mt-1 "
                            >
                              <kdw-label
                                :text="displayServicePrice(service.isFixedPrice, service.ServicePrice)"
                              />
                            </v-list-item-action>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-list-item-action
                          class=""
                        >
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <kdw-btn
                                :id="'informationSearchButtonDesktop' + r"
                                color="sunflower"
                                elevation="10"
                                min-width="50"
                                v-bind="attrs"
                                v-on="on"
                                @click="serviceDescriptionDetails(service)"
                              >
                                <v-icon
                                  color="white"
                                >
                                  mdi-information-outline
                                </v-icon>
                              </kdw-btn>
                            </template>
                            <span>{{ $t('tooltip.serviceOverview') }}</span>
                          </v-tooltip>
                        </v-list-item-action>
                        <v-list-item-action
                          class=""
                        >
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <kdw-btn
                                :id="'selectOfferSearchButtonDesktop' + r"
                                color="sea"
                                elevation="10"
                                min-width="50"
                                v-bind="attrs"
                                dark
                                v-on="on"
                                @click="selectOffer(service)"
                              >
                                <v-icon>mdi-check-bold</v-icon>
                              </kdw-btn>
                            </template>
                            <span>{{ $t('tooltip.PickSP') }}</span>
                          </v-tooltip>
                        </v-list-item-action>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!----- THIS IS THE MOBILE LOGIC ---->
                <v-card
                  flat
                  class="d-flex"
                >
                  <kdw-label
                    class="mt-1 hidden-md-and-up"
                    :text="displayServicePrice(service.isFixedPrice, service.ServicePrice)"
                  />
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <kdw-btn
                        :id="'informationSearchButtonMobile' + r"
                        class="ml-1 mr-5 my-auto hidden-md-and-up"
                        color="sunflower"
                        elevation="9"
                        min-width="49"
                        v-bind="attrs"
                        v-on="on"
                        @click="serviceDescriptionDetails(service)"
                      >
                        <v-icon
                          color="white"
                        >
                          mdi-information-outline
                        </v-icon>
                      </kdw-btn>
                    </template>
                    <span>{{ $t('tooltip.serviceOverview') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template
                      #activator="{ on, attrs }"
                    >
                      <kdw-btn
                        :id="'selectOfferSearchButtonMobile' + r"
                        class="ml-1 my-auto hidden-md-and-up"
                        dark
                        color="sea"
                        elevation="9"
                        min-width="49"
                        v-bind="attrs"
                        v-on="on"
                        @click="selectOffer(service)"
                      >
                        <v-icon>mdi-check-bold</v-icon>
                      </kdw-btn>
                    </template>
                    <span>{{ $t('tooltip.PickSP') }}</span>
                  </v-tooltip>
                </v-card>
              </v-list-item-content>
              <!------- Pricing & Acceptation button  ---->
            </v-list-item>
          </v-card-text>
          <!---- We are taking care of the bottom of the card --->
          <v-divider />
          <v-card-actions
            class="mt-4"
          >
            <v-combobox
              :key="'ComboFilter1' + displayTrick"
              v-model="filterOptionsSelected"
              :items="getFilterOptions"
              item-text="name"
              item-value="id"
              :label="$t('generalSearchResults.filter')"
              light
              dense
              class="mx-2 ml-5"
              @change="filterOptionsChanged()"
            />
            <v-combobox
              :key="'ComboFilter2' + displayTrick"
              v-model="rowByPageSelected"
              :items="getRowByPage"
              item-text="name"
              item-value="id"
              :label="$t('generalSearchResults.rowsByPage')"
              class="mx-2"
              dense
              disabled
              light
            />
            <v-spacer />
            <v-chip
              disabled
              label
              class="mb-4"
            >
              1/1
            </v-chip>
            <kdw-btn
              min-width="10"
              small
              class="ml-3 mb-4"
              color="rgba(60,60,59,0.6)"
              disabled
            >
              <v-icon>mdi-chevron-left </v-icon>
            </kdw-btn>
            <kdw-btn
              min-width="10"
              small
              class="mr-5 mb-4"
              color="rgba(60,60,59,0.6)"
              disabled
            >
              <v-icon> mdi-chevron-right </v-icon>
            </kdw-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <kdw-dialog-info-service
      v-model="dialogInfoService"
      :service-details="infoServiceItem"
      :logo="infoServiceItem.cpyLogo"
    />
  </div>
</template>

<script>
  // import { fetchTownfromPostalCode, fetchTownfromPartialName } from '@/services/PostalCode'
  // import { getByCityPostalCode } from '@/services/commonTools'
  // import { normaliseTextFR } from '@/helpers/GenericFunctions'
  import { servicesfromOnePostalCodeRayon, getSPIDLogo } from '@/services/Distance'
  import { formatServicePriceString } from '@/helpers/Formatter'
  import { Logger } from 'aws-amplify'
  const logger = new Logger('GeneralSearchResults')

  export default {
    name: 'GeneralSearchResults',

    props: {
      searchEvent: {
        type: Boolean,
        default: false,
      },
      searchedId: {
        type: String,
        default: '',
      },
      localisation: {
        type: Object,
      },
    },

    // TODO translations
    data: () => ({
      rowByPage: [],
      rowByPageSelected: { id: 'all' },
      filterOptions: [],
      filterOptionsSelected: { id: 'NAME↓' },
      displayTrick: 0,
      /*
      filterOptions: [{ id: 'PRICE↑', name: this.$i18n.t('generalSearchResults.filterPriceUp') },
                      { id: 'PRICE↓', name: this.$i18n.t('generalSearchResults.filterPriceDown') },
                      { id: 'SUBCATEGORY↑', name: this.$i18n.t('generalSearchResults.filterSubCategoryUp') },
                      { id: 'SUBCATEGORY↓', name: this.$i18n.t('generalSearchResults.filterSubCategoryDown') },
                      { id: 'NAME↑', name: this.$i18n.t('generalSearchResults.filterNameUp') },
                      { id: 'NAME↓', name: this.$i18n.t('generalSearchResults.filterNameDown') }],
      filterOptionsSelected: { id: 'NAME↓', name: this.$i18n.t('generalSearchResults.filterNameDown') },
      */
      displayServices: false,
      foundServices: [
        // { cpyLogo: 'https://cdn.vuetifyjs.com/images/john.png', name: 'Peinture Intérieure Intérieure Intérieure Intérieure', serviceDescription: 'Peinture de votre intérieur', isFixedPrice: false, id: '2c804156-81c0-4aec-b242-7336d1b65b68', serviceDescriptionShort: 'Peinture de votre intérie...', serviceDuration: '1h', ServicePrice: 'Quote based', subCategory: 'Indoor painting', category: 'Painting', townName: 'MOUGINS' },
        // { cpyLogo: '', name: 'Peinture extérieure', serviceDescription: 'Peinture de votre extérieur', isFixedPrice: false, id: '2c804156-81c0-4aec-b242-7336d1b65b68', serviceDescriptionShort: 'Peinture de votre ext..', serviceDuration: '3h', ServicePrice: 'Quote based', subCategory: 'Outdoor painting', category: 'Painting', townName: 'MOUGINS' },
        // { cpyLogo: 'https://cdn.vuetifyjs.com/images/john.png', name: 'Peinture extérieure2', serviceDescription: 'Peinture de votre extérieur', isFixedPrice: true, id: '2c804156-81c0-4aec-b242-7336d1b65b68', serviceDescriptionShort: 'blabla...ext..', serviceDuration: '2h', ServicePrice: '111', subCategory: 'Outdoor painting', category: 'Painting', townName: 'MOUGINS' },
      ],
      dialogInfoService: false,
      infoServiceItem: { name: 'AA', category: 'cat', subCategory: 'subCat', townName: 'townName', ServicePrice: '12 Euros', serviceDescription: 'waaa!', serviceDuration: '1d' },
    }),

    computed: {
      getRowByPage: function () {
        return this.getRowByPageMethod()
      },
      getFilterOptions: function () {
        return this.getFilterOptionsMethod()
      },
    },

    watch: {
      // We check if the parent requested for a search
      searchEvent: function (val) {
        if (val) {
          // We ask to reset the parent
          this.$emit('ResetSearchEvent')
          this.search()
        }
      },
    },

    created: function () {
      this.getFilterOptionsMethod()
      this.getRowByPageMethod()
    },

    methods: {
      displayServicePrice (isFixedPrice, ServicePrice) {
        // TODO better place for scrolling?
        // BEG scroll to results
        this.$vuetify.goTo('#generalsearchresults', {
          duration: 1000,
          offset: 0,
          easing: 'easeInOutQuint',
        })
        // END scroll to results
        return formatServicePriceString(isFixedPrice, ServicePrice)
      },
      getRowByPageMethod: function () {
        const table = [{ id: '1', name: '1' },
                       { id: '2', name: '2' },
                       { id: '5', name: '5' },
                       { id: '10', name: '10' },
                       { id: '15', name: '15' },
                       { id: 'all', name: this.$i18n.t('generalSearchResults.all') }]
        if (this.rowByPageSelected.id === 'all') {
          this.rowByPageSelected.name = this.$i18n.t('generalSearchResults.all')
        }
        return table
      },
      getFilterOptionsMethod () {
        const table = [{ id: 'PRICE↑', name: this.$i18n.t('generalSearchResults.filterPriceUp') },
                       { id: 'PRICE↓', name: this.$i18n.t('generalSearchResults.filterPriceDown') },
                       { id: 'SUBCATEGORY↑', name: this.$i18n.t('generalSearchResults.filterSubCategoryUp') },
                       { id: 'SUBCATEGORY↓', name: this.$i18n.t('generalSearchResults.filterSubCategoryDown') },
                       { id: 'NAME↑', name: this.$i18n.t('generalSearchResults.filterNameUp') },
                       { id: 'NAME↓', name: this.$i18n.t('generalSearchResults.filterNameDown') }]
        // console.log('this.filterOptionsSelected before')
        // console.log(this.filterOptionsSelected)
        if (this.filterOptionsSelected.id === 'PRICE↑') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterPriceUp')
          return table
        }
        if (this.filterOptionsSelected.id === 'PRICE↓') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterPriceDown')
          return table
        }
        if (this.filterOptionsSelected.id === 'SUBCATEGORY↑') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterSubCategoryUp')
          return table
        }
        if (this.filterOptionsSelected.id === 'SUBCATEGORY↓') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterSubCategoryDown')
          return table
        }
        if (this.filterOptionsSelected.id === 'NAME↑') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterNameUp')
          return table
        }
        if (this.filterOptionsSelected.id === 'NAME↓') {
          this.filterOptionsSelected.name = this.$i18n.t('generalSearchResults.filterNameDown')
        }
        // console.log('this.filterOptionsSelected after')
        // console.log(this.filterOptionsSelected)
        this.displayTrick++
        return table
      },

      serviceDescriptionDetails (service) {
        // console.log('service:')
        // console.log(service)
        this.infoServiceItem = Object.assign({}, service)
        // We are added the logo info is any
        // const cpyLogo = await
        getSPIDLogo(service.serviceProviderID)
          .then((cpyLogo) => {
            this.infoServiceItem.cpyLogo = cpyLogo
            this.infoServiceItem.ServicePriceDisplay = this.displayServicePrice(service.isFixedPrice, service.ServicePrice)
            // console.log('serviceprice:', this.infoServiceItem.ServicePriceDisplay)
            // console.log('infoServiceItem:')
            // console.log(this.infoServiceItem)
            this.dialogInfoService = true
          })
      },

      // The end user requested a search
      async search () {
        // console.log('this.foundServices')
        // console.log(this.foundServices)
        // console.log('this.searchCategory')
        // console.log(this.searchCategory)
        // console.log('this.searchedId')
        // console.log(this.searchedId)
        // console.log('headers')
        // console.log(headers)
        var headers = this.searchedId.split(',')
        // clear the found service table
        this.foundServices.splice(0, this.foundServices.length)
        // Perform the search
        servicesfromOnePostalCodeRayon(this.localisation.latitude, this.localisation.longitude, this.localisation.radius, headers[0], this.foundServices)
          .then(() => {
            if (this.foundServices.length === 0) {
              this.$emit('NoServiceFound')
              this.displayServices = false
            } else {
              this.displayServices = true
              this.sortFoundServices()
              // console.log('this.foundServices:')
              // console.log(this.foundServices)
            }
          })
          .catch(err => logger.error(err))
      },

      // Filter option changed
      filterOptionsChanged () {
        // console.log('filterOptionsChanged', this.filterOptionsSelected)
        this.sortFoundServices()
      },
      selectOffer (service) {
        // console.log('selected Service:')
        // console.log(service)
        // We emit the result to the parent as the end user select the offer
        this.$emit('ServiceSelected', service)
      },
      // This is managing the ordering options of the search content
      sortFoundServices () {
        // console.log('this.foundServices')
        // console.log(this.foundServices)
        switch (this.filterOptionsSelected.id) {
          case 'NAME↑': // sort name by alphabetic order inverse
            this.foundServices.sort(function (a, b) {
              if (a.name.toUpperCase() < b.name.toUpperCase()) { return 1 }
              if (a.name.toUpperCase() > b.name.toUpperCase()) { return -1 }
              return 0
            })
            break
          case 'NAME↓': // sort name by alphabetic order
            this.foundServices.sort(function (a, b) {
              if (a.name.toUpperCase() < b.name.toUpperCase()) { return -1 }
              if (a.name.toUpperCase() > b.name.toUpperCase()) { return 1 }
              return 0
            })
            break
          case 'PRICE↑': // sort by price inverse (from big to small)
            this.foundServices.sort(function (a, b) {
              if (a.ServicePrice < b.ServicePrice) { return 1 }
              if (a.ServicePrice > b.ServicePrice) { return -1 }
              return 0
            })
            break
          case 'PRICE↓': // sort by price (from small to big)
            this.foundServices.sort(function (a, b) {
              if (a.ServicePrice < b.ServicePrice) { return -1 }
              if (a.ServicePrice > b.ServicePrice) { return 1 }
              return 0
            })
            break
          case 'SUBCATEGORY↑': // sort subCategory by alphabetic order inverse
            this.foundServices.sort(function (a, b) {
              if (a.subCategory.toUpperCase() < b.subCategory.toUpperCase()) { return 1 }
              if (a.subCategory.toUpperCase() > b.subCategory.toUpperCase()) { return -1 }
              return 0
            })
            break
          case 'SUBCATEGORY↓': // sort subCategory by alphabetic order
            this.foundServices.sort(function (a, b) {
              if (a.subCategory.toUpperCase() < b.subCategory.toUpperCase()) { return -1 }
              if (a.subCategory.toUpperCase() > b.subCategory.toUpperCase()) { return 1 }
              return 0
            })
            break
        }
      },
    },
  }
</script>
