/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:38c3c56c-585f-423c-91a0-cbc4d2ef251a",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ugSuQ4wmc",
    "aws_user_pools_web_client_id": "453ufu4drp6b71b7m502jsare7",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://rc4tvpvss5bhjdniatfptquoke.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-xandi4s2mnglxeypttto25fwna",
    "aws_cloud_logic_custom": [
        {
            "name": "kdwevts",
            "endpoint": "https://mdlijwss7l.execute-api.eu-west-1.amazonaws.com/devc",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "kdwuser22b714429fc64b298613bc13354f706ezwrw0a-devc",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
